import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import React, { useState } from 'react';

import Divider from '../../general/Divider';
import { Concrete, CopperRose, Questions } from '../../StyleGuide/Colors';
import { TitleAndSubtitle } from '../components/HeaderAndSubtitle';
import { SECTION_INTERNAL_PADDING } from './constants';

const PREFIX = 'Collapsible';

const classes = {
  collapsed: `${PREFIX}-collapsed`,
  moreSpaceBelow: `${PREFIX}-moreSpaceBelow`
};

const Root = styled('div')({
  marginTop: 15
});
const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `10px ${SECTION_INTERNAL_PADDING}px`
});
const Collapser = styled('button')({
  background: 'transparent',
  border: 0,
  color: CopperRose,
  cursor: 'pointer',
  ['& svg']: {
    fontSize: 30
  }
});
const ContentContainer = styled('div')({
  padding: `10px ${SECTION_INTERNAL_PADDING}px`,
  background: `linear-gradient(white, white, ${Concrete})`,
  [`&.${classes.collapsed}`]: {
    display: 'none'
  },
  [`&.${classes.moreSpaceBelow}`]: {
    paddingBottom: `15px`
  }
});

/**
 * Helper component for dividing a Section page into collapsible sections.
 */
export const Collapsible: React.FC<{
  title: string;
  subtitle: string;
  moreSpaceBelow: boolean;
  showStar?: boolean;
}> = ({ title, subtitle, children, moreSpaceBelow, showStar }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  return (
    <Root>
      <Header>
        <div>
          <TitleAndSubtitle {...{ title, subtitle, showStar }} />
        </div>
        <Collapser onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <ExpandMore /> : <ExpandLess />}
        </Collapser>
      </Header>
      <Divider
        style={{
          borderColor: Questions,
          margin: `2px auto 0`
        }}
        width={`calc(100% - ${2 * SECTION_INTERNAL_PADDING}px)`}
      />
      <ContentContainer
        className={classNames({
          [classes.collapsed]: collapsed,
          [classes.moreSpaceBelow]: moreSpaceBelow
        })}
      >
        {children}
      </ContentContainer>
    </Root>
  );
};
