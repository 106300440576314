import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';

const PREFIX = 'Progress';

const classes = {
  progress: `${PREFIX}-progress`
};

const StyledLinearProgress = styled(LinearProgress)({
  [`&.${classes.progress}`]: {
    height: '17px !important',
    borderRadius: 10
  }
});

/**
 * Helper component for the progress bar at each Onboarding stage.
 */
// TODO samgqroberts 2021-12-10 update color to be soshe theme
export const Progress: React.FC<{ progress: number; className?: string }> = ({
  progress,
  className
}) => {
  return (
    <StyledLinearProgress
      variant="determinate"
      value={progress}
      className={classNames(classes.progress, className)}
    />
  );
};
