import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { appSubroute } from '../general/Routing';

export const OnlyForNonAppPages: React.FC = ({ children }) => {
  return (
    <Switch>
      <Route path={appSubroute}></Route>
      <Route>{children}</Route>
    </Switch>
  );
};
