import React from 'react';

import Divider from '../general/Divider';
import { useCachedQueryParam } from '../general/react-util';
import {
  COUPON_USED_QUERY_FIELD,
  EMAIL_QUERY_FIELD,
  PRICE_PAID_QUERY_FIELD,
  PRODUCT_NAME_QUERY_FIELD
} from '../general/Routing';
import { useCheckoutInfo, useUserInfo } from '../general/useUserInfo';
import { PageLevelComponent } from '../general/util';
import { getMessageHandler as getWKMessageHandler } from '../general/webkit';
import image from '../img/circle-check.jpg';
import { freeSkuName } from '../MembershipPlansPage/Plans';
import { Button } from '../StyleGuide/Button';
import { H3, P } from '../StyleGuide/Text';
import styles from './AppCheckoutSuccessPage.module.css';

const MESSAGE_HANDLER_NAME = 'paymentMessageHandler';
const MESSAGE_TYPE = 'checkoutComplete';

function confirmationMsg(email: string | null): string {
  return email
    ? `We sent a confirmation email to ${email}.`
    : 'We sent you a confirmation email.';
}

const iosNextHandler =
  ({
    productName,
    email,
    couponUsed,
    pricePaid
  }: {
    productName: string | null;
    email: string | null;
    couponUsed: string | null;
    pricePaid: string | number | null;
  }): (() => void) =>
  () => {
    getWKMessageHandler(MESSAGE_HANDLER_NAME)?.postMessage({
      type: MESSAGE_TYPE,
      productName,
      email,
      couponUsed,
      pricePaid
    });
  };

export const AppCheckoutSuccessPage: React.FC<{
  email: string | null;
  productName: string | null;
  onNext: () => void;
}> = ({ email, productName, onNext }) => {
  return (
    <div className={styles.page}>
      <img className={styles.image} alt="Welcome to SoShe!" src={image} />
      <H3 className={styles.header}>
        {!productName || productName === freeSkuName
          ? 'Sign Up Complete'
          : 'Payment Complete'}
      </H3>
      <Divider width={80} className={styles.divider} />
      <P className={styles.confirmation}>{confirmationMsg(email)}</P>
      <Button className={styles.next} onClick={onNext}>
        Next
      </Button>
    </div>
  );
};

export const GenericAppCheckoutSuccessPage: PageLevelComponent = () => {
  const queryParams = {
    productName: useCachedQueryParam(PRODUCT_NAME_QUERY_FIELD),
    email: useCachedQueryParam(EMAIL_QUERY_FIELD),
    couponUsed: useCachedQueryParam(COUPON_USED_QUERY_FIELD),
    pricePaid: useCachedQueryParam(PRICE_PAID_QUERY_FIELD)
  };
  const { email, productName } = queryParams;
  const onNext = iosNextHandler(queryParams);
  return <AppCheckoutSuccessPage {...{ email, productName, onNext }} />;
};

export const IosAppCheckoutSuccessPage: PageLevelComponent = () => {
  const params = {
    ...useUserInfo(false).userInfo,
    ...useCheckoutInfo(false).checkoutInfo
  };
  const { email, productName } = params;
  const onNext = iosNextHandler(params);
  return <AppCheckoutSuccessPage {...{ email, productName, onNext }} />;
};
