const ExternalUrls = {
  facebook: 'https://www.facebook.com/SosheApp/',
  twitter: 'https://twitter.com/soshe_app',
  instagram: 'https://www.instagram.com/soshe_app/',
  linkedin: 'https://www.linkedin.com/company/soshe-app/',
  mailtoHello: 'mailto:hello@soshe-app.com',
  mailtoFeedback: 'mailto:feedback@soshe-app.com',
  mailtoSupport: 'mailto:support@soshe-app.com',
  appStore: 'https://apps.apple.com/us/app/soshe/id1468000162',
  shannonLinkedIn: 'https://www.linkedin.com/in/shannonsullivanfield/',
  acogOptimizingPostpartumCare:
    'https://www.acog.org/clinical/clinical-guidance/committee-opinion/articles/2018/05/optimizing-postpartum-care',
  npaPerinatalMoodAndAnxietyDisorders:
    'http://www.nationalperinatal.org/resources/Documents/Position%20Papers/2018%20Position%20Statement%20PMADs_NPA.pdf',
  voxMaternityLeaveUnitedStates:
    'https://www.vox.com/2015/8/21/9188343/maternity-leave-united-states',
  theAtlanticWomenWithChildreanLeaveTheirJobs:
    'https://www.theatlantic.com/sexes/archive/2013/04/why-43-of-women-with-children-leave-their-jobs-and-how-to-get-them-back/275134/',
  nprLostMothers: 'https://www.npr.org/series/543928389/lost-mothers',
  nprFocusOnInfants:
    'https://www.npr.org/2017/05/12/527806002/focus-on-infants-during-childbirth-leaves-u-s-moms-in-danger',
  proPublicaChildbirthComplications:
    'https://www.propublica.org/article/severe-complications-for-women-during-childbirth-are-skyrocketing-and-could-often-be-prevented',
  nihMaternalDeathsInUS:
    'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2409165/',
  cochraneLibraryContinuousSupport:
    'https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD003766.pub6/full',
  proPublicaMidwives:
    'https://www.propublica.org/article/midwives-study-maternal-neonatal-care',
  acogMaternityMortalityPrevention:
    'https://www.acog.org/advocacy/policy-priorities/maternal-mortality-prevention'
} as const;
export default ExternalUrls;

export type ExternalUrlK = keyof typeof ExternalUrls;
export type ExternalUrlE = typeof ExternalUrls[ExternalUrlK];
