import classNames from 'classnames';

import image from '../img/app-store-download.png';
import styles from './AppStoreButton.module.css';
import ExternalUrls from './ExternalUrls';

const AppStoreButton: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <a
      href={ExternalUrls.appStore}
      className={classNames(styles.link, className)}
    >
      <img src={image} alt="Find us on the App Store!" />
    </a>
  );
};
export default AppStoreButton;
