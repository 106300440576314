/* Typescript bindings for breakpoint constants defined in css modules */

import constants from './StyleConstants.module.css';

export const MobileBreakpoint = asInt(constants['MobileBreakpoint']);
export const MidsizeBreakpoint = asInt(constants['MidsizeBreakpoint']);
export const MidsizeWideBreakpoint = asInt(constants['MidsizeWideBreakpoint']);
export const WideBreakpoint = asInt(constants['WideBreakpoint']);
export const DesktopNavHeight = asInt(constants['DesktopNavHeight']);

export function asInt(value: string): number {
  let v = value;
  if (v.endsWith('px')) {
    v = v.substring(0, v.length - 2);
  }
  return parseInt(v, 10);
}

export const mobileMediaQuery = `@media (max-width: ${MobileBreakpoint}px)`;
export const notMobileMediaQuery = `@media (min-width: ${MobileBreakpoint}px)`;
