import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import {
  GenericAppCheckoutPage,
  IosAppCheckoutPage,
  WebCheckoutPage
} from '../CheckoutPage/CheckoutPage';
import {
  GenericAppCheckoutSuccessPage,
  IosAppCheckoutSuccessPage
} from '../CheckoutSuccessPage/AppCheckoutSuccessPage';
import { WebCheckoutSuccessPage } from '../CheckoutSuccessPage/WebCheckoutSuccessPage';
import ConfirmPasswordResetPage from '../ConfirmPasswordResetPage';
import { CreateAccountPage } from '../CreateAccountPage';
import { appEnv } from '../general/AppEnv';
import PageUrls from '../general/Routing';
import { dataLayerGTM } from '../general/tagManager';
import { LoginPage } from '../LoginPage/LoginPage';
import {
  GenericAppMembershipPlansPage,
  IosAppMembershipPlansPage,
  WebMembershipPlansPage
} from '../MembershipPlansPage';
import NotFoundPage from '../NotFoundPage';
import RequestPasswordResetPage from '../RequestPasswordResetPage';
import styles from './App.module.css';
import Navbar from './Navbar';
import { OnlyForNonAppPages } from './OnlyForNonAppPages';

export const UnauthenticatedApp: React.FC = () => {
  const { hash, key, pathname } = useLocation();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // reset window's scroll position whenever a navigation event occurs
  useEffect(() => {
    scrollContainerRef.current?.scrollTo(0, 0);
  }, [key]);

  // smoothly scroll to any anchor tag matching the location's hash (if present)
  // important that this comes after the reset scroll effect!
  useEffect(() => {
    const node = document.getElementById(hash.substr(1));
    node?.scrollIntoView({ behavior: 'smooth' });
  }, [hash]);

  // anytime the pathname changes, emit a GTM tag (equivalent to GTM tracking page changes)
  useEffect(() => {
    dataLayerGTM({ event: 'pageview', page: pathname });
  }, [pathname]);

  const appFlow = pathname.split('/')[1] === 'app';

  return (
    <Switch>
      <Route>
        <div className={styles.app}>
          {/* only show navbar if the page isn't an app-embedded page */}
          <OnlyForNonAppPages>
            <Navbar />
          </OnlyForNonAppPages>
          <div
            ref={scrollContainerRef}
            className={classNames(styles.contentContainer, {
              [styles.appFlow]: appFlow
            })}
          >
            <div className={styles.content}>
              <Switch>
                <Route exact path={PageUrls.home}>
                  <LoginPage />
                </Route>
                {/* web-based checkout flow pages */}
                <Route exact path={PageUrls.membership}>
                  <WebMembershipPlansPage />
                </Route>
                <Route exact path={PageUrls.checkout}>
                  <WebCheckoutPage />
                </Route>
                <Route exact path={PageUrls.checkoutSuccess}>
                  <WebCheckoutSuccessPage />
                </Route>
                <Route exact path={PageUrls.createAccount}>
                  <CreateAccountPage />
                </Route>
                {/* generic app-based checkout flow pages, passing user info in url */}
                <Route exact path={PageUrls.appMembership}>
                  <GenericAppMembershipPlansPage />
                </Route>
                <Route exact path={PageUrls.appCheckout}>
                  <GenericAppCheckoutPage />
                </Route>
                <Route exact path={PageUrls.appCheckoutSuccess}>
                  <GenericAppCheckoutSuccessPage />
                </Route>
                {/* ios-based checkout flow pages, passing user info using webkit mechanisms and local storage */}
                {appEnv('SHOW_DEV_RESOURCES') && (
                  <Route exact path={PageUrls.iosMembership}>
                    <IosAppMembershipPlansPage />
                  </Route>
                )}
                {appEnv('SHOW_DEV_RESOURCES') && (
                  <Route exact path={PageUrls.iosCheckout}>
                    <IosAppCheckoutPage />
                  </Route>
                )}
                {appEnv('SHOW_DEV_RESOURCES') && (
                  <Route exact path={PageUrls.iosCheckoutSuccess}>
                    <IosAppCheckoutSuccessPage />
                  </Route>
                )}
                {/* password reset pages */}
                <Route exact path={PageUrls.requestPasswordReset}>
                  <RequestPasswordResetPage />
                </Route>
                <Route exact path={PageUrls.confirmPasswordReset}>
                  <ConfirmPasswordResetPage />
                </Route>
                {/* fallthrough 404 page */}
                <Route>
                  <NotFoundPage />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </Route>
    </Switch>
  );
};
