import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import React from 'react';

import { Button } from '../../StyleGuide/Button';
import { Tundora, White } from '../../StyleGuide/Colors';

const PREFIX = 'OnboardingOptions';

const classes = {
  selected: `${PREFIX}-selected`
};

const Root = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});
const OptionItem = styled('li')({
  width: '100%',
  padding: 0,
  '&:not(:first-of-type)': {
    marginTop: 15
  }
});
const OptionButton = styled(Button)({
  width: '100%',
  paddingLeft: 0,
  paddingRight: 0,
  [`&.${classes.selected}`]: {
    border: '1px solid transparent'
  },
  [`&:not(.${classes.selected})`]: {
    background: White,
    color: Tundora,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Tundora
  }
});

interface OptionItem<Id> {
  id: Id;
  name: string;
}

interface OptionProps<Id> {
  options: OptionItem<Id>[];
  selected: Id[];
  onSelect: (option: Id) => void;
  onDeselect: (option: Id) => void;
  className?: string;
}

/**
 * Helper component in the Onboarding UX flow to ensure common styles for option button lists.
 */
export const Options = <T extends string | number>({
  options,
  selected,
  onSelect,
  onDeselect,
  className
}: OptionProps<T>): ReturnType<React.FC> => {
  return (
    <Root {...{ className }}>
      {options.map((option) => {
        const isSelected = selected.includes(option.id);
        return (
          <OptionItem key={option.id}>
            <OptionButton
              colorVariant="cta"
              className={classNames({
                [classes.selected]: isSelected
              })}
              onClick={() => {
                if (isSelected) {
                  onDeselect(option.id);
                } else {
                  onSelect(option.id);
                }
              }}
            >
              {option.name}
            </OptionButton>
          </OptionItem>
        );
      })}
    </Root>
  );
};
