import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Route, Switch } from 'react-router-dom';

import { appEnv } from '../general/AppEnv';
import PageUrls, { devSubroute } from '../general/Routing';
import StyleGuidePage from '../StyleGuide/StyleGuidePage';
import { refreshTokenStored } from '../webapp/page/Auth';
import { Webapp } from '../webapp/Webapp';
import { UnauthenticatedApp } from './UnauthenticatedApp';

/**
 * Extension of App entrypoint that has been wrapped in a <Router />
 */
export const RoutedApp: React.FC = () => {
  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);
  return (
    <Switch>
      {appEnv('SHOW_DEV_RESOURCES') && (
        <Route path={devSubroute}>
          <Switch>
            <Route path={PageUrls.styleguide}>
              <StyleGuidePage />
            </Route>
          </Switch>
        </Route>
      )}
      {/* If the refresh token is stored, then the user had previously logged in,
          meaning we show the logged in webapp experience */}
      {refreshTokenStored() ? <Webapp /> : <UnauthenticatedApp />}
    </Switch>
  );
};
