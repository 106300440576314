import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { userIsOniOSDevice } from './util';

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

/**
 * On component mount, grabs the value for the specified query parameter and caches it in an
 * immutable state variable.
 */
export function useCachedQueryParam(key: string): string | null {
  const [value] = useState<string | null>(useQuery().get(key));
  return value;
}

export function useUserIsOnIOSDevice(): boolean | undefined {
  const [oniOSDevice, setOniOSDevice] = useState<boolean | undefined>(
    undefined
  );
  useEffect(() => {
    setOniOSDevice(userIsOniOSDevice());
  }, []);
  return oniOSDevice;
}
