import { styled } from '@mui/material/styles';
import { useEffect } from 'react';

import { browserNavigate } from '../../general/util';
import friendsHugging from '../../img/img_misc_two.friends.hugging@2x.png';
import { White } from '../../StyleGuide/Colors';
import { H7 } from '../../StyleGuide/Text';
import { useOnboardingContext } from './OnboardingContext';

const Gradient = styled('div')({
  backgroundImage: 'linear-gradient(transparent, black)',
  position: 'absolute',
  bottom: 0,
  top: '40%',
  left: 0,
  right: 0,
  opacity: 0.7
});

/**
 * A stage in the Onboarding flow.
 */
export const ConclusionStage: React.FC = () => {
  const { setNextText, setNextAction } = useOnboardingContext();
  useEffect(() => setNextText('Start My Birth Prep'), []);
  useEffect(() => setNextAction(() => browserNavigate('/')), []);
  return (
    <div>
      <div style={{ width: '100%', position: 'relative' }}>
        <img
          style={{ width: '100%' }}
          src={friendsHugging}
          alt="Welcome to SoShe!"
        />
        <Gradient />
        <H7
          style={{
            position: 'absolute',
            bottom: 25,
            color: White,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
            padding: 0,
            margin: 0
          }}
        >
          We&apos;re so glad you&apos;re here!
        </H7>
      </div>
      <H7 style={{ textAlign: 'center' }}>
        And we&apos;re honored to be a part of your village of support.
      </H7>
    </div>
  );
};
