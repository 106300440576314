import {
  AccountCircle,
  Description,
  LocalLibrary,
  Share
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Divider from '../../general/Divider';
import { LogoLink } from '../../general/LogoLink';
import { browserNavigate } from '../../general/util';
import { Button } from '../../StyleGuide/Button';
import {
  Concrete,
  CopperRose,
  Questions,
  Tundora,
  White
} from '../../StyleGuide/Colors';
import { H7 } from '../../StyleGuide/Text';
import { eraseToken } from './Auth';
import { Tab } from './BottomNav';
import { WebappUrls } from './WebappUrls';

const PREFIX = 'LeftNav';

const classes = {
  leftnav: `${PREFIX}-leftnav`,
  logoContainer: `${PREFIX}-logocontainer`,
  list: `${PREFIX}-list`,
  navitem: `${PREFIX}-navitem`,
  navitemlabel: `${PREFIX}-navitemlabel`,
  selected: `${PREFIX}-selected`,
  logout: `${PREFIX}-logout`
};

const Root = styled('div')({
  [`&.${classes.leftnav}`]: {
    width: 330,
    background: White,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: `1px solid ${Concrete}`
  },
  [`& .${classes.logoContainer}`]: {
    width: '100%',
    height: 83,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  [`& .${classes.list}`]: {
    width: 'calc(100% - 70px)',
    margin: '10px 0 20px',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    listStyleType: 'none',
    '& li': {
      margin: '15px 10px',
      width: '100%'
    }
  },
  [`& .${classes.navitem}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: Questions,
    textDecoration: 'none',
    width: '100%',
    fontSize: 20,
    ['& svg']: {
      fontSize: 30
    }
  },
  [`& .${classes.navitemlabel}`]: {
    marginLeft: 15
  },
  [`& .${classes.selected}`]: {
    color: Tundora
  },
  [`& .${classes.logout}`]: {
    marginTop: 'auto',
    marginBottom: 30,
    color: CopperRose,
    background: 'transparent',
    border: `2px solid ${CopperRose}`
  }
});

export const logout = (): void => {
  // erase the token directly from localstorage, so react view does not rerender and user is not
  //   taken to the "somehow you got logged out" page.
  eraseToken();
  // bring user back to home page
  browserNavigate('/');
};

const LeftNavItem: React.FC<{
  to: string;
  name: string;
  icon: ReactNode;
  selected: boolean;
}> = ({ to, name, icon, selected }) => {
  return (
    <Link
      className={classNames(classes.navitem, { [classes.selected]: selected })}
      to={to}
    >
      {icon}
      <span className={classes.navitemlabel}>{name}</span>
    </Link>
  );
};

export const LeftNav: React.FC<{ selected: Tab | null }> = ({ selected }) => {
  return (
    <Root className={classes.leftnav}>
      <div className={classes.logoContainer}>
        <LogoLink />
      </div>
      <Divider widthPx={260} />
      <H7
        style={{
          margin: '12px 35px',
          textAlign: 'left',
          width: 'calc(100% - 70px)'
        }}
      >
        My Childbirth Prep
      </H7>
      <Divider widthPx={260} />
      <ul className={classes.list}>
        <li>
          <LeftNavItem
            icon={<LocalLibrary />}
            to={WebappUrls.home}
            name="Learn"
            selected={selected === 'learn'}
          />
        </li>
        <li>
          <LeftNavItem
            icon={<Description />}
            to={WebappUrls.birthPlan}
            name="Birth Plan"
            selected={selected === 'birthplan'}
          />
        </li>
        <li>
          <LeftNavItem
            icon={<Share />}
            to={WebappUrls.share}
            name="Share"
            selected={selected === 'share'}
          />
        </li>
        <li>
          <LeftNavItem
            icon={<AccountCircle />}
            to={WebappUrls.profile}
            name="Profile"
            selected={selected === 'profile'}
          />
        </li>
      </ul>
      <Button className={classes.logout} onClick={logout}>
        Sign out
      </Button>
    </Root>
  );
};
