import { styled } from '@mui/material/styles';

import ExternalUrls from '../../general/ExternalUrls';
import { mailto } from '../../general/util';
import hearticon from '../../Icons/noun-heart-135904-DFD4D5.png';
import hearthands from '../../img/img_misc_share.tab_heart.hands@2x.jpg';
import { notMobileMediaQuery } from '../../StyleGuide/Breakpoints';
import { Button } from '../../StyleGuide/Button';
import { H8, P } from '../../StyleGuide/Text';
import { PageLayout } from '../page/PageLayout';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: 20,
  [notMobileMediaQuery]: {
    paddingTop: 44
  }
});
const Hero = styled('img')({
  width: '100%'
});

export const SharePage: React.FC = () => {
  return (
    <PageLayout>
      <Root>
        <Hero alt="Share" src={hearthands} />
        <H8 style={{ margin: '20px 0 15px' }}>
          Sharing is caring.{' '}
          <img
            style={{ display: 'inline', width: 16, height: 16, marginLeft: 5 }}
            alt="Heart"
            src={hearticon}
          />
        </H8>
        <P>Share the SoShe app with pregnant friends.</P>
        <Button
          style={{ height: 44, paddingTop: 0, paddingBottom: 0 }}
          onClick={() => {
            const subject = 'Checkout SoShe app';
            const body = `Hi - I was thinking of you. You should download this new app SoShe that I'm using.

You can create a birth plan, learn about birth the real stuff, and get insider tips.

It's not another "your baby is the size of a _______" pregnancy app. This is to get you more prepared for the birth part.

Download SoShe: ${ExternalUrls.appStore}`;
            mailto({ subject, body });
          }}
        >
          Share SoShe via email
        </Button>
      </Root>
    </PageLayout>
  );
};
