import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import { isDefined } from '../../general/util';
import { H7 } from '../../StyleGuide/Text';
import { useAppContext } from '../page/WebappContext';
import { useOnboardingContext } from './OnboardingContext';
import { Options } from './Options';
import { Progress } from './Progress';

const StyledProgress = styled(Progress)({
  marginTop: 25
});

/**
 * A stage in the Onboarding flow.
 */
// TODO samgqroberts 2021-12-12 there's an additional client-added option here for home birth
export const BirthLocationStage: React.FC = () => {
  const { setStage, setNextText, setNextAction, setBirthLocationId } =
    useOnboardingContext();
  const { options } = useAppContext();
  const [bl, setBL] = useState<number | undefined>(undefined);
  useEffect(() => setNextText('Next'), []);
  useEffect(
    () =>
      setNextAction(
        bl === undefined
          ? undefined
          : () => {
              setBirthLocationId(bl);
              setStage('provider');
            }
      ),
    [bl]
  );
  // TODO samgqroberts 2021-12-10 should 'request' this data, and context should detect it's already been loaded and
  //      just serve the loaded one.
  //      can extend this to cache in localstorage as well.
  if (!options.data) {
    console.error('Options not loaded');
    return null;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledProgress progress={75} />
      <H7>Where do you plan to give birth?</H7>
      <Options<number>
        options={options.data.locations.map((l) => ({
          id: l.id,
          name: l.name
        }))}
        selected={[bl].filter(isDefined)}
        onSelect={(id) => setBL(id)}
        onDeselect={() => {}}
      />
    </div>
  );
};
