import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import { Button, ButtonLink } from '../../StyleGuide/Button';
import { Placeholder, Tundora } from '../../StyleGuide/Colors';
import { H7 } from '../../StyleGuide/Text';

const ContentDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 10,
  '& > *': {
    margin: 10
  },
  '& > input': {
    width: 140,
    border: `1px solid ${Placeholder}`,
    borderRadius: 2,
    padding: 4
  }
});

const OptionButton = styled(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selected,
    ...rest
  }: React.ComponentProps<typeof Button> & {
    selected: boolean;
  }) => <Button colorVariant={selected ? 'cta' : 'std'} {...rest} />
)(({ selected }) => ({
  width: 140,
  background: selected ? undefined : 'transparent !important',
  color: selected ? undefined : Tundora,
  border: selected ? undefined : `1px solid ${Tundora}`
}));

/**
 * Helper component meant for use in a {@link UpdateProfileInformationDialog}.
 * Contains consistent styles for cancel and submit buttons.
 */
export const UpdateProfileFooterButtons: React.FC<{
  onCancel: () => void;
  submitDisabled?: boolean;
  onSubmit: undefined | (() => void);
}> = ({ onCancel, submitDisabled = false, onSubmit }) => {
  return (
    <>
      <ButtonLink onClick={onCancel}>Cancel</ButtonLink>
      <Button disabled={submitDisabled} onClick={onSubmit}>
        Update
      </Button>
    </>
  );
};

/**
 * Helper component meant for use in a {@link UpdateProfileInformationDialog}.
 * Allows choosing different single options.
 */
export const UpdateProfileButtonList: React.FC<{
  onCancel: () => void;
  options: { id: number; name: string }[];
  initialSelectedId?: number;
  onSubmit: (selectedId: number) => void;
}> = ({ onCancel, options, initialSelectedId, onSubmit }) => {
  const [selectedId, setSelectedId] = useState<number | undefined>(
    initialSelectedId
  );
  return (
    <>
      {options.map((o) => {
        const selected = selectedId === o.id;
        return (
          <OptionButton
            {...{ selected }}
            key={o.id}
            onClick={() => setSelectedId(o.id)}
          >
            {o.name}
          </OptionButton>
        );
      })}
      <UpdateProfileFooterButtons
        {...{ onCancel }}
        submitDisabled={selectedId === undefined}
        onSubmit={
          selectedId === undefined ? undefined : () => onSubmit(selectedId)
        }
      />
    </>
  );
};

/**
 * Helper component for a dialog in the profile ecosystem that allows changing some information
 *   about the user.
 */
export const UpdateProfileInformationDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  title?: string;
}> = ({ open, onClose, title, children }) => {
  return (
    <Dialog {...{ open, onClose }}>
      <ContentDiv>
        {title && <H7>{title}</H7>}
        {children}
      </ContentDiv>
    </Dialog>
  );
};
