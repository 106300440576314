import React from 'react';

import AppStoreButton from '../general/AppStoreButton';
import Divider from '../general/Divider';
import {
  useCachedQueryParam,
  useUserIsOnIOSDevice
} from '../general/react-util';
import {
  EMAIL_QUERY_FIELD,
  PRODUCT_NAME_QUERY_FIELD
} from '../general/Routing';
import useIsMobile from '../general/useIsMobile';
import { PageLevelComponent } from '../general/util';
import babyfeet from '../img/baby-feet.jpg';
import wavebackground from '../img/wavebackground.jpg';
import { freeSkuName } from '../MembershipPlansPage/Plans';
import styles from './CheckoutSuccessPage.module.css';

function confirmationMsg(email: string | null): string {
  return email ? `We sent an email to ${email}.` : 'We sent you an email.';
}

export const WebCheckoutSuccessPage: PageLevelComponent = () => {
  const isMobile = useIsMobile();
  const productName = useCachedQueryParam(PRODUCT_NAME_QUERY_FIELD);
  const email = useCachedQueryParam(EMAIL_QUERY_FIELD);
  const oniOSDevice = useUserIsOnIOSDevice();
  const image = (
    <img className={styles.image} alt="Welcome to SoShe!" src={babyfeet} />
  );
  const instructions = oniOSDevice ? (
    <React.Fragment>
      <p>Download the app to get started.</p>
      <AppStoreButton className={styles.appStore} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <p>{confirmationMsg(email)}</p>
      <p>
        <b>To download the iPhone app:</b> Open the email on your iPhone or iPad
        and click the link to download SoShe.
      </p>
      <p>
        Or just <a href="/">sign in</a> to use SoShe on the web.
      </p>
    </React.Fragment>
  );
  return (
    <div
      className={styles.page}
      style={{
        backgroundImage: `url("${wavebackground}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: isMobile ? '200% 100%' : '100% 100%',
        backgroundPosition: 'center'
      }}
    >
      <div className={styles.inner}>
        {productName === freeSkuName ? (
          <span>Your Plan: FREE Birth Plan. (Upgrade anytime.)</span>
        ) : (
          <span>Thank you. Payment Complete.</span>
        )}
        <Divider className={styles.divider} />
        {isMobile ? (
          <div className={styles.below}>
            {image}
            {instructions}
          </div>
        ) : (
          <div className={styles.below}>
            {image}
            <div className={styles.right}>{instructions}</div>
          </div>
        )}
      </div>
    </div>
  );
};
