import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { appEnv } from '../general/AppEnv';
import LoaderOverlay from '../general/LoaderOverlay';
import PageUrls, {
  PRODUCT_NAME_QUERY_FIELD,
  toAppCheckoutSuccess,
  toCheckoutSuccess,
  useQueryParams
} from '../general/Routing';
import { ProductsService, Sku } from '../general/ServerClient';
import {
  addToCartGTM,
  dataLayerGTM,
  getProductGTMData
} from '../general/tagManager';
import { useCheckoutInfo, useUserInfo } from '../general/useUserInfo';
import { PageLevelComponent } from '../general/util';
import { UserInfo } from '../MembershipPlansPage/Plans';
import CheckoutForm, { PaymentSuccessHandler } from './CheckoutForm';
import styles from './CheckoutPage.module.css';

const stripePromise = loadStripe(appEnv('STRIPE_CLIENT_API_KEY'));

const CheckoutFormWrapper: React.FC<{
  productName: string;
  userInfo: UserInfo;
  addToCartOnLoad: boolean;
  onPaymentSuccess: PaymentSuccessHandler;
}> = ({ productName, userInfo, addToCartOnLoad, onPaymentSuccess }) => {
  const stripeM = useStripe();
  const elementsM = useElements();
  const [loadedProduct, setLoadedProduct] = useState<boolean>(false);
  const [product, setProduct] = useState<Sku | undefined>(undefined);
  useEffect(() => {
    ProductsService.productsList()
      .then((list) => {
        const p = list.results?.find((p) => p.name === productName);
        if (p) {
          setProduct(p);
          if (addToCartOnLoad) {
            addToCartGTM(p);
          }
          dataLayerGTM({ ecommerce: null });
          dataLayerGTM({
            event: 'checkout',
            ecommerce: {
              checkout: {
                actionField: { step: 1, option: 'Visa' },
                products: [getProductGTMData(p)]
              }
            }
          });
        }
      })
      .finally(() => {
        setLoadedProduct(true);
      });
  }, []);
  const loading = !loadedProduct;
  return (
    <React.Fragment>
      {stripeM && elementsM ? (
        <CheckoutForm
          stripe={stripeM}
          elements={elementsM}
          {...{
            productName,
            userInfo,
            product,
            onPaymentSuccess
          }}
          error={
            loadedProduct && !product
              ? 'Could not connect to server. Please refresh your page to try again.'
              : undefined
          }
        />
      ) : (
        <div className={styles.errorConnecting}>
          Could not connect to Stripe. Please refresh your page to try again.
        </div>
      )}
      {loading && <LoaderOverlay />}
    </React.Fragment>
  );
};

export const CheckoutPage: React.FC<{
  productName: string | null;
  addToCartOnLoad: boolean;
  onPaymentSuccess: PaymentSuccessHandler;
  userInfo: UserInfo;
}> = ({ addToCartOnLoad, onPaymentSuccess, productName, userInfo }) => {
  return (
    <Elements stripe={stripePromise}>
      {productName ? (
        <CheckoutFormWrapper
          {...{
            productName,
            userInfo,
            addToCartOnLoad,
            onPaymentSuccess
          }}
        />
      ) : (
        <span>Cannot checkout, product name invalid: {productName}</span>
      )}
    </Elements>
  );
};

export const WebCheckoutPage: PageLevelComponent = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const productName = queryParams[PRODUCT_NAME_QUERY_FIELD];
  const userInfo = queryParams;
  return (
    <CheckoutPage
      {...{ productName, userInfo }}
      addToCartOnLoad={false}
      onPaymentSuccess={({ email, productName }) => {
        history.push(toCheckoutSuccess({ email, productName }));
      }}
    />
  );
};

export const GenericAppCheckoutPage: PageLevelComponent = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const productName = queryParams[PRODUCT_NAME_QUERY_FIELD];
  const userInfo = queryParams;
  return (
    <CheckoutPage
      {...{ productName, userInfo }}
      addToCartOnLoad={true}
      onPaymentSuccess={({ email, productName, couponUsed, pricePaid }) => {
        history.push(
          toAppCheckoutSuccess({ email, productName, couponUsed, pricePaid })
        );
      }}
    />
  );
};

export const IosAppCheckoutPage: PageLevelComponent = () => {
  const history = useHistory();
  const userInfoFromLocalStorage = useUserInfo(false);
  const checkoutInfoFromLocalStorage = useCheckoutInfo(true);
  return (
    <CheckoutPage
      productName={userInfoFromLocalStorage.userInfo.productName}
      userInfo={userInfoFromLocalStorage.userInfo}
      addToCartOnLoad={true}
      onPaymentSuccess={({ email, productName, couponUsed, pricePaid }) => {
        userInfoFromLocalStorage.setEmail(email);
        userInfoFromLocalStorage.setProductName(productName);
        checkoutInfoFromLocalStorage.setCouponUsed(couponUsed || undefined);
        checkoutInfoFromLocalStorage.setPricePaid(pricePaid);
        history.push(PageUrls.iosCheckoutSuccess);
      }}
    />
  );
};
