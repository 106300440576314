import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { MissingParametersMessage } from '../general/CenteredMessage';
import LoaderOverlay from '../general/LoaderOverlay';
import {
  MembershipPlansService,
  PaginatedMembershipPlanList,
  PaginatedSkuList,
  ProductsService
} from '../general/ServerClient';
import { useContainerDimensions } from '../general/useContainerDimensions';
import wavebackground from '../img/wavebackground.jpg';
import { MobileBreakpoint } from '../StyleGuide/Breakpoints';
import { H2 } from '../StyleGuide/Text';
import { MobileProvider, useMobileContext } from './MobileContext';
import Plan from './Plan';
import styles from './Plans.module.css';

// must be synced with backend SKU name field!
const calloutPlanName = 'birth_class';
export const freeSkuName = 'birth_plan';

export interface UserInfo {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  currentPlan: string | null;
}

export type FreeClaimSuccessHandler = (productName: string) => void;
export type PaidSkuSignupHandler = (productName: string) => void;

export interface PlansPropsNotRegistered {
  alreadyRegistered: false;
  onFreeClaimSuccess: FreeClaimSuccessHandler;
  onPaidSkuSignup: PaidSkuSignupHandler;
}

export interface PlansPropsAlreadyRegistered {
  alreadyRegistered: true;
  onFreeClaimSuccess: FreeClaimSuccessHandler;
  onPaidSkuSignup: PaidSkuSignupHandler;
  userInfo: UserInfo;
}

export type PlansProps = PlansPropsNotRegistered | PlansPropsAlreadyRegistered;

const PlansContextualized: React.FC<PlansProps> = (props) => {
  const { alreadyRegistered } = props;
  const { email, currentPlan: currentPlanName } =
    'userInfo' in props ? props.userInfo : ({} as UserInfo);
  const { setIsMobile } = useMobileContext();
  const componentRef = useRef<HTMLDivElement>(null);
  const dimensions = useContainerDimensions(componentRef);
  const isMobileDetected = dimensions && dimensions.width < MobileBreakpoint;
  useEffect(() => {
    if (typeof isMobileDetected === 'boolean') {
      setIsMobile(isMobileDetected);
    }
  }, [isMobileDetected]);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [loadingPlans, setLoadingPlans] = useState<boolean>(false);
  const [claimingFreeSku, setClaimingFreeSku] = useState<boolean>(false);
  const [productsList, setProductsList] = useState<PaginatedSkuList | null>(
    null
  );
  const [plansList, setPlansList] =
    useState<PaginatedMembershipPlanList | null>(null);
  useEffect(() => {
    setLoadingProducts(true);
    ProductsService.productsList()
      .then((list) => {
        setLoadingProducts(false);
        setProductsList(list);
      })
      .catch(() => {
        setLoadingProducts(false);
      });
  }, []);
  useEffect(() => {
    setLoadingPlans(true);
    MembershipPlansService.membershipPlansList()
      .then((list) => {
        setLoadingPlans(false);
        setPlansList(list);
      })
      .catch(() => {
        setLoadingPlans(false);
      });
  }, []);

  const currentPlan = currentPlanName
    ? plansList?.results?.find((plan) => plan.database_name === currentPlanName)
    : null;

  const products = !plansList
    ? undefined
    : productsList?.results?.filter((sku) => {
        if (alreadyRegistered) {
          // always show current plan when upgrading
          if (
            currentPlan &&
            sku.plan.database_name === currentPlan.database_name
          ) {
            return true;
          }
          // if this is not the upgrade version, show free and nonfree base skus
          if (!currentPlan || currentPlan.is_default) return !sku.upgrade_from;
          // if user is currently on free plan, show nonfree base skus
          if (currentPlan.is_free)
            return !sku.upgrade_from && !sku.plan.is_free;
          // otherwise show only skus that upgrade from the user's current plan
          return sku.upgrade_from?.database_name === currentPlanName;
        }
        // this is not upgrades version of page, show free and nonfree base skus
        return !sku.upgrade_from;
      });

  // if we're assuming we've already registered (meaning we'll proceed directly to the checkout page)
  //   then we need to have already been supplied the user's email
  if (alreadyRegistered && !email) {
    return <MissingParametersMessage parameters={['email']} />;
  }

  return (
    <div
      ref={componentRef}
      className={classNames(styles.plans, {
        [styles.mobile]: isMobileDetected
      })}
      style={{
        backgroundImage: `url("${wavebackground}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: isMobileDetected ? 'cover' : '100% 100%',
        backgroundPosition: isMobileDetected ? 'initial' : 'center'
      }}
    >
      <H2 className={styles.header}>
        {currentPlanName ? 'Upgrade Your Plan' : "What's Your Plan?"}
      </H2>
      <div className={styles.planCards}>
        {productsList
          ? products &&
            products.map((product) => {
              const destination: string | (() => void) = (() => {
                if (product.name === freeSkuName) {
                  return () => {
                    setClaimingFreeSku(true);
                    props.onFreeClaimSuccess(product.name);
                  };
                } else {
                  return () => props.onPaidSkuSignup(product.name);
                }
              })();
              return (
                <Plan
                  key={product.name}
                  {...product}
                  {...{ destination, calloutPlanName }}
                  isCurrentPlan={
                    !!(
                      currentPlan &&
                      product.plan.database_name === currentPlan.database_name
                    )
                  }
                />
              );
            })
          : [1, 2].map((n) => (
              <div
                key={n}
                className={classNames(styles.plan, styles.placeholderPlan)}
              />
            ))}
        {(loadingProducts || loadingPlans || claimingFreeSku) && (
          <LoaderOverlay />
        )}
      </div>
    </div>
  );
};

const Plans: React.FC<PlansProps> = (props) => {
  return (
    <MobileProvider>
      <PlansContextualized {...props} />
    </MobileProvider>
  );
};
export default Plans;
