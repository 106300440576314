import React from 'react';

import { BirthPlan, BirthPlanService } from '../../general/ServerClient';
import { useAppContext } from '../page/WebappContext';
import {
  UpdateProfileButtonList,
  UpdateProfileInformationDialog
} from './UpdateProfileInformationDialog';

export const UpdateBirthTypeDialog: React.FC<{
  isOpen: boolean;
  dismiss: () => void;
}> = ({ isOpen, dismiss }) => {
  const { birthPlan, options } = useAppContext();

  return (
    <UpdateProfileInformationDialog
      open={isOpen}
      onClose={dismiss}
      title="What type of birth are you planning or hoping for?"
    >
      {options.data ? (
        <UpdateProfileButtonList
          options={options.data.birth_types}
          initialSelectedId={birthPlan.data?.birth_type}
          onCancel={dismiss}
          onSubmit={(selectedId) => {
            if (birthPlan.data) {
              const newBirthPlan: BirthPlan = {
                ...birthPlan.data,
                birth_type: selectedId
              };
              birthPlan.setData(newBirthPlan);
              BirthPlanService.birthPlanProfileCreate(newBirthPlan);
            }
            dismiss();
          }}
        />
      ) : (
        <div>Error: no birth plan options</div>
      )}
    </UpdateProfileInformationDialog>
  );
};
