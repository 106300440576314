import { CloudDownload, Visibility } from '@mui/icons-material';
import { Dialog, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { appEnv } from '../../general/AppEnv';
import LoaderOverlay from '../../general/LoaderOverlay';
import CloseIcon from '../../Icons/CloseIcon';
import { CopperRose } from '../../StyleGuide/Colors';
import { useAuthContext } from '../page/Auth';

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '50px 5px',
  marginBottom: -20
});
const iconStyles = {
  fontSize: '18px !important',
  marginRight: 5
};
const VisibilityIcon = styled(Visibility)(iconStyles);
const DownloadIcon = styled(CloudDownload)(iconStyles);
const Action = styled('button')({
  background: 'transparent',
  padding: '5px 15px',
  border: `1px solid ${CopperRose}`,
  color: CopperRose,
  borderRadius: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 115,
  cursor: 'pointer',
  fontSize: 12,
  '&:not(:first-of-type)': {
    marginLeft: 20
  }
});

const downloadUrl = `${appEnv('SERVER_BASE_URL')}/api/v1/birth-plan/download`;

function fetchPdfBlob(accessToken: string | undefined): Promise<Blob> {
  return fetch(downloadUrl, {
    headers: new Headers({ Authorization: `Bearer ${accessToken}` })
  }).then((d) => d.blob());
}

function downloadPdf(filename: string, blob: Blob) {
  const pdfBlob = new Blob([blob], { type: 'application/pdf' });
  const data = window.URL.createObjectURL(pdfBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = filename;
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 100);
}

/**
 * List of action buttons related to the Birth Plan PDF.
 */
export const PlanActions: React.FC = () => {
  const { access } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);

  const [pdfBlob, setPdfBlob] = useState<Blob | undefined>(undefined);

  return (
    <Root>
      <Action
        onClick={() => {
          setLoading(true);
          fetchPdfBlob(access).then((blob) => {
            setPdfBlob(blob);
            setLoading(false);
          });
        }}
      >
        <VisibilityIcon />
        View
      </Action>
      <Action
        onClick={() => {
          setLoading(true);
          fetchPdfBlob(access).then((d) => {
            downloadPdf('BirthPlan.pdf', d);
            setLoading(false);
          });
        }}
      >
        <DownloadIcon />
        Download
      </Action>
      <Dialog fullScreen open={!!pdfBlob} onClose={() => setPdfBlob(undefined)}>
        <div>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setPdfBlob(undefined)}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <div>
            {pdfBlob && (
              <Document file={pdfBlob}>
                <Page pageNumber={1} />
                <Page pageNumber={2} />
              </Document>
            )}
          </div>
        </div>
      </Dialog>
      {loading && <LoaderOverlay />}
    </Root>
  );
};
