import { styled } from '@mui/material/styles';
import React from 'react';

import { mobileMediaQuery } from '../../StyleGuide/Breakpoints';
import { LinkButton } from '../../StyleGuide/Button';
import { White } from '../../StyleGuide/Colors';
import { H2, H7 } from '../../StyleGuide/Text';
import { OverImageProgress } from '../components/OverImageProgress';
import { SectionAndProgress } from '../util/util';

const Root = styled('div')({
  position: 'relative',
  width: 'calc(100% - 40px)',
  margin: '0 auto 0',
  paddingTop: 42,
  [mobileMediaQuery]: {
    paddingTop: 20
  }
});
const Img = styled('img')({
  width: '100%',
  borderRadius: 18,
  display: 'block'
});
// TODO samgqroberts 2022-03-15 extract gradient component, or perhaps entire "img behind gradient" treatment
const Gradient = styled('div')({
  backgroundImage: 'linear-gradient(transparent, black)',
  position: 'absolute',
  bottom: 0,
  top: '40%',
  left: 0,
  right: 0,
  opacity: 0.7,
  borderRadius: 18
});
const ProgressMetrics = styled('div')({
  position: 'absolute',
  left: 0,
  right: 0,
  padding: '0 20px',
  bottom: 100,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  color: White,
  [mobileMediaQuery]: {
    bottom: 40
  }
});
const InProgress = styled(H7)({
  fontSize: 24,
  textAlign: 'left',
  margin: 0,
  [mobileMediaQuery]: {
    fontWeight: 'normal',
    fontSize: 16
  }
});
const Middle = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center'
});
const SectionTitle = styled(H2)({
  fontSize: 40,
  margin: '10px 0',
  [mobileMediaQuery]: {
    fontSize: 22,
    margin: '5px 0'
  }
});
const ProgressContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 15px',
  margin: '10px 0 10px auto',
  flexShrink: 0,
  [mobileMediaQuery]: {
    paddingRight: 0,
    margin: 0,
    marginLeft: 'auto'
  }
});
const Continue = styled(LinkButton)({
  position: 'absolute',
  boxShadow: '6px 6px 6px 1px rgb(0 0 0 / 32%)',
  bottom: -22,
  width: 250,
  borderRadius: 22,
  height: 44,
  padding: 0,
  left: 'calc(50% - 125px)',
  fontSize: 16
});

/**
 * Renders the "in progress" section in a list of course sections.
 */
export const InProgressSection: React.FC<{
  section: SectionAndProgress;
  path: string;
  allSectionsNotStarted: boolean;
}> = ({ section, path, allSectionsNotStarted }) => {
  return (
    <Root>
      <Img src={section.section.image_2x} alt="In-progress section" />
      <Gradient />
      <ProgressMetrics>
        <InProgress>
          {allSectionsNotStarted ? 'Start With' : 'In Progress'}
        </InProgress>
        <Middle>
          <SectionTitle>{section.section.title}</SectionTitle>
          <ProgressContainer>
            <OverImageProgress
              value={section.progress.overall.percent_completed}
            />
          </ProgressContainer>
        </Middle>
      </ProgressMetrics>
      <Continue to={path}>Continue</Continue>
    </Root>
  );
};
