import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Image,
  MediaService,
  SectionProgress,
  TerseSection
} from '../../general/ServerClient';
import useIsMobile from '../../general/useIsMobile';
import { mobileMediaQuery } from '../../StyleGuide/Breakpoints';
import {
  Concrete,
  CopperRose,
  Placeholder,
  Questions,
  Tundora
} from '../../StyleGuide/Colors';
import { CircularProgressWithLabel } from '../components/CircularProgressWithLabel';
import { useRetryFetch } from '../page/Auth';

const height = 80;
const spaceBetweenSectionItems = 34;
const paddingBetweenSectionItems = spaceBetweenSectionItems / 2;

const Root = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  padding: `${paddingBetweenSectionItems}px 0`,
  textDecoration: 'none',
  height,
  ['&:first-of-type']: {
    marginTop: paddingBetweenSectionItems
  }
});
const Completed = styled(CircularProgressWithLabel)({
  marginLeft: 'auto',
  color: Tundora
});
const Right = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  margin: '0 0 0 20px',
  height: '100%',
  borderBottom: `1px solid ${Concrete}`
});
const Center = styled('div')({});
const Title = styled('span')({
  fontSize: 20,
  color: 'black',
  fontWeight: 'bold',
  [mobileMediaQuery]: {
    fontSize: 16
  }
});
const Estimate = styled('div')({
  color: Questions,
  fontSize: 14,
  marginTop: 3
});
const CircleImageContainer = styled('div')({
  width: height,
  height,
  overflow: 'hidden',
  borderRadius: '50%',
  '& > img': {
    height: '100%',
    marginLeft: '-25%'
  }
});
const CircleImage: React.FC<{ alt: string; src: string }> = ({ alt, src }) => {
  return (
    <CircleImageContainer>
      <img {...{ alt, src }} />
    </CircleImageContainer>
  );
};

/**
 * Renders a particular section in a list of course sections.
 */
export const SectionItem: React.FC<{
  section: TerseSection;
  index: number;
  progress?: SectionProgress;
  path: string;
}> = ({ section, index, progress, path }) => {
  const isMobile = useIsMobile();
  const { title, image } = section;
  const [imageObj, setImageObj] = useState<Image | undefined>(undefined);
  // TODO samgqroberts 2021-12-06 this should use remotedata tracking, + perhaps this is unnecessary with
  //      the section object now serving image url directly
  if (image) {
    useRetryFetch(() => MediaService.mediaImagesRetrieve(image), setImageObj);
  }
  const to = path;
  const percentCompleted = progress ? progress.overall.percent_completed : 0;
  return (
    <Root {...{ to }}>
      {imageObj && <CircleImage alt={title} src={imageObj.url_2x} />}
      <Right>
        <Center>
          <Title>
            {index + 1}. {title}
          </Title>
          {section.estimated_time && (
            <Estimate>({section.estimated_time})</Estimate>
          )}
        </Center>
        <Completed
          value={percentCompleted}
          backgroundColor={Placeholder}
          foregroundColor={percentCompleted === 0 ? Placeholder : CopperRose}
          size={isMobile ? 60 : 70}
        />
      </Right>
    </Root>
  );
};
