import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import Divider from '../../general/Divider';
import { Answer, Question } from '../../general/ServerClient';
import { mobileMediaQuery } from '../../StyleGuide/Breakpoints';
import { Placeholder } from '../../StyleGuide/Colors';
import { BirthPlanStarIcon } from '../components/BirthPlanStarIcon';
import { WebappUrls } from '../page/WebappUrls';
import { CompletedIcon } from './CompletedIcon';

export const widescreenHeaderFontSize = 20;

const StyledLink = styled(Link)({
  color: 'black',
  textDecoration: 'none'
});
const Top = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: `13px 0 5px`,
  [mobileMediaQuery]: {
    margin: `5px 0 0`
  }
});
const Completed = styled('div')({
  '& > svg': {
    marginTop: 7
  }
});
const Header = styled('div')({
  fontWeight: 'bold',
  fontSize: widescreenHeaderFontSize,
  marginLeft: 10,
  [mobileMediaQuery]: {
    fontWeight: 'bold',
    fontSize: 14,
    marginLeft: 10
  }
});
const AnswerContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '0 8px',
  margin: '6px 0',
  [mobileMediaQuery]: {
    margin: '5px 0',
    ['&:last-of-type']: {
      marginBottom: 9
    }
  }
});
const AnswerText = styled('div')({
  fontSize: 14,
  marginLeft: 17,
  [mobileMediaQuery]: {
    fontSize: 13
  }
});
const Middot = styled('span')({
  lineHeight: '17px',
  fontSize: 35,
  color: Placeholder
});
const Right = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});
const StyledDivider = styled(Divider)({
  borderColor: Placeholder,
  margin: '10px 0 0 auto',
  [mobileMediaQuery]: {
    margin: '6px 0 0 auto'
  }
});
const StyledStarIcon = styled(BirthPlanStarIcon)({
  marginLeft: 'auto',
  marginRight: 13
});

/**
 * Displays information about a question in a section, including header and completion status.
 */
export const QuestionItem: React.FC<{
  question: Question;
  answers: Answer[];
  path: string;
  showDivider: boolean;
  showStar?: boolean;
  lockedBehindPaywall: boolean;
}> = ({
  question,
  answers,
  path,
  showDivider,
  showStar,
  lockedBehindPaywall
}) => {
  return (
    <StyledLink to={lockedBehindPaywall ? WebappUrls.upgrade : path}>
      <Top>
        <Completed>
          <CompletedIcon
            completed={answers.length > 0}
            incompleteIsQuestionMark
          />
        </Completed>
        <Header>{question.header}</Header>
        {showStar && <StyledStarIcon />}
      </Top>
      <Right>
        {answers.map((answer) => {
          return (
            <AnswerContainer key={answer.id}>
              <Middot>&middot;</Middot>
              <AnswerText>{answer.text}</AnswerText>
            </AnswerContainer>
          );
        })}
        {showDivider && <StyledDivider width={`calc(100% - 35px)`} />}
      </Right>
    </StyledLink>
  );
};
