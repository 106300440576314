import { generatePath } from 'react-router-dom';

export const WebappUrls = {
  home: '/',
  learn: '/learn',
  learnSection: '/learn/:sectionId',
  learnQuestion: '/learn/:sectionId/question/:questionId',
  learnContent: '/learn/:sectionId/content/:contentBlockName',
  birthPlan: '/plan',
  planSection: '/plan/:sectionId',
  planQuestion: '/plan/:sectionId/question/:questionId',
  share: '/share',
  profile: '/profile',
  upgrade: '/profile/upgrade',
  checkout: '/profile/checkout',
  upgradeSuccess: '/profile/upgrade-success'
};

export const toPlanSection = (params: { sectionId: number }): string =>
  generatePath(WebappUrls.planSection, params);

export const toLearnQuestion = (params: {
  sectionId: number;
  questionId: number;
}): string => generatePath(WebappUrls.learnQuestion, params);

export const toLearnContent = (params: {
  sectionId: number;
  contentBlockName: string;
}): string => generatePath(WebappUrls.learnContent, params);
