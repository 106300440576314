import { PlayCircleFilledRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import isNumber from 'predicates/number';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';

import LoaderOverlay from '../../general/LoaderOverlay';
import {
  MediaService,
  SectionVideo,
  VimeoVideoInformation
} from '../../general/ServerClient';
import CloseIcon from '../../Icons/CloseIcon';
import { mobileMediaQuery } from '../../StyleGuide/Breakpoints';
import { Questions } from '../../StyleGuide/Colors';
import { H7, Span } from '../../StyleGuide/Text';
import { WebappUrls } from '../page/WebappUrls';
import { VimeoPlayer } from '../vimeo/VimeoPlayer';
import { CompletedIcon } from './CompletedIcon';
import { widescreenHeaderFontSize } from './QuestionItem';

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center'
});
const Title = styled(H7)({
  fontSize: widescreenHeaderFontSize,
  margin: 0,
  lineHeight: '32px',
  [mobileMediaQuery]: {
    fontSize: 14,
    lineHeight: '21px'
  }
});
const Thumbnail = styled('button')({
  width: 166,
  height: 93,
  flexShrink: 0,
  cursor: 'pointer',
  borderRadius: 15,
  border: 0,
  padding: 0,
  overflow: 'hidden',
  position: 'relative',
  color: 'black',
  [mobileMediaQuery]: {
    width: '42vw',
    height: 'initial'
  }
});
const ThumbnailImg = styled('img')({
  width: '100%'
});
const Right = styled('div')({
  marginLeft: 20
});
const Completion = styled('div')({
  marginTop: 4,
  display: 'flex',
  alignItems: 'center'
});

/**
 * Displays teaser data about a "LessonItem", including a thumbnail of the video in the lesson
 *   that opens a full dialog showing the video.
 */
export const LessonItem: React.FC<{
  video: SectionVideo;
  onUserWatchedVideo: (videoId: number) => void;
  lockedBehindPaywall: boolean;
}> = ({ video, onUserWatchedVideo, lockedBehindPaywall }) => {
  const history = useHistory();
  const [watched, setWatched] = useState<boolean | undefined>(undefined);
  const [vimeoInfo, setVimeoInfo] = useState<VimeoVideoInformation | undefined>(
    undefined
  );
  const vimeoPlayerLink = vimeoInfo?.player_link;
  const vimeoThumbnail = vimeoInfo?.thumbnail;
  const vimeoDuration = vimeoInfo?.duration;
  const [watchingVideo, setWatchingVideo] = useState<boolean>(false);
  useEffect(() => {
    setWatched(undefined);
    // TODO samgqroberts 2021-11-27 move this to context and/or use a retry fetch
    MediaService.mediaVideoWatchedRetrieve(video.id).then((status) => {
      setWatched(status.watched);
    });
  }, []);
  useEffect(() => {
    // TODO samgqroberts 2021-11-27 move this to context and/or use a retry fetch
    MediaService.mediaVimeoVideoRetrieve(video.vimeo_id).then((videoInfo) => {
      setVimeoInfo(videoInfo);
    });
  }, []);
  return (
    <Root key={video.id}>
      <Thumbnail
        onClick={
          lockedBehindPaywall
            ? () => history.push(WebappUrls.upgrade)
            : () => setWatchingVideo(true)
        }
      >
        {vimeoThumbnail ? (
          <>
            <ThumbnailImg src={vimeoThumbnail.link} alt={video.title} />
            <PlayCircleFilledRounded
              sx={{
                position: 'absolute',
                fontSize: 50,
                top: `calc(50% - 25px)`,
                left: `calc(50% - 25px)`,
                pointerEvents: 'none',
                opacity: 0.4
              }}
            />
          </>
        ) : (
          <LoaderOverlay />
        )}
      </Thumbnail>
      <Right>
        <Title>{video.title}</Title>
        <Completion>
          {watched !== undefined && <CompletedIcon completed={watched} />}
          {isNumber(vimeoDuration) && (
            <Span
              style={{
                fontSize: 12,
                color: Questions,
                marginLeft: 4
              }}
            >{`${Math.floor(vimeoDuration / 60)} min`}</Span>
          )}
        </Completion>
      </Right>
      <Dialog
        fullScreen
        open={!!watchingVideo}
        onClose={() => setWatchingVideo(false)}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            background: 'black'
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setWatchingVideo(false)}
              aria-label="close"
              style={{ fill: 'white' }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <div
            style={{
              position: 'relative',
              flexGrow: 1,
              overflow: 'hidden'
            }}
          >
            {vimeoPlayerLink && (
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <VimeoPlayer
                      videoId={video.id}
                      link={vimeoPlayerLink}
                      title={video.title}
                      {...{ height, width }}
                      onUserWatchedVideo={(videoId) => {
                        setWatched(true);
                        onUserWatchedVideo(videoId);
                      }}
                      onVideoEnded={() => {
                        setWatchingVideo(false);
                      }}
                    />
                  );
                }}
              </AutoSizer>
            )}
          </div>
        </div>
      </Dialog>
    </Root>
  );
};
