import classNames from 'classnames';

import Loader from './Loader';
import styles from './LoaderOverlay.module.css';

const LoaderOverlay: React.FC<{
  height?: number;
  width?: number;
  className?: string;
}> = ({ height, width, className, children }) => {
  return (
    <div className={classNames(styles.overlay, className)}>
      <Loader {...{ height, width }} className={styles.loader} />
      {children && <div className={styles.children}>{children}</div>}
    </div>
  );
};
export default LoaderOverlay;
