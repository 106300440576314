/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FullSection } from '../models/FullSection';
import type { PaginatedCourseList } from '../models/PaginatedCourseList';
import type { UserAnswer } from '../models/UserAnswer';
import type { UserProgress } from '../models/UserProgress';
import { request as __request } from '../core/request';

export class CoursesService {

    /**
     * Retrieve all courses and their sections
     * @param page A page number within the paginated result set.
     * @returns PaginatedCourseList
     * @throws ApiError
     */
    public static async coursesList(
        page?: number,
    ): Promise<PaginatedCourseList> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/courses`,
            query: {
                'page': page,
            },
        });
        return result.body;
    }

    /**
     * @param id
     * @returns FullSection
     * @throws ApiError
     */
    public static async coursesSectionsRetrieve(
        id: number,
    ): Promise<FullSection> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/courses/sections/${id}`,
        });
        return result.body;
    }

    /**
     * Provides all UserAnswers for the logged-in user for the specified section.
     * Filters out inactive answers.
     * @param id
     * @returns UserAnswer
     * @throws ApiError
     */
    public static async coursesSectionsUserAnswersList(
        id: number,
    ): Promise<Array<UserAnswer>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/courses/sections/${id}/user-answers`,
        });
        return result.body;
    }

    /**
     * Retrieves user progress information by section id for the specified course
     * @param id
     * @returns UserProgress
     * @throws ApiError
     */
    public static async coursesUserProgressRetrieve(
        id: number,
    ): Promise<UserProgress> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/courses/${id}/user-progress`,
        });
        return result.body;
    }

}