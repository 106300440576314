import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { ContentBlock } from '../../general/ServerClient';
import { Questions } from '../../StyleGuide/Colors';
import { toLearnContent, WebappUrls } from '../page/WebappUrls';

const StyledImg = styled('img')({
  width: '100%',
  borderRadius: 10
});
const Title = styled('div')({
  fontSize: 14,
  fontWeight: 'bold',
  marginTop: 7,
  marginBottom: 8,
  color: 'black'
});
const ReadingTime = styled('div')({
  fontSize: 12,
  color: Questions
});

/**
 * Displays teaser data about a ContentBlock object, including an image and title of the article.
 *   When click, navigates to the full article.
 */
export const ContentBlockItem: React.FC<{
  sectionId: number;
  contentBlock: ContentBlock | 'justForSpacing';
  lockedBehindPaywall: boolean;
}> = ({ sectionId, contentBlock, lockedBehindPaywall }) => {
  const content = contentBlock !== 'justForSpacing' && (
    <>
      <StyledImg src={contentBlock.image_2x} alt="Content Block" />
      <Title>{contentBlock.title}</Title>
      {contentBlock.reading_time && (
        <ReadingTime>{contentBlock.reading_time}</ReadingTime>
      )}
    </>
  );
  const rootStyles: React.CSSProperties = {
    width: '36%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    paddingBottom: 14,
    textAlign: 'left',
    textDecoration: 'none',
    background: 'transparent',
    border: 0,
    fontFamily: 'Open Sans',
    outline: 'none',
    cursor: 'pointer'
  };
  return contentBlock === 'justForSpacing' ? (
    <a href={undefined} style={rootStyles}>
      {content}
    </a>
  ) : (
    <Link
      to={
        lockedBehindPaywall
          ? WebappUrls.upgrade
          : toLearnContent({
              sectionId,
              contentBlockName: contentBlock.database_name
            })
      }
      style={rootStyles}
    >
      {content}
    </Link>
  );
};
