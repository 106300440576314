import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';

import { MissingParametersMessage } from '../general/CenteredMessage';
import Divider from '../general/Divider';
import LoaderOverlay from '../general/LoaderOverlay';
import { useCachedQueryParam } from '../general/react-util';
import {
  PRODUCT_NAME_QUERY_FIELD,
  toCheckout,
  toCheckoutSuccess
} from '../general/Routing';
import {
  AuthService,
  PaymentsService,
  ProductsService
} from '../general/ServerClient';
import { addToCartGTM, ifGTMEnabled } from '../general/tagManager';
import useIsMobile from '../general/useIsMobile';
import { PageLevelComponent } from '../general/util';
import wavebackground from '../img/wavebackground.jpg';
import { freeSkuName } from '../MembershipPlansPage/Plans';
import { Button } from '../StyleGuide/Button';
import { Placeholder, White, William } from '../StyleGuide/Colors';
import {
  FirstNameInput,
  IconEmailInput,
  LastNameInput,
  VisibilityTogglePasswordInput
} from '../StyleGuide/Input';
import { H7, P, Span } from '../StyleGuide/Text';
import styles from './CreateAccountPage.module.css';

export const CreateAccountPage: PageLevelComponent = () => {
  const isMobile = useIsMobile();
  const productName = useCachedQueryParam(PRODUCT_NAME_QUERY_FIELD);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [firstNameRaw, setFirstName] = useState<string>('');
  const [lastNameRaw, setLastName] = useState<string>('');
  const [agreed, setAgreed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [fatalError, setFatalError] = useState<string | undefined>(undefined);
  const history = useHistory();
  useEffect(() => {
    ifGTMEnabled(() => {
      ProductsService.productsList().then((list) => {
        const product = list.results?.find((p) => p.name === productName);
        if (product) {
          addToCartGTM(product);
        }
      });
    });
  }, []);
  if (!productName) {
    return <MissingParametersMessage parameters={['productName']} />;
  }
  const firstName = firstNameRaw === '' ? null : firstNameRaw;
  const lastName = lastNameRaw === '' ? null : lastNameRaw;
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthService.authRegisterCreate({
      email,
      password,
      first_name: firstName,
      last_name: lastName
    })
      .then(() => {
        if (productName === freeSkuName) {
          PaymentsService.paymentsClaimCreate({
            email,
            sku_name: productName
          }).then(() => {
            history.push(toCheckoutSuccess({ email, productName }));
          });
        } else {
          history.push(toCheckout({ email, productName, firstName, lastName }));
        }
      })
      .catch((r) => {
        if ('body' in r) {
          if ('email' in r.body) {
            setError(r.body.email.toString());
          } else if ('password' in r.body) {
            setError(r.body.password.toString());
          }
        } else {
          console.error(r, r.body);
          setFatalError('An unexpected error occurred. Please try again later');
        }
        setLoading(false);
      });
  };
  // TODO samgqroberts 2021-04-19 share this background styling with plans and checkout success
  return (
    <div
      className={styles.page}
      style={{
        background: `url("${wavebackground}")`,
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: isMobile ? '100% !important' : '60% !important'
      }}
    >
      <H7 className={styles.header}>Create Your Account</H7>
      <Divider className={styles.divider} widthPx={550} />
      <p className={styles.text}>
        Save your info. Save your progress. Share your birth plan.
      </p>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formInner}>
          <div className={styles.inputs}>
            <IconEmailInput
              value={email}
              setValue={setEmail}
              className={styles.emailContainer}
            />
            <VisibilityTogglePasswordInput
              value={password}
              setValue={setPassword}
              className={styles.passwordContainer}
            />
            <FirstNameInput
              className={styles.firstName}
              value={firstNameRaw}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name (optional)"
            />
            <LastNameInput
              className={styles.lastName}
              value={lastNameRaw}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name (optional)"
            />
            <Span className={styles.birthPlanNote}>
              Your Birth Plan printout will have your name on it.
            </Span>
            <label htmlFor="agreement-switch" className={styles.agreement}>
              <Switch
                id="agreement-switch"
                checked={agreed}
                onChange={setAgreed}
                offHandleColor={White}
                onHandleColor={White}
                offColor={Placeholder}
                onColor={William}
                uncheckedIcon={false}
                checkedIcon={false}
              />
              <Span className={styles.agreementText}>
                I agree to the{' '}
                {/* TODO samgqroberts 2022-02-10 extract these to variables, or at least domain */}
                <a href="https://www.soshe.com/privacy-policy">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="https://www.soshe.com/terms-of-use">Terms of Use</a>
              </Span>
            </label>
          </div>
          {error && <P className={styles.error}>{error}</P>}
          {fatalError && <P className={styles.error}>{fatalError}</P>}
          <Button
            type="submit"
            disabled={!email || !password || !agreed || loading}
            className={styles.submit}
          >
            Next
          </Button>
        </div>
      </form>
      {loading && <LoaderOverlay />}
    </div>
  );
};
