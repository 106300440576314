import React from 'react';
import { useHistory } from 'react-router-dom';

import Loader from '../general/Loader';
import PageUrls, {
  EMAIL_QUERY_FIELD,
  FIRST_NAME_QUERY_FIELD,
  LAST_NAME_QUERY_FIELD,
  toAppCheckout,
  toAppCheckoutSuccess,
  toCreateAccount,
  useQueryParams
} from '../general/Routing';
import { PaymentsService } from '../general/ServerClient';
import {
  GTM_PLANS_CATEGORY,
  useDataLayerGTMOnMount
} from '../general/tagManager';
import { useUserInfo } from '../general/useUserInfo';
import { PageLevelComponent } from '../general/util';
import FAQs from './FAQs';
import Plans, {
  FreeClaimSuccessHandler,
  PaidSkuSignupHandler,
  PlansProps
} from './Plans';

export const MembershipPlansPage: React.FC<
  PlansProps & { error?: boolean; loadingUserInfo?: boolean }
> = ({ error, loadingUserInfo, ...plansProps }) => {
  useDataLayerGTMOnMount({
    ecommerce: null // clear the previous ecommerce object
  });
  useDataLayerGTMOnMount({
    event: 'productView',
    ecommerce: {
      detail: {
        actionField: { list: GTM_PLANS_CATEGORY }
      }
    }
  });

  const plans = error ? (
    <p style={{ textAlign: 'center' }}>
      An error has occurred. Please try again later.
    </p>
  ) : loadingUserInfo ? (
    <div
      style={{
        display: 'flex',
        padding: '50px',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Loader />
    </div>
  ) : (
    <Plans {...plansProps} />
  );
  return (
    <React.Fragment>
      {plans}
      <FAQs />
    </React.Fragment>
  );
};

export const WebMembershipPlansPage: PageLevelComponent = () => {
  const history = useHistory();
  const alreadyRegistered = false;
  const onFreeClaimSuccess: FreeClaimSuccessHandler = (productName) => {
    history.push(toCreateAccount({ productName }));
  };
  const onPaidSkuSignup = onFreeClaimSuccess;
  return (
    <MembershipPlansPage
      {...{ alreadyRegistered, onFreeClaimSuccess, onPaidSkuSignup }}
    />
  );
};

export const GenericAppMembershipPlansPage: PageLevelComponent = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const alreadyRegistered = true;
  const onFreeClaimSuccess: FreeClaimSuccessHandler = (productName) => {
    const email = queryParams[EMAIL_QUERY_FIELD];
    if (email) {
      PaymentsService.paymentsClaimCreate({
        sku_name: productName,
        email
      }).then(() => {
        history.push(
          toAppCheckoutSuccess({
            email,
            productName,
            couponUsed: null,
            pricePaid: 0
          })
        );
      });
    }
  };
  const onPaidSkuSignup: PaidSkuSignupHandler = (productName) => {
    const email = queryParams[EMAIL_QUERY_FIELD];
    if (email) {
      history.push(
        toAppCheckout({
          email,
          productName,
          firstName: queryParams[FIRST_NAME_QUERY_FIELD],
          lastName: queryParams[LAST_NAME_QUERY_FIELD]
        })
      );
    }
  };
  const userInfo = queryParams;
  return (
    <MembershipPlansPage
      {...{ alreadyRegistered, onFreeClaimSuccess, onPaidSkuSignup, userInfo }}
    />
  );
};

export const IosAppMembershipPlansPage: PageLevelComponent = () => {
  const history = useHistory();
  const userInfoFromLocalStorage = useUserInfo(true);
  const alreadyRegistered = true;
  const onFreeClaimSuccess: FreeClaimSuccessHandler = (productName) => {
    const email = userInfoFromLocalStorage.userInfo.email;
    userInfoFromLocalStorage.setProductName(productName);
    if (email) {
      PaymentsService.paymentsClaimCreate({
        sku_name: productName,
        email
      }).then(() => {
        history.push(PageUrls.iosCheckoutSuccess);
      });
    }
  };
  const onPaidSkuSignup: PaidSkuSignupHandler = (productName) => {
    userInfoFromLocalStorage.setProductName(productName);
    history.push(PageUrls.iosCheckout);
  };
  const { userInfo, loadingUserInfo, error } = userInfoFromLocalStorage;
  return (
    <MembershipPlansPage
      {...{
        alreadyRegistered,
        onFreeClaimSuccess,
        onPaidSkuSignup,
        userInfo,
        loadingUserInfo,
        error
      }}
    />
  );
};
