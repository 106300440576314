import isNumber from 'predicates/number';
import isString from 'predicates/string';
import React from 'react';
import { useParams } from 'react-router-dom';

import { parseStringAsInt } from '../../general/util';
import NotFoundPage from '../../NotFoundPage';

type GenericParamsMap = Record<string, 'string' | 'int'>;

type TypedParams<ParamsMap extends GenericParamsMap> = {
  [key in keyof ParamsMap]: ParamsMap[key] extends 'string'
    ? string
    : ParamsMap[key] extends 'int'
    ? number
    : never;
};

interface QueryStringReaderProps<T extends GenericParamsMap> {
  params: T;
  children: React.FC<TypedParams<T>>;
}

/**
 * Helper React component that reads the url and allows rendering children
 *   with properly parsed and typed path parameters from the URL.
 * If any requested parameter is missing or not of the requested type, <NotFoundPage> is shown.
 */
export const PathParamsReader = <T extends GenericParamsMap>({
  params,
  children
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
QueryStringReaderProps<T>): React.ReactElement<any, any> | null => {
  const typedPathParams = useTypedPathParams(params);
  if (!typedPathParams) {
    return <NotFoundPage />;
  }
  return children(typedPathParams);
};

/**
 * React hook for providing path parameters in the url.
 * If any requested parameter is missing or not of the requested type null is returned.
 * @param params a collection of required path parameter names and their types.
 */
export const useTypedPathParams = <T extends GenericParamsMap>(
  params: T
): TypedParams<T> | null => {
  const pathParams = useParams<Record<string, string | undefined>>();
  const typedParams = Object.entries(params).reduce(
    (acc, [paramName, paramType]) => {
      const pathParam = pathParams[paramName];
      if (paramType === 'string') {
        if (isString(pathParam)) {
          return { ...acc, [paramName]: pathParam } as TypedParams<T>;
        }
      } else if (paramType === 'int') {
        const asInt = isString(pathParam) && parseStringAsInt(pathParam);
        if (isNumber(asInt)) {
          return { ...acc, [paramName]: asInt } as TypedParams<T>;
        }
      }
      return null;
    },
    {} as TypedParams<T> | null
  );
  return typedParams;
};
