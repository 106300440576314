import classNames from 'classnames';
import { forwardRef } from 'react';

import constants from './StyleConstants.module.css';
import styles from './Text.module.css';

export const BodyFont = constants['BodyFont'];
export const AltFont = constants['AltFont'];

type HeaderProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

export const Header1 = forwardRef<HTMLHeadingElement, HeaderProps>(
  function Header1(props, ref) {
    return (
      <h1
        {...{ ref }}
        {...props}
        className={classNames(styles.Header1, props.className)}
      >
        {props.children}
      </h1>
    );
  }
);
export const H1 = Header1;

export const Header2 = forwardRef<HTMLHeadingElement, HeaderProps>(
  function Header2(props, ref) {
    return (
      <h2
        {...{ ref }}
        {...props}
        className={classNames(styles.Header2, props.className)}
      >
        {props.children}
      </h2>
    );
  }
);
export const H2 = Header2;

export const Header3 = forwardRef<HTMLHeadingElement, HeaderProps>(
  function Header3(props, ref) {
    return (
      <h3
        {...{ ref }}
        {...props}
        className={classNames(styles.Header3, props.className)}
      >
        {props.children}
      </h3>
    );
  }
);
export const H3 = Header3;

export const Header4 = forwardRef<HTMLHeadingElement, HeaderProps>(
  function Header4(props, ref) {
    return (
      <h3
        {...{ ref }}
        {...props}
        className={classNames(styles.Header4, props.className)}
      >
        {props.children}
      </h3>
    );
  }
);
export const H4 = Header4;

export const Header5 = forwardRef<HTMLHeadingElement, HeaderProps>(
  function Header5(props, ref) {
    return (
      <h4
        {...{ ref }}
        {...props}
        className={classNames(styles.Header5, props.className)}
      >
        {props.children}
      </h4>
    );
  }
);
export const H5 = Header5;

export const Header6 = forwardRef<HTMLHeadingElement, HeaderProps>(
  function Header6(props, ref) {
    return (
      <h4
        {...{ ref }}
        {...props}
        className={classNames(styles.Header6, props.className)}
      >
        {props.children}
      </h4>
    );
  }
);
export const H6 = Header6;

export const Header7 = forwardRef<HTMLHeadingElement, HeaderProps>(
  function Header7(props, ref) {
    return (
      <h5
        {...{ ref }}
        {...props}
        className={classNames(styles.Header7, props.className)}
      >
        {props.children}
      </h5>
    );
  }
);
export const H7 = Header7;

export const Header8 = forwardRef<HTMLHeadingElement, HeaderProps>(
  function Header8(props, ref) {
    return (
      <h5
        {...{ ref }}
        {...props}
        className={classNames(styles.Header8, props.className)}
      >
        {props.children}
      </h5>
    );
  }
);
export const H8 = Header8;

export const Header9 = forwardRef<HTMLHeadingElement, HeaderProps>(
  function Header9(props, ref) {
    return (
      <h6
        {...{ ref }}
        {...props}
        className={classNames(styles.Header9, props.className)}
      >
        {props.children}
      </h6>
    );
  }
);
export const H9 = Header9;

type ParagraphProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>;

export const BodyParagraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
  function BodyParagraph(props, ref) {
    return (
      <p
        {...{ ref }}
        {...props}
        className={classNames(styles.Body, props.className)}
      >
        {props.children}
      </p>
    );
  }
);
export const P = BodyParagraph;

type SpanProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

export const BodySpan = forwardRef<HTMLSpanElement, SpanProps>(
  function BodySpan(props, ref) {
    return (
      <span
        {...{ ref }}
        {...props}
        className={classNames(styles.Body, props.className)}
      >
        {props.children}
      </span>
    );
  }
);
export const Span = BodySpan;
