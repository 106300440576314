import { Location } from 'history';
import * as is from 'predicates';

import { useCachedQueryParam } from './react-util';

export const FAQAnchor = 'faqs';

const BaseRoutes = {
  // Home page, with hero video
  home: '/',
  // "What's your plan?" page with FAQs
  membership: '/membership-plans',
  // Checkout page. Requires the product name to be passed in the url's query string (which happens if linked
  //   from membership plans page)
  checkout: '/checkout',
  // Page to display on successful checkout
  checkoutSuccess: '/checkout-success',
  // Create account page, reachable through plan sign up flow.
  // Requires product name in url query string.
  createAccount: '/create-account',
  // Reset password page.
  requestPasswordReset: '/reset-request',
  // Confirm password reset page.
  confirmPasswordReset: '/reset'
} as const;

// subroute for all pages in the "app onboarding flow". all pages here, eg. hide the navbar
//   and footer since the pages are expected to be embedded in an iOS app.
export const appSubroute = '/app';

const AppRoutes = {
  // "What's your plan?" page but meant for traffic coming from the app (includes Free option)
  appMembership: appSubroute + '/membership-plans',
  // Checkout page but meant for traffic coming from the app
  appCheckout: appSubroute + '/checkout',
  // Checkout success page for app flow
  appCheckoutSuccess: appSubroute + '/checkout-success'
} as const;

// basically a copy of AppRoutes but with a different protocol for handling and passing email values
export const iosSubroute = '/app/ios';

const IOSRoutes = {
  // "What's your plan?" page but meant for traffic coming from the app (includes Free option)
  iosMembership: iosSubroute + '/membership-plans',
  // Checkout page but meant for traffic coming from the app
  iosCheckout: iosSubroute + '/checkout',
  // Checkout success page for app flow
  iosCheckoutSuccess: iosSubroute + '/checkout-success'
} as const;

// subroute to contain pages that only exist to facilitate development
export const devSubroute = '/dev';

const DevRoutes = {
  styleguide: devSubroute + '/styleguide'
} as const;

const PageUrls = {
  ...BaseRoutes,
  ...AppRoutes,
  ...IOSRoutes,
  ...DevRoutes
} as const;
export default PageUrls;

export type PageUrlK = keyof typeof PageUrls;
export type PageUrlE = typeof PageUrls[PageUrlK];

// not exported to require client usage to go through helpers below that require certain params
const withQuery = (
  page: keyof typeof PageUrls,
  queryParams: { [key: string]: string | null | undefined }
): string => {
  const query = Object.entries(queryParams)
    .map(([key, value]) =>
      value ? `${key}=${encodeURIComponent(value)}` : undefined
    )
    .filter(is.string)
    .join('&');
  return `${PageUrls[page]}?${query}`;
};

export function pathname(fullpath: string): string {
  return fullpath.split('#')[0];
}

export function shouldHideNavigation(location: Location<unknown>): boolean {
  return location.pathname === PageUrls.checkout;
}

// the field in the query string expected to have the product name, used to coordinate with the backend during checkout
export const PRODUCT_NAME_QUERY_FIELD = 'productName';
export const EMAIL_QUERY_FIELD = 'email';
export const FIRST_NAME_QUERY_FIELD = 'firstName';
export const LAST_NAME_QUERY_FIELD = 'lastName';
export const CURRENT_PLAN_QUERY_FIELD = 'currentPlan';
export const COUPON_USED_QUERY_FIELD = 'couponUsed';
export const PRICE_PAID_QUERY_FIELD = 'pricePaid';

interface QueryParams {
  [PRODUCT_NAME_QUERY_FIELD]: string | null;
  [EMAIL_QUERY_FIELD]: string | null;
  [FIRST_NAME_QUERY_FIELD]: string | null;
  [LAST_NAME_QUERY_FIELD]: string | null;
  [CURRENT_PLAN_QUERY_FIELD]: string | null;
  [COUPON_USED_QUERY_FIELD]: string | null;
  [PRICE_PAID_QUERY_FIELD]: string | null;
}

export function useQueryParams(): QueryParams {
  const productName = useCachedQueryParam(PRODUCT_NAME_QUERY_FIELD);
  const email = useCachedQueryParam(EMAIL_QUERY_FIELD);
  const firstName = useCachedQueryParam(FIRST_NAME_QUERY_FIELD);
  const lastName = useCachedQueryParam(LAST_NAME_QUERY_FIELD);
  const currentPlan = useCachedQueryParam(CURRENT_PLAN_QUERY_FIELD);
  const couponUsed = useCachedQueryParam(COUPON_USED_QUERY_FIELD);
  const pricePaid = useCachedQueryParam(PRICE_PAID_QUERY_FIELD);
  return {
    productName,
    email,
    firstName,
    lastName,
    currentPlan,
    couponUsed,
    pricePaid
  };
}

/* helpers to build urls to specific pages */

export function toAppCheckoutSuccess({
  email,
  productName,
  couponUsed,
  pricePaid
}: {
  email: string;
  productName: string;
  couponUsed: string | null;
  pricePaid: number;
}): string {
  return withQuery('appCheckoutSuccess', {
    [PRODUCT_NAME_QUERY_FIELD]: productName,
    [EMAIL_QUERY_FIELD]: email,
    [COUPON_USED_QUERY_FIELD]: couponUsed,
    [PRICE_PAID_QUERY_FIELD]: encodeURIComponent(pricePaid)
  });
}

export function toCheckoutSuccess({
  email,
  productName
}: {
  email: string;
  productName: string;
}): string {
  return withQuery('checkoutSuccess', {
    [PRODUCT_NAME_QUERY_FIELD]: productName,
    [EMAIL_QUERY_FIELD]: email
  });
}

export function toCheckout({
  email,
  productName,
  firstName,
  lastName
}: {
  email: string;
  productName: string;
  firstName: string | null;
  lastName: string | null;
}): string {
  return withQuery('checkout', {
    [PRODUCT_NAME_QUERY_FIELD]: productName,
    [EMAIL_QUERY_FIELD]: email,
    [FIRST_NAME_QUERY_FIELD]: firstName,
    [LAST_NAME_QUERY_FIELD]: lastName
  });
}

export function toAppCheckout({
  email,
  productName,
  firstName,
  lastName
}: {
  email: string;
  productName: string;
  firstName: string | null;
  lastName: string | null;
}): string {
  return withQuery('appCheckout', {
    [PRODUCT_NAME_QUERY_FIELD]: productName,
    [EMAIL_QUERY_FIELD]: email,
    [FIRST_NAME_QUERY_FIELD]: firstName,
    [LAST_NAME_QUERY_FIELD]: lastName
  });
}

export function toCreateAccount({
  productName
}: {
  productName: string;
}): string {
  return withQuery('createAccount', {
    [PRODUCT_NAME_QUERY_FIELD]: productName
  });
}

export function toAppMembershipPlans({
  email,
  firstName,
  lastName,
  currentPlan
}: {
  email: string;
  firstName: string | null;
  lastName: string | null;
  currentPlan: string | null;
}): string {
  return withQuery('appMembership', {
    [EMAIL_QUERY_FIELD]: email,
    [FIRST_NAME_QUERY_FIELD]: firstName,
    [LAST_NAME_QUERY_FIELD]: lastName,
    [CURRENT_PLAN_QUERY_FIELD]: currentPlan
  });
}

export function toMembershipPlans(): string {
  return PageUrls.membership;
}
