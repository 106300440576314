/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedUserAnswerList } from '../models/PaginatedUserAnswerList';
import type { RecordAnswer } from '../models/RecordAnswer';
import type { UserAnswer } from '../models/UserAnswer';
import { request as __request } from '../core/request';

export class UserAnswersService {

    /**
     * Record a user answer
     * @param page A page number within the paginated result set.
     * @returns PaginatedUserAnswerList
     * @throws ApiError
     */
    public static async userAnswersList(
        page?: number,
    ): Promise<PaginatedUserAnswerList> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/user-answers`,
            query: {
                'page': page,
            },
        });
        return result.body;
    }

    /**
     * Record a user answer
     * @param requestBody
     * @returns UserAnswer
     * @throws ApiError
     */
    public static async userAnswersCreate(
        requestBody: RecordAnswer,
    ): Promise<UserAnswer> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/user-answers`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Remove a previous user answer
     * @param id
     * @returns any No response body
     * @throws ApiError
     */
    public static async userAnswersDestroy(
        id: number,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/v1/user-answers/${id}`,
        });
        return result.body;
    }

}