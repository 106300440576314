/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BirthPlan } from '../models/BirthPlan';
import type { BirthPlanOptions } from '../models/BirthPlanOptions';
import type { PaginatedBirthPlanList } from '../models/PaginatedBirthPlanList';
import { request as __request } from '../core/request';

export class BirthPlanService {

    /**
     * Retrieve a birth plan PDF for the user's latest plan
     * @returns any
     * @throws ApiError
     */
    public static async birthPlanDownloadRetrieve(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/birth-plan/download`,
        });
        return result.body;
    }

    /**
     * Creates a new birth profile for a user
     * @param requestBody
     * @returns BirthPlan
     * @throws ApiError
     */
    public static async birthPlanProfileCreate(
        requestBody: BirthPlan,
    ): Promise<BirthPlan> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/birth-plan/profile`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Retrieves the latest planned and actual birth plans for a user
     * @param page A page number within the paginated result set.
     * @returns PaginatedBirthPlanList
     * @throws ApiError
     */
    public static async birthPlanProfileLatestList(
        page?: number,
    ): Promise<PaginatedBirthPlanList> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/birth-plan/profile/latest`,
            query: {
                'page': page,
            },
        });
        return result.body;
    }

    /**
     * Returns options for the birth profile
     * @returns BirthPlanOptions
     * @throws ApiError
     */
    public static async birthPlanProfileOptionsRetrieve(): Promise<BirthPlanOptions> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/birth-plan/profile/options`,
        });
        return result.body;
    }

}