import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import { PageLevelComponent } from '../../general/util';
import { H7 } from '../../StyleGuide/Text';
import { useOnboardingContext } from './OnboardingContext';
import { Options } from './Options';

const Root = styled('div')({});

const goals = [
  'Prepare My Mind / Body for Birth',
  'Practice Coping Skills for Labor',
  'Feel Confident / Less Anxiety',
  'Help Keep My Baby and Me Safe',
  'Get Information I Can Trust'
];

/**
 * A stage in the Onboarding flow.
 */
export const GoalsStage: PageLevelComponent = () => {
  const { setStage, setNextAction } = useOnboardingContext();
  const [selectedGoals, setSelectedGoals] = useState<string[]>([]);
  useEffect(() => {
    setNextAction(
      selectedGoals.length === 0
        ? undefined
        : () => {
            setStage('assurance');
          }
    );
  }, [selectedGoals]);
  return (
    <Root>
      <H7>I want to:</H7>
      <Options
        options={goals.map((goal) => ({ id: goal, name: goal }))}
        selected={selectedGoals}
        onSelect={(goal) => setSelectedGoals([...selectedGoals, goal])}
        onDeselect={(goal) =>
          setSelectedGoals(selectedGoals.filter((g) => g !== goal))
        }
      />
    </Root>
  );
};
