import { ChevronRight } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import React from 'react';

import Divider from '../../general/Divider';
import { Concrete, Placeholder, Questions } from '../../StyleGuide/Colors';

const sharedStyles: React.CSSProperties = {
  height: 42,
  display: 'flex',
  alignItems: 'center',
  padding: '0 20px',
  justifyContent: 'space-between',
  background: 'transparent',
  fontFamily: 'Open Sans',
  fontSize: 16,
  border: 'none'
};

const ItemButton = styled('button')({
  ...sharedStyles,
  width: '100%'
});

const ItemDiv = styled('div')({
  ...sharedStyles
});

const Header = styled('div')({
  background: Concrete,
  color: Questions,
  fontSize: 14,
  fontWeight: 'bold',
  padding: '4px 20px'
});

const StyledDivider = styled(Divider)({
  width: 'calc(100% - 20px) !important',
  margin: '0 0 0 auto',
  padding: 0
});

const Chevron = styled(ChevronRight)({
  color: Placeholder
});

interface SectionItem {
  label: string;
  onClick?: () => void;
}

/**
 * Displays a section of the profile page, including a header and items within the section.
 */
export const ProfileSection: React.FC<{
  header: string;
  items: SectionItem[];
}> = ({ header, items }) => {
  return (
    <div>
      <Header>{header}</Header>
      {items.map((item, i) => {
        return (
          <React.Fragment key={`fragment-${item.label}`}>
            {i > 0 && <StyledDivider key={`hr-${item.label}`} />}
            {item.onClick ? (
              <ItemButton key={item.label} onClick={item.onClick}>
                <span>{item.label}</span>
                <Chevron />
              </ItemButton>
            ) : (
              <ItemDiv key={item.label}>
                <span>{item.label}</span>
              </ItemDiv>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
