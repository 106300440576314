import React, { useEffect, useState } from 'react';

import { UserService } from '../../general/ServerClient';
import { FirstNameInput, LastNameInput } from '../../StyleGuide/Input';
import { useAppContext } from '../page/WebappContext';
import {
  UpdateProfileFooterButtons,
  UpdateProfileInformationDialog
} from './UpdateProfileInformationDialog';

export const UpdateNamesDialog: React.FC<{
  isOpen: boolean;
  dismiss: () => void;
}> = ({ isOpen, dismiss }) => {
  const { userDetails } = useAppContext();

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  useEffect(() => {
    if (isOpen) {
      setFirstName(userDetails.data?.first_name || '');
      setLastName(userDetails.data?.last_name || '');
    }
  }, [isOpen]);
  return (
    <UpdateProfileInformationDialog open={isOpen} onClose={dismiss}>
      <FirstNameInput
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        placeholder="First Name"
      />
      <LastNameInput
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        placeholder="Last Name"
      />
      <UpdateProfileFooterButtons
        onCancel={dismiss}
        onSubmit={() => {
          if (userDetails.data) {
            userDetails.setData({
              ...userDetails.data,
              first_name: firstName,
              last_name: lastName
            });
            UserService.userDetailsCreate({
              send_notifications:
                userDetails.data.attributes.send_notifications || false,
              first_name: firstName,
              last_name: lastName
            });
          }
        }}
      />
    </UpdateProfileInformationDialog>
  );
};
