import classNames from 'classnames';
import React from 'react';

import Divider from '../general/Divider';
import { Sku } from '../general/ServerClient';
import {
  fmap,
  maybe,
  moneyFormatDollars,
  parseStringAsFloat
} from '../general/util';
import checkmark from '../img/checkmark.png';
import { Button } from '../StyleGuide/Button';
import { H5, H9, Span } from '../StyleGuide/Text';
import { useMobileContext } from './MobileContext';
import styles from './Plan.module.css';

const Plan: React.FC<
  Sku & {
    destination: () => void;
    calloutPlanName: string;
    isCurrentPlan: boolean;
  }
> = ({
  name,
  display_name: displayName,
  description,
  price: priceRaw,
  discount_text,
  full_price,
  destination,
  calloutPlanName,
  isCurrentPlan
}) => {
  const { isMobile } = useMobileContext();
  // TODO samgqroberts 2020-02-23 consolidate this tooling with CheckoutForm
  const price = fmap(priceRaw, parseStringAsFloat);
  const fullPrice = fmap(full_price, parseStringAsFloat);
  const descLines = description.split('\n');
  const isCalloutPlan = name === calloutPlanName;
  const bannerText = isCurrentPlan ? 'Current Plan' : discount_text;
  return (
    <div
      className={classNames(styles.plan, {
        [styles.callout]: isCalloutPlan,
        [styles.mobile]: isMobile
      })}
    >
      <div className={styles.above}>
        <div
          className={classNames(styles.nameAndDiscount, {
            [styles.discountPresent]: !!bannerText
          })}
        >
          {bannerText && (
            <div className={styles.discountMsg}>
              <H9>{bannerText}</H9>
            </div>
          )}
          <H5 className={styles.name}>{displayName}</H5>
        </div>
        <div className={styles.price}>
          <span className={styles.priceSpan}>
            <span className={styles.priceValue}>
              {maybe(
                price,
                (price) => (price === 0 ? 'Free' : moneyFormatDollars(price)),
                ''
              )}
            </span>
            {fullPrice && price !== fullPrice && (
              <span className={styles.fullPrice}>
                {moneyFormatDollars(fullPrice)}
              </span>
            )}
          </span>
        </div>
      </div>
      <Divider width={80} />
      <ul className={styles.descList}>
        {descLines.map((descLine) => (
          <li key={descLine}>
            <img alt="Checkmark" src={checkmark} />
            <Span>{descLine}</Span>
          </li>
        ))}
      </ul>
      <div className={styles.buyContainer}>
        {isCurrentPlan ? (
          <Button
            className={classNames(styles.buy, styles.disabled)}
            onClick={destination}
            aria-label={`This is your current plan.`}
            disabled
          >
            Your Current Plan
          </Button>
        ) : (
          <Button
            className={styles.buy}
            onClick={destination}
            aria-label={`Sign Up for ${name}`}
          >
            Sign Up
          </Button>
        )}
      </div>
    </div>
  );
};

export default Plan;
