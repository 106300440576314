import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import { H7 } from '../../StyleGuide/Text';
import { useOnboardingContext } from './OnboardingContext';
import { Progress } from './Progress';

const PREFIX = 'DueDate';

const classes = {
  duedate: `${PREFIX}-duedate`,
  setup: `${PREFIX}-setup`,
  progress: `${PREFIX}-progress`,
  prompt: `${PREFIX}-prompt`
};

const Root = styled('div')({
  [`&.${classes.duedate}`]: { display: 'flex', flexDirection: 'column' },
  [`& .${classes.setup}`]: {
    marginTop: 25
  },
  [`& .${classes.progress}`]: {
    marginTop: 25
  },
  [`& .${classes.prompt}`]: {}
});

/**
 * A stage in the Onboarding flow.
 */
export const DueDateStage: React.FC = () => {
  const {
    setStage,
    setNextText,
    setNextAction,
    setDueDate: setContextDueDate
  } = useOnboardingContext();
  const today = new Date(Date.now()).toISOString().split('T')[0];
  const [dueDate, setDueDate] = useState<string>(today);
  useEffect(() => setNextText('Next'), []);
  useEffect(
    () =>
      setNextAction(() => {
        setContextDueDate(dueDate);
        setStage('birthtype');
      }),
    [dueDate]
  );
  return (
    <Root className={classes.duedate}>
      <span className={classes.setup}>Let&apos;s get you setup.</span>
      <Progress progress={25} className={classes.progress} />
      <H7>What&apos;s your due date?</H7>
      {/* TODO samgqroberts 2021-12-10 plug in a fancier date picker, like mui's perhaps */}
      <input
        type="date"
        data-testid="due-date-input"
        value={dueDate}
        onChange={(e) => setDueDate(e.target.value)}
        min={today}
      />
    </Root>
  );
};
