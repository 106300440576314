/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InterstitialStatus } from '../models/InterstitialStatus';
import type { UserInterstitialStatus } from '../models/UserInterstitialStatus';
import { request as __request } from '../core/request';

export class InterstitialsService {

    /**
     * Get status, mark or unmark an interstitial as viewed by the current user
     * @param id
     * @returns any No response body
     * @throws ApiError
     */
    public static async interstitialsViewedDestroy(
        id: number,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/v1/interstitials/${id}/viewed`,
        });
        return result.body;
    }

    /**
     * Get status, mark or unmark an interstitial as viewed by the current user
     * @param id
     * @returns InterstitialStatus
     * @throws ApiError
     */
    public static async interstitialsViewedRetrieve(
        id: number,
    ): Promise<InterstitialStatus> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/interstitials/${id}/viewed`,
        });
        return result.body;
    }

    /**
     * Get status, mark or unmark an interstitial as viewed by the current user
     * @param id
     * @param requestBody
     * @returns UserInterstitialStatus
     * @throws ApiError
     */
    public static async interstitialsViewedCreate(
        id: number,
        requestBody?: UserInterstitialStatus,
    ): Promise<UserInterstitialStatus> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/interstitials/${id}/viewed`,
            body: requestBody,
        });
        return result.body;
    }

}