/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Image } from '../models/Image';
import type { PaginatedImageList } from '../models/PaginatedImageList';
import type { UserWatchedVideo } from '../models/UserWatchedVideo';
import type { Video } from '../models/Video';
import type { VideoStatus } from '../models/VideoStatus';
import type { VimeoVideoInformation } from '../models/VimeoVideoInformation';
import { request as __request } from '../core/request';

export class MediaService {

    /**
     * @param page A page number within the paginated result set.
     * @returns PaginatedImageList
     * @throws ApiError
     */
    public static async mediaImagesList(
        page?: number,
    ): Promise<PaginatedImageList> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/media/images`,
            query: {
                'page': page,
            },
        });
        return result.body;
    }

    /**
     * @param id
     * @returns Image
     * @throws ApiError
     */
    public static async mediaImagesRetrieve(
        id: number,
    ): Promise<Image> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/media/images/${id}`,
        });
        return result.body;
    }

    /**
     * @param id
     * @returns Video
     * @throws ApiError
     */
    public static async mediaVideoRetrieve(
        id: number,
    ): Promise<Video> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/media/video/${id}`,
        });
        return result.body;
    }

    /**
     * Get status, mark or unmark a video as watched by the current user
     * @param id
     * @returns any No response body
     * @throws ApiError
     */
    public static async mediaVideoWatchedDestroy(
        id: number,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/v1/media/video/${id}/watched`,
        });
        return result.body;
    }

    /**
     * Get status, mark or unmark a video as watched by the current user
     * @param id
     * @returns VideoStatus
     * @throws ApiError
     */
    public static async mediaVideoWatchedRetrieve(
        id: number,
    ): Promise<VideoStatus> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/media/video/${id}/watched`,
        });
        return result.body;
    }

    /**
     * Get status, mark or unmark a video as watched by the current user
     * @param id
     * @param requestBody
     * @returns UserWatchedVideo
     * @throws ApiError
     */
    public static async mediaVideoWatchedCreate(
        id: number,
        requestBody?: UserWatchedVideo,
    ): Promise<UserWatchedVideo> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/media/video/${id}/watched`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param videoId
     * @returns VimeoVideoInformation
     * @throws ApiError
     */
    public static async mediaVimeoVideoRetrieve(
        videoId: number,
    ): Promise<VimeoVideoInformation> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/media/vimeo/video/${videoId}`,
        });
        return result.body;
    }

}