import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useState } from 'react';
import { Route, useHistory } from 'react-router-dom';

import LoaderOverlay from '../../general/LoaderOverlay';
import { mailto, PageLevelComponent } from '../../general/util';
import { notMobileMediaQuery } from '../../StyleGuide/Breakpoints';
import { PageLayout } from '../page/PageLayout';
import { RouteSwitcher } from '../page/RouteSwitcher';
import { useAppContext } from '../page/WebappContext';
import { WebappUrls } from '../page/WebappUrls';
import { ProfileSection } from './ProfileSection';
import { SignOutDialog } from './SignOutDialog';
import { UpdateBirthLocationDialog } from './UpdateBirthLocationDialog';
import { UpdateBirthTypeDialog } from './UpdateBirthTypeDialog';
import { UpdateDueDateDialog } from './UpdateDueDateDialog';
import { UpdateNamesDialog } from './UpdateNamesDialog';
import { UpdateProviderDialog } from './UpdateProviderDialog';
import {
  WebappCheckoutPage,
  WebappMembershipPlansPage,
  WebappUpgradeSuccessPage
} from './Upgrade';

const SectionList = styled('div')({
  [notMobileMediaQuery]: {
    marginTop: 10
  }
});

/**
 * Top level navigational tab content.
 * Displays information and interactions regarding the user and their profile.
 */
export const ProfilePage: PageLevelComponent = () => {
  const {
    userDetails: { data: userDetails },
    birthPlan,
    options
  } = useAppContext();

  const history = useHistory();
  const [updatingName, setUpdatingName] = useState<boolean>(false);
  const [signingOut, setSigningOut] = useState<boolean>(false);
  const [updatingDueDate, setUpdatingDueDate] = useState<boolean>(false);
  const [updatingBirthType, setUpdatingBirthType] = useState<boolean>(false);
  const [updatingProvider, setUpdatingProvider] = useState<boolean>(false);
  const [updatingBirthLocation, setUpdatingBirthLocation] =
    useState<boolean>(false);
  let dueDate = '';
  if (birthPlan.data) {
    const dateString = birthPlan.data?.birth_date;
    const date = new Date(Date.parse(dateString));
    const mom = moment(date);
    dueDate = mom.utc().format('LL');
  }
  let birthType = '';
  if (birthPlan.data && options.data) {
    const typeId = birthPlan.data.birth_type;
    const option = options.data.birth_types.find((o) => o.id === typeId);
    if (option) {
      birthType = option.name;
    }
  }
  let provider = '';
  if (birthPlan.data && options.data) {
    const providerId = birthPlan.data.birth_provider;
    const option = options.data.providers.find((p) => p.id === providerId);
    if (option) {
      provider = option.name;
    }
  }
  let birthLocation = '';
  if (birthPlan.data && options.data) {
    const providerId = birthPlan.data.birth_location;
    const option = options.data.locations.find((p) => p.id === providerId);
    if (option) {
      birthLocation = option.name;
    }
  }
  return (
    <PageLayout>
      {!userDetails && <LoaderOverlay />}
      {userDetails && (
        <RouteSwitcher>
          <Route exact path={WebappUrls.profile}>
            <React.Fragment>
              <SectionList>
                <ProfileSection
                  header="MY ACCOUNT"
                  items={[
                    { label: `Current Plan: ${userDetails.plan.display_name}` },
                    {
                      label: 'Upgrade Your Plan',
                      onClick: () => {
                        history.push(WebappUrls.upgrade);
                      }
                    },
                    {
                      label: `Name: ${userDetails.first_name || ''} ${
                        userDetails.last_name || ''
                      }`,
                      onClick: () => setUpdatingName(true)
                    },
                    { label: `Email Address: ${userDetails.email || ''}` },
                    { label: 'Sign Out', onClick: () => setSigningOut(true) }
                  ]}
                />
                <ProfileSection
                  header="MY PREGNANCY"
                  items={[
                    {
                      label: `Due Date: ${dueDate}`,
                      onClick: () => setUpdatingDueDate(true)
                    },
                    {
                      label: `Birth Type: ${birthType}`,
                      onClick: () => setUpdatingBirthType(true)
                    },
                    {
                      label: `Provider: ${provider}`,
                      onClick: () => setUpdatingProvider(true)
                    },
                    {
                      label: `Birth Location: ${birthLocation}`,
                      onClick: () => setUpdatingBirthLocation(true)
                    }
                  ]}
                />
                <ProfileSection
                  header="CONTACT US"
                  items={[
                    {
                      label: 'Support',
                      onClick: () => {
                        const address = 'support@soshe-app.com';
                        const subject = 'Support request from SoShe user';
                        mailto({ address, subject });
                      }
                    },
                    {
                      label: 'Feedback',
                      onClick: () => {
                        const address = 'feedback@soshe-app.com';
                        const subject = 'Feedback from SoShe user';
                        mailto({ address, subject });
                      }
                    },
                    {
                      label: 'Say Hello',
                      onClick: () => {
                        const address = 'hello@soshe-app.com';
                        const subject = 'Hello from SoShe user';
                        mailto({ address, subject });
                      }
                    }
                  ]}
                />
              </SectionList>
              <UpdateNamesDialog
                isOpen={updatingName}
                dismiss={() => setUpdatingName(false)}
              />
              <SignOutDialog
                isOpen={signingOut}
                dismiss={() => setSigningOut(false)}
              />
              <UpdateDueDateDialog
                isOpen={updatingDueDate}
                dismiss={() => setUpdatingDueDate(false)}
              />
              <UpdateBirthTypeDialog
                isOpen={updatingBirthType}
                dismiss={() => setUpdatingBirthType(false)}
              />
              <UpdateProviderDialog
                isOpen={updatingProvider}
                dismiss={() => setUpdatingProvider(false)}
              />
              <UpdateBirthLocationDialog
                isOpen={updatingBirthLocation}
                dismiss={() => setUpdatingBirthLocation(false)}
              />
            </React.Fragment>
          </Route>
          <Route exact path={WebappUrls.upgrade}>
            <WebappMembershipPlansPage />
          </Route>
          <Route exact path={WebappUrls.checkout}>
            <WebappCheckoutPage />
          </Route>
          <Route exact path={WebappUrls.upgradeSuccess}>
            <WebappUpgradeSuccessPage />
          </Route>
        </RouteSwitcher>
      )}
    </PageLayout>
  );
};
