import React, { forwardRef, SVGProps } from 'react';

/**
 * Google Material icon encoded as a react `<svg />`
 *
 * source: https://material.io/resources/icons/?icon=add&style=baseline
 */
const AddIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  function AddIcon(props, ref) {
    return (
      <svg
        {...{ ref }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
      </svg>
    );
  }
);
export default AddIcon;
