import { styled } from '@mui/material/styles';
import classNames from 'classnames';

import logo from '../img/desktop-logo.png';

const Root = styled('a')({
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ['& img']: {
    height: 30
  }
});

export const LogoLink: React.FC<{
  linkClassName?: string;
  imgClassName?: string;
}> = ({ linkClassName, imgClassName }) => {
  return (
    <Root href={'https://www.soshe.com'} className={classNames(linkClassName)}>
      <img src={logo} alt="SoShe" className={classNames(imgClassName)} />
    </Root>
  );
};
