/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FreeSkuClaim } from '../models/FreeSkuClaim';
import type { FreeSkuClaimResponse } from '../models/FreeSkuClaimResponse';
import type { PaginatedUserPaymentList } from '../models/PaginatedUserPaymentList';
import type { PriceCheck } from '../models/PriceCheck';
import type { PriceCheckResponse } from '../models/PriceCheckResponse';
import type { PurchaseIntent } from '../models/PurchaseIntent';
import type { PurchaseIntentResponse } from '../models/PurchaseIntentResponse';
import { request as __request } from '../core/request';

export class PaymentsService {

    /**
     * Check the price of a sku/coupon combination
     * @param requestBody
     * @returns PriceCheckResponse
     * @throws ApiError
     */
    public static async paymentsCheckPriceCreate(
        requestBody: PriceCheck,
    ): Promise<PriceCheckResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/payments/check-price`,
            body: requestBody,
            errors: {
                400: `No response body`,
                404: `No response body`,
            },
        });
        return result.body;
    }

    /**
     * Claim a free SKU for the given user.
     * A SKU may be made free by a supplied coupon dropping its price to $0.
     * If no coupon is supplied, only SKUs marked free can be claimed, and this action
     * is only valid for users on a default plan.
     * @param requestBody
     * @returns FreeSkuClaimResponse
     * @throws ApiError
     */
    public static async paymentsClaimCreate(
        requestBody: FreeSkuClaim,
    ): Promise<FreeSkuClaimResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/payments/claim`,
            body: requestBody,
            errors: {
                400: `No response body`,
                404: `No response body`,
                500: `No response body`,
            },
        });
        return result.body;
    }

    /**
     * Create a Stripe payment intent for the provided Sku and optional coupon code.
     * Will fail with 400 if inapplicable coupon code is provided.
     * Will fail with 400 if specified product is a free product.
     * @param requestBody
     * @returns PurchaseIntentResponse
     * @throws ApiError
     */
    public static async paymentsIntentCreate(
        requestBody: PurchaseIntent,
    ): Promise<PurchaseIntentResponse> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/payments/intent`,
            body: requestBody,
            errors: {
                400: `No response body`,
                404: `No response body`,
                500: `No response body`,
            },
        });
        return result.body;
    }

    /**
     * @param page A page number within the paginated result set.
     * @returns PaginatedUserPaymentList
     * @throws ApiError
     */
    public static async paymentsUserPaymentsList(
        page?: number,
    ): Promise<PaginatedUserPaymentList> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/payments/user-payments`,
            query: {
                'page': page,
            },
        });
        return result.body;
    }

    /**
     * Used by Stripe to notify us of asynchonous events, not for direct use by apps!
     * @returns any No response body
     * @throws ApiError
     */
    public static async paymentsWebhookCreate(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/payments/webhook`,
        });
        return result.body;
    }

}