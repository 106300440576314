import { createContext, useContext, useState } from 'react';

import {
  BirthPlan,
  BirthPlanOptions,
  Course,
  FullSection,
  PaginatedCourseList,
  User,
  UserAnswer,
  UserProgress
} from '../../general/ServerClient';
import { initRemoteData } from '../util/RemoteData';

const userDetailsRD = initRemoteData<User>();
const birthPlanRD = initRemoteData<BirthPlan | undefined>();
const birthPrepCourseProgressRD = initRemoteData<UserProgress>();
const birthPlanCourseProgressRD = initRemoteData<UserProgress>();
const optionsRD = initRemoteData<BirthPlanOptions>();
const coursesRD = initRemoteData<PaginatedCourseList>();
const fullSectionRD = initRemoteData<FullSection, { sectionId: number }>();
const sectionAnswersRD = initRemoteData<UserAnswer[], { sectionId: number }>();

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
interface UpgradeInformation {
  productName: string | null;
  couponUsed: string | null;
  pricePaid: number | null;
}

interface LocalState<Data> {
  data: Data;
  setData: (data: Data) => void;
}

export function initLocalState<Data>(emptyState: Data): {
  dummyState: LocalState<Data>;
  useLocalState: () => LocalState<Data>;
} {
  const dummyState: LocalState<Data> = {
    data: emptyState,
    setData: noop
  };
  const useLocalState = () => {
    const [data, setData] = useState<Data>(emptyState);
    return {
      data,
      setData
    };
  };
  return {
    dummyState,
    useLocalState
  };
}

export const upgradeLS = initLocalState<UpgradeInformation>({
  productName: null,
  couponUsed: null,
  pricePaid: null
});

export interface IWebappState {
  userDetails: typeof userDetailsRD.dummyState;
  birthPlan: typeof birthPlanRD.dummyState;
  options: typeof optionsRD.dummyState;
  courses: typeof coursesRD.dummyState;
  birthPrepCourseProgress: typeof birthPrepCourseProgressRD.dummyState;
  birthPlanCourseProgress: typeof birthPlanCourseProgressRD.dummyState;
  fullSection: typeof fullSectionRD.dummyState;
  sectionAnswers: typeof sectionAnswersRD.dummyState;
  upgrade: typeof upgradeLS.dummyState;
}

const initialWebappState: IWebappState = {
  userDetails: userDetailsRD.dummyState,
  birthPlan: birthPlanRD.dummyState,
  options: optionsRD.dummyState,
  courses: coursesRD.dummyState,
  birthPrepCourseProgress: birthPrepCourseProgressRD.dummyState,
  birthPlanCourseProgress: birthPlanCourseProgressRD.dummyState,
  fullSection: fullSectionRD.dummyState,
  sectionAnswers: sectionAnswersRD.dummyState,
  upgrade: upgradeLS.dummyState
};

export const WebappContext = createContext<IWebappState>(initialWebappState);

export const WebappProvider: React.FC = ({ children }) => {
  const userDetails = userDetailsRD.useRemoteData();
  const birthPlan = birthPlanRD.useRemoteData();
  const options = optionsRD.useRemoteData();
  const courses = coursesRD.useRemoteData();
  const birthPrepCourseProgress = birthPrepCourseProgressRD.useRemoteData();
  const birthPlanCourseProgress = birthPlanCourseProgressRD.useRemoteData();
  const fullSection = fullSectionRD.useRemoteData();
  const sectionAnswers = sectionAnswersRD.useRemoteData();
  const upgrade = upgradeLS.useLocalState();

  return (
    <WebappContext.Provider
      value={{
        userDetails,
        birthPlan,
        options,
        courses,
        birthPrepCourseProgress,
        birthPlanCourseProgress,
        fullSection,
        sectionAnswers,
        upgrade
      }}
    >
      {children}
    </WebappContext.Provider>
  );
};

export const useAppContext = (): IWebappState => useContext(WebappContext);

export const BIRTH_PREP_COURSE_ID = 1;

type Selector<T> = (state: IWebappState) => T;

const selectBirthPrepCourse: Selector<Course | undefined> = (state) => {
  const { courses } = state;
  return courses.data?.results?.find((c) => c.id === BIRTH_PREP_COURSE_ID);
};

export const useBirthPrepCourse = (): Course | undefined => {
  return selectBirthPrepCourse(useAppContext());
};

export const BIRTH_PLAN_COURSE_ID = 2;

export const useBirthPlanCourse = (): Course | undefined => {
  const { courses } = useAppContext();
  return courses.data?.results?.find((c) => c.id === BIRTH_PLAN_COURSE_ID);
};

export const getUserHasAccessToBirthClass: Selector<boolean | undefined> = (
  state
) => {
  const birthPrepCourse = selectBirthPrepCourse(state);
  const userDetails = state.userDetails.data;
  if (!userDetails || !birthPrepCourse) return undefined;
  return userDetails.courses.some((c) => c.id === birthPrepCourse.id);
};

export const useUserHasAccessToBirthClass = (): boolean | undefined => {
  return getUserHasAccessToBirthClass(useAppContext());
};
