import isNumber from 'predicates/number';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import LoaderOverlay from '../../general/LoaderOverlay';
import {
  CoursesService,
  FullSection,
  MediaService,
  UserAnswer,
  UserProgress
} from '../../general/ServerClient';
import { PageLevelComponent, parseStringAsInt } from '../../general/util';
import NotFoundPage from '../../NotFoundPage';
import { useGetRetryFetch } from '../page/Auth';
import { useAppContext, useBirthPrepCourse } from '../page/WebappContext';
import { toLearnQuestion, WebappUrls } from '../page/WebappUrls';
import { SectionPage } from '../SectionPage/SectionPage';
import { load } from '../util/RemoteData';

/**
 * A page displaying a particular section in the Learn tab.
 */
export const LearnSectionPage: PageLevelComponent = () => {
  const { sectionId } = useParams<{ sectionId: string | undefined }>();
  const id = sectionId && parseStringAsInt(sectionId);
  const { courses, fullSection, sectionAnswers, birthPrepCourseProgress } =
    useAppContext();
  const retryFetchFullSection = useGetRetryFetch<FullSection>();
  const retryFetchSectionAnswers = useGetRetryFetch<UserAnswer[]>();
  const retryFetchUserProgress = useGetRetryFetch<UserProgress>();
  const birthPrepCourse = useBirthPrepCourse();
  useEffect(() => {
    if (isNumber(id)) {
      load(
        fullSection,
        () =>
          retryFetchFullSection(() =>
            CoursesService.coursesSectionsRetrieve(id)
          ),
        { sectionId: id }
      );
    }
  }, [id]);
  useEffect(() => {
    if (isNumber(id)) {
      load(
        sectionAnswers,
        () =>
          retryFetchSectionAnswers(() =>
            CoursesService.coursesSectionsUserAnswersList(id)
          ),
        { sectionId: id }
      );
    }
  }, [id]);
  const progress = isNumber(id)
    ? birthPrepCourseProgress.data?.sections[id]
    : undefined;
  if (fullSection.error || sectionAnswers.error) {
    if (fullSection.error instanceof Error) {
      if (fullSection.error.message.includes('Not Found')) {
        return <NotFoundPage />;
      }
    }
    console.error('Found error', fullSection.error);
    return <div>An unexpected error occurred while fetching this section.</div>;
  }
  return (
    <>
      {fullSection.data && sectionAnswers.data && progress && (
        <SectionPage
          {...{
            section: fullSection.data,
            userAnswers: sectionAnswers.data,
            progress,
            generateQuestionPath: toLearnQuestion,
            onUserWatchedVideo: (videoId) => {
              MediaService.mediaVideoWatchedCreate(videoId).then(() => {
                if (birthPrepCourse) {
                  load(birthPrepCourseProgress, () =>
                    retryFetchUserProgress(() =>
                      CoursesService.coursesUserProgressRetrieve(
                        birthPrepCourse.id
                      )
                    )
                  );
                }
              });
            }
          }}
          back={{ label: 'Back To All Sections', destination: WebappUrls.home }}
          isBirthClass={true}
        />
      )}
      {(fullSection.loading ||
        sectionAnswers.loading ||
        courses.loading ||
        birthPrepCourseProgress.loading) && <LoaderOverlay />}
    </>
  );
};
