import { Link, LinkProps } from 'react-router-dom';

import PageUrls, { PageUrlK } from './Routing';

interface InternalLinkI {
  to: PageUrlK;
}

const InternalLink: React.FC<Omit<LinkProps<unknown>, 'to'> & InternalLinkI> =
  ({ to, ...props }) => {
    const dest = PageUrls[to];
    return <Link {...props} to={dest}></Link>;
  };
export default InternalLink;
