import {
  AccountCircle,
  Description,
  LocalLibrary,
  Share
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Questions, Tundora, Wafer } from '../../StyleGuide/Colors';
import { WebappUrls } from './WebappUrls';

const PREFIX = 'NavItem';

const classes = {
  bottomnav: `${PREFIX}-bottomnav`,
  list: `${PREFIX}-list`,
  navitem: `${PREFIX}-navitem`,
  selected: `${PREFIX}-selected`,
  navitemlabel: `${PREFIX}-navitemlabel`
};

const Root = styled('nav')({
  [`&.${classes.bottomnav}`]: {
    display: 'flex',
    height: 50,
    flexShrink: 0,
    borderTop: `1px solid ${Wafer}`
  },
  [`& .${classes.list}`]: {
    listStyle: 'none',
    display: 'flex',
    padding: 0,
    margin: 0,
    height: '100%',
    width: '100%',
    '& li': {
      flex: 1
    }
  },
  [`& .${classes.navitem}`]: {
    width: '100%',
    height: '100%',
    background: 'transparent',
    border: 0,
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: Questions,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12
  },
  [`& .${classes.selected}`]: {
    color: Tundora
  },
  [`& .${classes.navitemlabel}`]: {}
});

export type Tab = 'learn' | 'birthplan' | 'share' | 'profile';

export const NavItem: React.FC<{
  to: string;
  name: string;
  icon: ReactNode;
  selected: boolean;
}> = ({ to, name, icon, selected }) => {
  return (
    <Link
      className={classNames(classes.navitem, { [classes.selected]: selected })}
      to={to}
    >
      {icon}
      <span className={classes.navitemlabel}>{name}</span>
    </Link>
  );
};

export const BottomNav: React.FC<{
  selected: Tab | null;
}> = ({ selected }) => {
  return (
    <Root className={classes.bottomnav}>
      <ul className={classes.list}>
        <li>
          <NavItem
            icon={<LocalLibrary />}
            to={WebappUrls.home}
            name="Learn"
            selected={selected === 'learn'}
          />
        </li>
        <li>
          <NavItem
            icon={<Description />}
            to={WebappUrls.birthPlan}
            name="Birth Plan"
            selected={selected === 'birthplan'}
          />
        </li>
        <li>
          <NavItem
            icon={<Share />}
            to={WebappUrls.share}
            name="Share"
            selected={selected === 'share'}
          />
        </li>
        <li>
          <NavItem
            icon={<AccountCircle />}
            to={WebappUrls.profile}
            name="Profile"
            selected={selected === 'profile'}
          />
        </li>
      </ul>
    </Root>
  );
};
