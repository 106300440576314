/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedSkuList } from '../models/PaginatedSkuList';
import { request as __request } from '../core/request';

export class ProductsService {

    /**
     * @param page A page number within the paginated result set.
     * @returns PaginatedSkuList
     * @throws ApiError
     */
    public static async productsList(
        page?: number,
    ): Promise<PaginatedSkuList> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/products`,
            query: {
                'page': page,
            },
        });
        return result.body;
    }

}