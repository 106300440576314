/* Typescript bindings for color constants defined in css modules */

import constants from './StyleConstants.module.css';

export const BisonHide = constants['BisonHide'];
export const Bone = constants['Bone'];
export const Concrete = constants['Concrete'];
export const CopperRose = constants['CopperRose'];
export const CuttySark = constants['CuttySark'];
export const Gothic = constants['Gothic'];
export const OrientalPink = constants['OrientalPink'];
export const Placeholder = constants['Placeholder'];
export const Questions = constants['Questions'];
export const Schooner = constants['Schooner'];
export const Thatch = constants['Thatch'];
export const Tundora = constants['Tundora'];
export const Wafer = constants['Wafer'];
export const White = constants['White'];
export const William = constants['William'];

export const colors = {
  BisonHide,
  Bone,
  Concrete,
  CopperRose,
  CuttySark,
  Gothic,
  OrientalPink,
  Placeholder,
  Questions,
  Schooner,
  Thatch,
  Tundora,
  Wafer,
  White,
  William
};

export type ColorName = keyof typeof colors;
export const colorNames = Object.keys(colors) as ColorName[];
