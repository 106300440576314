import React, { useEffect } from 'react';

import LoaderOverlay from '../../general/LoaderOverlay';
import { User, UserService } from '../../general/ServerClient';
import { load } from '../util/RemoteData';
import { useGetRetryFetch } from './Auth';
import { AuthedWebapp } from './AuthedWebapp';
import { LoggedOut } from './LoggedOut';
import { useAppContext } from './WebappContext';

/**
 * Container component responsible for making the first authenticated fetch for user details.
 * Will handle if refresh token is no good for any reason.
 */
export const EnsureAuthed: React.FC = () => {
  const { userDetails } = useAppContext();
  const retryFetchUserDetails = useGetRetryFetch<User>();
  // trigger fetch to load user details
  useEffect(() => {
    load(userDetails, () =>
      retryFetchUserDetails(UserService.userDetailsRetrieve)
    );
  }, []);
  return !userDetails.loaded || userDetails.loading ? (
    <LoaderOverlay />
  ) : userDetails.error ? (
    <LoggedOut />
  ) : (
    <AuthedWebapp />
  );
};
