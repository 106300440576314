/**
 * TODO samgqroberts 2022-06-06 this entire concept should probably be replaced with react-query.
 */

import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export interface RemoteData<Data, Description = undefined> {
  data: Data | undefined;
  setData: (data: Data | undefined, description?: Description) => void;
  error: unknown | undefined;
  setError: (error: unknown | undefined) => void;
  // TODO samgqroberts 2021-12-10 this can/should be derived from more basic state, like some loadedDescription
  loaded: boolean;
  setLoaded: (loaded: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  description?: Description | undefined;
  setDescription?: (descrition: Description | undefined) => void;
}

export function load<T, D>(
  rd: RemoteData<T, D>,
  go: () => Promise<T>,
  description?: D
): void {
  rd.setLoading(true);
  go()
    .then((data) => {
      rd.setData(data);
    })
    .catch((e) => {
      rd.setError(e);
    })
    .finally(() => {
      if (description && rd.setDescription) {
        rd.setDescription(description);
      }
      rd.setLoading(false);
      rd.setLoaded(true);
    });
}

export function initRemoteData<Data, Description = undefined>(): {
  dummyState: RemoteData<Data, Description>;
  useRemoteData: () => RemoteData<Data, Description>;
} {
  const dummyState: RemoteData<Data, Description> = {
    data: undefined,
    setData: noop,
    loaded: false,
    setLoaded: noop,
    loading: false,
    setLoading: noop,
    error: undefined,
    setError: noop
  };
  const useRemoteData = () => {
    const [data, setData] = useState<Data | undefined>(undefined);
    const [error, setError] = useState<unknown | undefined>(undefined);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [description, setDescription] = useState<Description | undefined>(
      undefined
    );
    return {
      data,
      setData,
      error,
      setError,
      loaded,
      setLoaded,
      loading,
      setLoading,
      description,
      setDescription
    };
  };
  return {
    dummyState,
    useRemoteData
  };
}
