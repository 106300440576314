import { useState } from 'react';

import LoaderOverlay from '../general/LoaderOverlay';
import { AuthService } from '../general/ServerClient';
import { PageLevelComponent } from '../general/util';
import { Button } from '../StyleGuide/Button';
import { EmailInput } from '../StyleGuide/Input';
import { H7, P } from '../StyleGuide/Text';
import styles from './RequestPasswordResetPage.module.css';

const RequestPasswordResetPage: PageLevelComponent = () => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthService.authPasswordResetCreate({ email }).then(() => {
      setLoading(false);
      setSent(true);
    });
  };
  return (
    <div className={styles.page}>
      <form onSubmit={handleSubmit}>
        <H7>Reset your password</H7>
        <EmailInput value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button type="submit" disabled={loading || sent}>
          Request Reset
        </Button>
        {sent && <P>{'An email with reset instructions has been sent.'}</P>}
      </form>
      {loading && <LoaderOverlay />}
    </div>
  );
};

export default RequestPasswordResetPage;
