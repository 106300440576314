import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import React from 'react';

import { Button, ButtonLink } from '../../StyleGuide/Button';
import { H7, P } from '../../StyleGuide/Text';
import { logout } from '../page/LeftNav';
import { useAppContext } from '../page/WebappContext';

const FormDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& > *': {
    margin: 10
  }
});

export const SignOutDialog: React.FC<{
  isOpen: boolean;
  dismiss: () => void;
}> = ({ isOpen, dismiss }) => {
  const { userDetails } = useAppContext();

  return (
    <Dialog open={isOpen} onClose={dismiss}>
      <FormDiv>
        <H7>
          {userDetails.data?.first_name || ''}{' '}
          {userDetails.data?.last_name || ''}
        </H7>
        <P>Are you sure you want to sign out of SoShe?</P>
        <ButtonLink onClick={dismiss}>Cancel</ButtonLink>
        <Button onClick={logout}>Sign out</Button>
      </FormDiv>
    </Dialog>
  );
};
