import { useEffect } from 'react';
import TagManager, { DataLayerArgs } from 'react-gtm-module';

import { Sku } from '../general/ServerClient';
import { appEnv } from './AppEnv';

export const GTM_PLANS_CATEGORY = 'Membership Plans';

export function ifGTMEnabled(
  fn: (id: string) => void,
  otherwise?: () => void
): void {
  const id = appEnv('GTM_CONTAINER_ID');
  if (id) {
    fn(id);
  } else if (otherwise) {
    otherwise();
  }
}

export function initializeGTM(): void {
  ifGTMEnabled(
    (id) => TagManager.initialize({ gtmId: id }),
    () =>
      console.debug('GTM container id not specified, skipping initialization')
  );
}

export function dataLayerGTM(dataLayerArgs: DataLayerArgs['dataLayer']): void {
  ifGTMEnabled(() => TagManager.dataLayer({ dataLayer: dataLayerArgs }));
}

export function useDataLayerGTMOnMount(
  args: DataLayerArgs['dataLayer'],
  condition?: boolean
): void {
  useEffect(() => {
    if (condition === true || condition === undefined) {
      dataLayerGTM(args);
    }
  }, []);
}

export function getProductGTMData(product: Sku): {
  name: string;
  id: string;
  price: string;
  brand: null;
  category: typeof GTM_PLANS_CATEGORY;
  variant: 'Class';
  quantity: 1;
} {
  return {
    name: product.display_name,
    id: product.name,
    price: product.price,
    brand: null,
    category: GTM_PLANS_CATEGORY,
    variant: 'Class',
    quantity: 1
  };
}

export function addToCartGTM(product: Sku): void {
  dataLayerGTM({
    ecommerce: null // clear the previous ecommerce object
  });
  dataLayerGTM({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'USD',
      add: {
        products: [getProductGTMData(product)]
      }
    }
  });
}
