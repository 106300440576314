import classNames from 'classnames';
import React, { forwardRef, useState } from 'react';

import ExpandMoreIcon from '../Icons/ExpandMoreIcon';
import LockIcon from '../Icons/LockIcon';
import MailOutlineIcon from '../Icons/MailOutlineIcon';
import VisibilityIcon from '../Icons/VisibilityIcon';
import VisibilityOffIcon from '../Icons/VisibilityOffIcon';
import styles from './Input.module.css';

export const Input = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>(function Input(props, ref) {
  return (
    <input
      {...{ ref }}
      {...props}
      className={classNames(styles.input, props.className)}
    />
  );
});

export const FirstNameInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>(function FirstNameInput(props, ref) {
  return (
    <input
      placeholder="First name"
      {...{ ref }}
      {...props}
      className={classNames(styles.input, props.className)}
      type="text"
    />
  );
});

export const LastNameInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>(function LastNameInput(props, ref) {
  return (
    <input
      placeholder="Last name"
      {...{ ref }}
      {...props}
      className={classNames(styles.input, props.className)}
      type="text"
    />
  );
});

export const EmailInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>(function EmailInput(props, ref) {
  return (
    <input
      placeholder="Email address"
      {...{ ref }}
      {...props}
      className={classNames(styles.input, props.className)}
      type="email"
    />
  );
});

export const PasswordInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>(function PasswordInput(props, ref) {
  return (
    <input
      placeholder="Password"
      {...{ ref }}
      {...props}
      className={classNames(styles.input, props.className)}
      type="password"
    />
  );
});

export const IconEmailInput: React.FC<{
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  className?: string;
  autoComplete?: string;
}> = ({ value, setValue, placeholder, className, autoComplete }) => {
  return (
    <div className={classNames(styles.emailContainer, className)}>
      <MailOutlineIcon className={styles.emailIcon} />
      <EmailInput
        className={styles.iconEmailInput}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder || 'Email'}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export const VisibilityTogglePasswordInput: React.FC<{
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  className?: string;
}> = ({ value, setValue, placeholder, className }) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  return (
    <div className={classNames(styles.passwordContainer, className)}>
      <LockIcon className={styles.passwordIcon} />
      <Input
        type={passwordVisible ? 'text' : 'password'}
        className={styles.password}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder || 'Password'}
        autoComplete="new-password"
      />
      <button
        type="button"
        tabIndex={-1}
        className={styles.passwordVisibilityButton}
        onClick={(e) => {
          e.preventDefault();
          setPasswordVisible(!passwordVisible);
        }}
      >
        {passwordVisible ? (
          <VisibilityIcon className={styles.passwordVisibilityIcon} />
        ) : (
          <VisibilityOffIcon className={styles.passwordVisibilityIcon} />
        )}
      </button>
    </div>
  );
};

export const Select = forwardRef<
  HTMLSelectElement,
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
>(function Select(props, ref) {
  return (
    <div className={classNames(styles.selectContainer, props.className)}>
      <select {...{ ref }} {...props} className={styles.select} />
      <ExpandMoreIcon className={styles.selectIcon} />
    </div>
  );
});

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >
>(function TextArea(props, ref) {
  return (
    <textarea
      {...{ ref }}
      {...props}
      className={classNames(styles.textarea, props.className)}
    />
  );
});

export const Checkbox: React.FC<{
  className?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}> = ({ className, checked, onChange, children }) => {
  return (
    <label className={classNames(styles.checkboxContainer, className)}>
      <input
        type="checkbox"
        className={styles.checkbox}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <span className={styles.checkboxLabel}>{children}</span>
    </label>
  );
};
