import numeral from 'numeral';
import isNumber from 'predicates/number';
import { ReactElement } from 'react';

export function parseStringAsFloat(data: string): number | undefined {
  const parsed = parseFloat(data);
  return isNumber(parsed) && !isNaN(parsed) ? parsed : undefined;
}

export function parseStringAsInt(data: string): number | undefined {
  const parsed = parseInt(data, 10);
  return isNumber(parsed) && !isNaN(parsed) ? parsed : undefined;
}

export function fmap<T, U>(
  data: T | null | undefined,
  f: (d: T) => U
): U | undefined {
  if (data === null || data === undefined) return undefined;
  return f(data);
}

export function maybe<T, U>(
  data: T | null | undefined,
  f: (d: T) => U,
  defaultValue: U
): U {
  if (data === null || data === undefined) return defaultValue;
  return f(data);
}

export function moneyFormatDollars(data: number): string {
  return numeral(data).format('$0,0');
}

export function moneyFormatCents(data: number): string {
  return numeral(data).format('$0,0.00');
}

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
export function userIsOniOSDevice(): boolean {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

// a functional component that accepts no props (even children)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PageLevelComponent = () => ReactElement<any, any> | null;

/**
 * Navigate to the given app path, using the browser's navigation mechanism. instead of
 *   react-router.
 * This loads the path like a new page.
 */
export function browserNavigate(path: string): void {
  window.location.href =
    window.location.protocol + '//' + window.location.host + path;
}

interface MailtoParams {
  address?: string;
  subject?: string;
  body?: string;
}
export function getMailtoUrl({ address, subject, body }: MailtoParams): string {
  return `mailto:${address || ''}?${
    subject ? `&subject=${encodeURIComponent(subject)}` : ''
  }${body ? `&body=${encodeURIComponent(body)}` : ''}`;
}
export function mailto(params: MailtoParams): void {
  window.location.href = getMailtoUrl(params);
}

export function isDefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export function getNowUTCISOString(): string {
  return new Date(Date.now()).toISOString();
}
