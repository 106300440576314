import { createContext, useContext, useEffect, useState } from 'react';

import {
  asResult,
  AuthService,
  ServerResult,
  setAuthHeader,
  TokenRefresh,
  unsetAuthHeader
} from '../../general/ServerClient';
import { useLocalStorage } from '../util/useLocalStorage';

interface IInMemoryAuthState {
  access: string | undefined;
  setAccess: (access: string | undefined) => void;
}

interface ILocalstorageAuthState {
  refresh: string | undefined;
  setRefresh: (refresh: string | undefined) => void;
}

const initialInMemoryAuthState: IInMemoryAuthState = {
  access: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAccess: () => {}
};

const initialLocalstorageAuthState: ILocalstorageAuthState = {
  refresh: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRefresh: () => {}
};

type IAuthState = IInMemoryAuthState & ILocalstorageAuthState;

const initialAuthState: IAuthState = {
  ...initialInMemoryAuthState,
  ...initialLocalstorageAuthState
};

export const AuthContext = createContext<IAuthState>(initialAuthState);

export const REFRESH_TOKEN_LOCALSTORAGE_KEY = 'REFRESH';

export function refreshTokenStored(): boolean {
  return !!localStorage.getItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);
}

export function storeRefreshToken(refresh: string): void {
  return localStorage.setItem(REFRESH_TOKEN_LOCALSTORAGE_KEY, refresh);
}

export const AuthProvider: React.FC = ({ children }) => {
  const [access, setAccessState] = useState<string | undefined>(undefined);
  // make sure we always store the refresh token value in localstorage
  const [refresh, setRefresh] = useLocalStorage(REFRESH_TOKEN_LOCALSTORAGE_KEY);
  const setAccess = (access: string | undefined) => {
    if (access) {
      setAuthHeader(access);
    } else {
      unsetAuthHeader();
    }
    setAccessState(access);
  };

  return (
    <AuthContext.Provider
      value={{
        access,
        setAccess,
        refresh,
        setRefresh
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): IAuthState => useContext(AuthContext);

/**
 * If neither access token nor refresh token are available, the user is known to be logged out.
 * It is assumed that the AuthContext was only mounted if at some point we detected the user was
 *   logged in (presumably using {@link refreshTokenStored}).
 */
export const useLoggedOut = (): boolean => {
  const { refresh } = useAuthContext();
  return !refresh;
};

export const eraseToken = (): void =>
  localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);

const refreshToken = (refresh: string): Promise<ServerResult<TokenRefresh>> => {
  return asResult(() =>
    AuthService.authTokenRefreshCreate({
      access:
        '' /* TODO samgqroberts 2021-04-21 this not required in this API call, but the generated client requires it */,
      refresh
    })
  );
};

/**
 * Uses the access and refresh JWT tokens in the AuthContext to attempt to perform the specified fetch.
 * On unauthorized failure, will silently attempt to refresh the access token and retry.
 * @param fn The fetch to perform
 * @param callback A callback on successful fetch, supplied with returned data
 * @param errorCallback A callback for when the fetch could not be successfully completed
 */
export function useRetryFetch<T>(
  fn: () => Promise<T>,
  callback: (data: T) => void,
  errorCallback?: () => void
): void {
  const { access, setAccess, refresh, setRefresh } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded && !loading) {
      if (!refresh) {
        // no refresh token, which is considered to be in a logged-out state
        // there is no valid state where access token exists but refresh doesn't
        setLoaded(true);
        if (errorCallback) errorCallback();
      } else if (!access) {
        // no access but we have refresh, try to get another access
        setLoading(true);
        refreshToken(refresh).then((result) => {
          if (result.type === 'success') {
            // fetch for access token via refresh token successful, record new access token
            setAccess(result.data.access);
          } else if (result.error.status === 401) {
            // fetch for access token rejected due to bad refresh token, unset refresh token
            setRefresh(undefined);
          } else {
            // access token fetch failed in an unexpected way
            setLoaded(true);
            console.error('Access token refresh failed:', result.error);
          }
          setLoading(false);
        });
      } else {
        // have access, do fetch
        setLoading(true);
        asResult(fn).then((result) => {
          if (result.type === 'success') {
            // data fetch successful, ensure we don't refetch and return data to caller
            setLoaded(true);
            callback(result.data);
          } else if (result.error.status === 401) {
            // data fetch unsuccessful because access token got rejected.
            // unset current access token. should trigger a retry of this logic.
            setAccess(undefined);
          } else {
            // data fetch with access token failed in an unexpected way
            setLoaded(true);
            console.error('Fetch to server failed:', result.error);
          }
          setLoading(false);
        });
      }
    }
  }, [access, refresh, loading]);
}

/**
 * Uses the access and refresh JWT tokens in the AuthContext to attempt to perform the specified fetch.
 * On unauthorized failure, will silently attempt to refresh the access token and retry.
 * @param fn The fetch to perform
 */
export function useGetRetryFetch<T>(): (fn: () => Promise<T>) => Promise<T> {
  const { access, setAccess, refresh, setRefresh } = useAuthContext();

  return (fn: () => Promise<T>): Promise<T> => {
    const refreshThenTry = (r: string): Promise<T> => {
      return refreshToken(r).then((result) => {
        if (result.type === 'success') {
          setAccess(result.data.access);
          return fn();
        } else if (result.error.status === 401) {
          setRefresh(undefined);
          return Promise.reject('Bad refresh token.');
        } else {
          return Promise.reject(
            `Access token reject failed in unexpected way. ${JSON.stringify(
              result.error
            )}`
          );
        }
      });
    };
    if (!refresh) {
      return Promise.reject('No refresh token.');
    }
    if (!access) {
      // no access token - attempt to refresh, then try actual fetch with new access token
      return refreshThenTry(refresh);
    } else {
      // have access token, do fetch
      return asResult(fn).then((result) => {
        if (result.type === 'success') {
          // data fetch successful, return data to caller
          return Promise.resolve(result.data);
        } else if (result.error.status === 401) {
          // data fetch unsuccessful because access token got rejected.
          // access token could have simply expird.
          // unset current access token, try to refresh it, then retry fetch.
          setAccess(undefined);
          return refreshThenTry(refresh);
        } else {
          // data fetch with access token failed in an unexpected way
          return Promise.reject(result.error);
        }
      });
    }
  };
}
