import { Cancel } from '@mui/icons-material';
import { Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import { Question } from '../../general/ServerClient';
import useIsMobile from '../../general/useIsMobile';
import { mobileMediaQuery } from '../../StyleGuide/Breakpoints';
import { White } from '../../StyleGuide/Colors';
import { H3 } from '../../StyleGuide/Text';
import { CONTENT_CONTAINER_ELEMENT_ID } from '../page/constants';
import { InterstitialContent } from './InterstitialContent';

export const estimatedTime = (estimate: string): string => `(${estimate} read)`;

export const INTERSTITIAL_SUMMARY_TEST_ID = 'interstitial-summary';

const Title = styled(DialogTitle)({
  padding: 0,
  lineHeight: 0,
  position: 'relative',
  '& img': {
    width: '100%'
  }
});
const Content = styled(DialogContent)({
  whiteSpace: 'pre-line',
  marginTop: 26,
  padding: `20px 15px`,
  [mobileMediaQuery]: {
    fontSize: 14
  }
});
const StyledPaper = styled(Paper)({
  borderRadius: 0,
  [mobileMediaQuery]: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'scroll'
  }
});
const CloseIcon = styled(Cancel)({
  position: 'absolute',
  top: 15,
  right: 15,
  cursor: 'pointer',
  opacity: 0.5,
  fontSize: 28
});
const Gradient = styled('div')({
  position: 'absolute',
  top: '40%',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundImage: 'linear-gradient(transparent, black)',
  opacity: 0.7
});
const Metadata = styled('div')({
  lineHeight: 'initial',
  position: 'absolute',
  bottom: 15,
  left: 15,
  display: 'flex',
  flexDirection: 'column',
  color: White
});
const Header = styled(H3)({
  margin: '10px 0 5px',
  [mobileMediaQuery]: {
    fontSize: 28,
    lineHeight: '34px'
  }
});
const Estimate = styled('span')({
  [mobileMediaQuery]: {
    fontSize: 16
  }
});

/**
 * A dialog displaying an interstitial's contents.
 */
export const InterstitialDialog: React.FC<{
  question: Question;
  open: boolean;
  onClose: () => void;
}> = ({ question, open, onClose }) => {
  const interstitial = question.interstitials[0];
  const isMobile = useIsMobile();
  if (!interstitial) {
    return null;
  }
  const contents = (
    <>
      <Title>
        <img src={interstitial.image_2x} alt={interstitial.title} />
        <Gradient />
        <Metadata>
          <Header>{interstitial.title}</Header>
          {interstitial.estimated_time && (
            <Estimate>{estimatedTime(interstitial.estimated_time)}</Estimate>
          )}
        </Metadata>
        <CloseIcon onClick={onClose} />
      </Title>
      <Content>
        <InterstitialContent content={interstitial.content} {...{ onClose }} />
      </Content>
    </>
  );
  return isMobile ? (
    open ? (
      <StyledPaper>{contents}</StyledPaper>
    ) : null
  ) : (
    <Dialog
      open={open}
      {...{ onClose }}
      scroll="body"
      PaperComponent={StyledPaper}
      container={() =>
        document.body.querySelector(`#${CONTENT_CONTAINER_ELEMENT_ID}`)
      }
      sx={{
        '&.MuiDialog-root': {
          position: 'absolute'
        },
        '& .MuiBackdrop-root': {
          position: 'absolute'
        }
      }}
    >
      {contents}
    </Dialog>
  );
};
