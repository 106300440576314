import { styled } from '@mui/material/styles';

import { ContentBlock } from '../../general/ServerClient';
import { ContentBlockItem } from './ContentBlockItem';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});
const Row = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around'
});

/**
 * Displays a list of ContentBlock objects.
 */
export const ContentBlocks: React.FC<{
  sectionId: number;
  contentBlocks: ContentBlock[];
  lockedBehindPaywall: boolean;
}> = ({ sectionId, contentBlocks, lockedBehindPaywall }) => {
  const rowsOfTwo: { first: ContentBlock; second: ContentBlock }[] = [];
  let current: ContentBlock | undefined;
  contentBlocks.forEach((cb) => {
    if (current) {
      rowsOfTwo.push({ first: current, second: cb });
      current = undefined;
    } else {
      current = cb;
    }
  });
  return (
    <Root>
      {rowsOfTwo.map(({ first, second }) => {
        return (
          <Row key={first.database_name}>
            <ContentBlockItem
              contentBlock={first}
              {...{ sectionId, lockedBehindPaywall }}
            />
            <ContentBlockItem
              contentBlock={second}
              {...{ sectionId, lockedBehindPaywall }}
            />
          </Row>
        );
      })}
      {current && (
        <Row>
          <ContentBlockItem
            contentBlock={current}
            {...{ sectionId, lockedBehindPaywall }}
          />
          <ContentBlockItem
            contentBlock="justForSpacing"
            {...{ sectionId, lockedBehindPaywall }}
          />
        </Row>
      )}
    </Root>
  );
};
