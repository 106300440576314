import { CheckCircle, Help } from '@mui/icons-material';

import { CopperRose, Placeholder } from '../../StyleGuide/Colors';

/**
 * Helper component displaying the completion status for something.
 */
export const CompletedIcon: React.FC<{
  completed: boolean;
  incompleteIsQuestionMark?: boolean;
}> = ({ completed, incompleteIsQuestionMark }) => {
  const sx = { color: completed ? CopperRose : Placeholder };
  return incompleteIsQuestionMark && !completed ? (
    <Help {...{ sx }} />
  ) : (
    <CheckCircle {...{ sx }} />
  );
};
