import React, { useEffect } from 'react';

import LoaderOverlay from '../../general/LoaderOverlay';
import {
  BirthPlanOptions,
  BirthPlanService,
  CoursesService,
  PaginatedBirthPlanList,
  PaginatedCourseList,
  TypeEnum,
  UserProgress
} from '../../general/ServerClient';
import { Onboarding } from '../Onboarding/Onboarding';
import { load } from '../util/RemoteData';
import { useGetRetryFetch } from './Auth';
import { WebappContent } from './WebappContent';
import {
  useAppContext,
  useBirthPlanCourse,
  useBirthPrepCourse
} from './WebappContext';

/**
 * Container component responsible for loading top-level information from server.
 */
export const AuthedWebapp: React.FC = () => {
  const {
    userDetails,
    birthPlan,
    options,
    courses,
    birthPrepCourseProgress,
    birthPlanCourseProgress
  } = useAppContext();
  const retryFetchBirthPlanProfile = useGetRetryFetch<PaginatedBirthPlanList>();
  const retryFetchBirthPlanOptions = useGetRetryFetch<BirthPlanOptions>();
  const retryFetchCourseList = useGetRetryFetch<PaginatedCourseList>();
  const retryFetchUserProgress = useGetRetryFetch<UserProgress>();
  const birthPrepCourse = useBirthPrepCourse();
  const birthPlanCourse = useBirthPlanCourse();
  // once user details are loaded, fetch birth plan and birth plan options.
  // this is second round of fetches, because we need to know whether to divert user to
  // onboarding flow.
  useEffect(() => {
    if (userDetails.data) {
      load(birthPlan, () =>
        retryFetchBirthPlanProfile(
          BirthPlanService.birthPlanProfileLatestList
        ).then((data) => {
          const planned = data.results?.find((b) => b.type === TypeEnum.P);
          return planned;
        })
      );
      load(options, () =>
        retryFetchBirthPlanOptions(
          BirthPlanService.birthPlanProfileOptionsRetrieve
        )
      );
    }
  }, [userDetails.data]);
  // if birth plan has already been filled out, fetch the course list
  useEffect(() => {
    if (birthPlan.data) {
      load(courses, () => retryFetchCourseList(CoursesService.coursesList));
    }
  }, [birthPlan.data]);
  // once the courses have been fetched and one of them has been identified as the birth prep course,
  // fetch details about that course. same below with birth plan course
  useEffect(() => {
    if (birthPrepCourse) {
      load(birthPrepCourseProgress, () =>
        retryFetchUserProgress(() =>
          CoursesService.coursesUserProgressRetrieve(birthPrepCourse.id)
        )
      );
    }
  }, [birthPrepCourse]);
  useEffect(() => {
    if (birthPlanCourse) {
      load(birthPlanCourseProgress, () =>
        retryFetchUserProgress(() =>
          CoursesService.coursesUserProgressRetrieve(birthPlanCourse.id)
        )
      );
    }
  }, [birthPlanCourse]);

  const loading = birthPlan.loading || options.loading || courses.loading;
  return !birthPlan.loaded || loading ? (
    <LoaderOverlay />
  ) : birthPlan.loaded && !birthPlan.data ? (
    <Onboarding />
  ) : (
    <WebappContent />
  );
};
