import React, { useEffect } from 'react';
import { generatePath } from 'react-router-dom';

import LoaderOverlay from '../../general/LoaderOverlay';
import { CoursesService, FullSection } from '../../general/ServerClient';
import { PageLevelComponent } from '../../general/util';
import NotFoundPage from '../../NotFoundPage';
import { PathParamsReader } from '../components/PathParamsReader';
import { useGetRetryFetch } from '../page/Auth';
import { PageLayout } from '../page/PageLayout';
import { useAppContext } from '../page/WebappContext';
import { WebappUrls } from '../page/WebappUrls';
import { load } from '../util/RemoteData';

/**
 * Page displaying a particular content block's contents (iframed in).
 */
export const LearnContentPage: PageLevelComponent = () => {
  return (
    <PathParamsReader params={{ sectionId: 'int', contentBlockName: 'string' }}>
      {({ sectionId, contentBlockName }) => {
        const { fullSection } = useAppContext();
        const retryFetchFullSection = useGetRetryFetch<FullSection>();
        useEffect(() => {
          load(
            fullSection,
            () =>
              retryFetchFullSection(() =>
                CoursesService.coursesSectionsRetrieve(sectionId)
              ),
            { sectionId }
          );
        }, [sectionId]);
        const contentBlock = fullSection.data?.content_blocks.find(
          (cb) => cb.database_name === contentBlockName
        );
        if (!fullSection.data) {
          return <LoaderOverlay />;
        }
        if (!contentBlock) {
          return <NotFoundPage />;
        }
        return (
          <PageLayout
            back={{
              label: 'Back',
              destination: generatePath(WebappUrls.learnSection, { sectionId })
            }}
            contentStyle={{
              height: '100%'
            }}
          >
            <iframe
              title={contentBlock.title}
              src={contentBlock.link}
              style={{
                flexGrow: 1,
                width: '100%',
                border: 0,
                height: '100%'
              }}
            />
          </PageLayout>
        );
      }}
    </PathParamsReader>
  );
};
