import { styled } from '@mui/material/styles';
import LinesEllipsis from 'react-lines-ellipsis';

import { Interstitial } from '../../general/ServerClient';
import {
  mobileMediaQuery,
  notMobileMediaQuery
} from '../../StyleGuide/Breakpoints';
import { Concrete, White, William } from '../../StyleGuide/Colors';
import { H3, P } from '../../StyleGuide/Text';
import { SIDE_PADDING } from './constants';
import { estimatedTime } from './InterstitialDialog';

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `10px ${SIDE_PADDING}px 20px`,
  background: `linear-gradient(white, ${Concrete})`
});
const ContentButton = styled('button')({
  display: 'flex',
  cursor: 'pointer',
  boxShadow: '6px 6px 8px 1px rgb(0 0 0 / 32%)',
  width: '100%',
  margin: 0,
  border: 0,
  outline: 'none',
  borderRadius: 15,
  padding: 0,
  overflow: 'hidden',
  background: 'white',
  flexDirection: 'column',
  '& img': {
    maxWidth: '100%'
  }
});
const MetadataContainer = styled('div')({
  lineHeight: 'initial',
  position: 'absolute',
  bottom: 15,
  left: 15,
  display: 'flex',
  flexDirection: 'column',
  color: White,
  alignItems: 'baseline'
});
const Title = styled(H3)({
  margin: '10px 0',
  textAlign: 'left',
  [mobileMediaQuery]: {
    fontSize: 28,
    lineHeight: '34px'
  },
  [notMobileMediaQuery]: {
    margin: '10px 0 3px'
  }
});
const Gradient = styled('div')({
  position: 'absolute',
  top: '40%',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundImage: 'linear-gradient(transparent, black)',
  opacity: 0.7
});
const Time = styled('span')({
  fontSize: 20,
  [mobileMediaQuery]: {
    fontSize: 16
  }
});
const ImgContainer = styled('div')({
  position: 'relative'
});
const BlurbContainer = styled('div')({
  padding: 15
});
const Blurb = styled('div')({
  textAlign: 'left',
  fontSize: 15,
  margin: '0 0 10px',
  lineHeight: '20px',
  [notMobileMediaQuery]: {
    fontSize: 16,
    lineHeight: '24px'
  }
});
const ReadMore = styled(P)({
  fontSize: 13,
  textAlign: 'left',
  color: William,
  margin: 0
});

export const INTERSTITIAL_SUMMARY_TEST_ID = 'interstitial-summary';

function markdownToString(markdownText: string): string {
  const htmlText = markdownText
    .replace(/^### (.*$)/gim, '$1')
    .replace(/^## (.*$)/gim, '$1')
    .replace(/^# (.*$)/gim, '$1')
    .replace(/^> (.*$)/gim, '$1')
    .replace(/\*\*(.*)\*\*/gim, '$1')
    .replace(/\*(.*)\*/gim, '$1')
    .replace(/!\[(.*?)\]\((.*?)\)/gim, '')
    .replace(/\[(.*?)\]\((.*?)\)/gim, '$1');

  return htmlText.trim();
}

/**
 * A "teaser" for an interstitial, showing part of the interstitial's contents and opening a dialog
 *   with the full contents on click.
 */
export const InterstitialTeaser: React.FC<{
  interstitial: Interstitial;
  onClick: () => void;
}> = ({ interstitial, onClick }) => {
  return (
    <Root data-testid={INTERSTITIAL_SUMMARY_TEST_ID}>
      <ContentButton {...{ onClick }}>
        <ImgContainer>
          <img src={interstitial.image_2x} alt={interstitial.title} />
          <Gradient />
          <MetadataContainer>
            {interstitial.title && <Title>{interstitial.title}</Title>}
            {interstitial.estimated_time && (
              <Time>{estimatedTime(interstitial.estimated_time)}</Time>
            )}
          </MetadataContainer>
        </ImgContainer>
        <BlurbContainer>
          <Blurb>
            <LinesEllipsis
              text={markdownToString(interstitial.content)}
              maxLine={2}
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </Blurb>
          <ReadMore>Read more</ReadMore>
        </BlurbContainer>
      </ContentButton>
    </Root>
  );
};
