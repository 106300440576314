import classNames from 'classnames';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { FAQAnchor } from '../general/Routing';
import AddIcon from '../Icons/AddIcon';
import CloseIcon from '../Icons/CloseIcon';
import { H7, P } from '../StyleGuide/Text';
import styles from './FAQs.module.css';

interface FAQData {
  question: string;
  answer: string;
}

const FAQ: React.FC<FAQData> = ({ question, answer }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const iconProps = {
    className: styles.expandIcon
  };
  return (
    <button
      className={classNames(styles.faq)}
      onClick={() => setExpanded(!expanded)}
    >
      <div className={styles.topRow}>
        <P className={styles.question}>{question}</P>
        {expanded ? <CloseIcon {...iconProps} /> : <AddIcon {...iconProps} />}
      </div>
      <AnimateHeight height={expanded ? 'auto' : 0} duration={300}>
        <P className={styles.answer}>{answer}</P>
      </AnimateHeight>
    </button>
  );
};

// unconnected
const FAQsU: React.FC<{
  faqs: FAQData[];
}> = ({ faqs }) => {
  return (
    <div className={styles.faqs}>
      <H7 className={styles.header} id={FAQAnchor}>
        FAQs
      </H7>
      {faqs.map((faq) => (
        <FAQ key={faq.question} {...faq} />
      ))}
    </div>
  );
};

const FAQs: React.FC = () => {
  const faqs = [
    {
      question: 'How is SoShe’s childbirth class different from other classes?',
      answer:
        'This is not your mother’s birth class. First, it’s all online, so you can go at your own pace, and revisit important concepts anytime (fyi: going in person to a day-long class can be a firehose of information that’s impossible to remember, or act upon). Second, SoShe doesn’t judge. We don’t push “natural” births, nor do we push epidurals. The only thing we “push” is that you have the information you need to decide what’s right for you and your family, and to make sure that during birth, decisions are make with you, and not for you. '
    },
    {
      question:
        'I’ve heard birth plans aren’t useful? Why does SoShe encourage them?',
      answer:
        'It’s true. You can’t plan or control exactly how your birth goes. But that doesn’t mean you shouldn’t prepare, and think about the kind of SUPPORT you want from your doctors, nurses, and partner. Effective birth plans are a learning process for you. They’re also great for knowing what to ask at your prenatal appointments.'
    },
    {
      question: 'What if I’m being induced? Or having a c-section?',
      answer:
        'SoShe can help. We have an entire section on induction -  and another section dedicated to cesarean births - covering reasons, risks & benefits, and tips for a positive experience. Given how common both of these are, we recommend that everyone prepares for both of these scenarios, just in case.'
    },
    {
      question: 'What if I or my baby have any special risks or complications?',
      answer:
        'SoShe does not replace the care and advice of your medical team, whether you’re low risk or high risk. Our goal is to help you have a positive dialog and relationship with your care team during your prenatal appointments and when you’re in labor. If you’re uncertain about any of the information in the SoShe class or articles, please consult your medical team. Again, SoShe does not practice medicine nore replace the care and advice or your medical team.'
    },
    {
      question:
        'Who are the coaches? Can I choose my coach? Can I switch later if I want?',
      answer:
        'All of SoShe’s childbirth coaches are certified childbirth educators and/or birth doulas. You get to pick your coach, and if it’s not a great fit, you can always switch. It’s your birth. We’re here to support you.'
    }
  ];
  return <FAQsU {...{ faqs }} />;
};
export default FAQs;
