import React, { useEffect, useState } from 'react';

import { BirthPlan, BirthPlanService } from '../../general/ServerClient';
import { useAppContext } from '../page/WebappContext';
import {
  UpdateProfileFooterButtons,
  UpdateProfileInformationDialog
} from './UpdateProfileInformationDialog';

export const UpdateDueDateDialog: React.FC<{
  isOpen: boolean;
  dismiss: () => void;
}> = ({ isOpen, dismiss }) => {
  const { birthPlan } = useAppContext();

  const today = new Date(Date.now()).toISOString().split('T')[0];
  const [dueDate, setDueDate] = useState<string>(today);
  useEffect(() => {
    if (birthPlan.data?.birth_date) {
      const birthDate = new Date(Date.parse(birthPlan.data.birth_date));
      setDueDate(birthDate.toISOString().split('T')[0]);
    } else {
      setDueDate(today);
    }
  }, [birthPlan.data?.birth_date]);
  return (
    <UpdateProfileInformationDialog open={isOpen} onClose={dismiss}>
      <>
        <input
          type="date"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
          min={today}
        />
        <UpdateProfileFooterButtons
          onCancel={dismiss}
          onSubmit={() => {
            if (birthPlan.data) {
              const newDueDate = new Date(Date.parse(dueDate)).toISOString();
              const newBirthPlan: BirthPlan = {
                ...birthPlan.data,
                birth_date: newDueDate
              };
              birthPlan.setData(newBirthPlan);
              BirthPlanService.birthPlanProfileCreate(newBirthPlan);
            }
            dismiss();
          }}
        />
      </>
    </UpdateProfileInformationDialog>
  );
};
