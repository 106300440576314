import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import LoaderOverlay from '../../general/LoaderOverlay';
import { BirthPlanService, TypeEnum } from '../../general/ServerClient';
import { isDefined } from '../../general/util';
import { H7 } from '../../StyleGuide/Text';
import { useGetRetryFetch } from '../page/Auth';
import { useAppContext } from '../page/WebappContext';
import { useOnboardingContext } from './OnboardingContext';
import { Options } from './Options';
import { Progress } from './Progress';

const StyledProgress = styled(Progress)({
  marginTop: 25
});

/**
 * A stage in the Onboarding flow.
 */
export const ProviderStage: React.FC = () => {
  const {
    setStage,
    setNextText,
    setNextAction,
    dueDate,
    birthTypeId,
    birthLocationId
  } = useOnboardingContext();
  const { options } = useAppContext();
  const [p, setP] = useState<number | undefined>(undefined);
  const [creatingProfile, setCreatingProfile] = useState<boolean>(false);
  const createProfile = useGetRetryFetch();
  useEffect(() => setNextText('Next'), []);
  useEffect(() => {
    if (p === undefined) {
      setNextAction(undefined);
    } else {
      setNextAction(() => {
        if (
          dueDate === undefined ||
          birthTypeId === undefined ||
          birthLocationId === undefined
        ) {
          throw new Error(
            `Cannot submit birth plan profile - insufficient data. ${JSON.stringify(
              { dueDate, birthTypeId, birthLocationId }
            )}`
          );
        }
        setCreatingProfile(true);
        createProfile(() =>
          BirthPlanService.birthPlanProfileCreate({
            type: TypeEnum.P,
            birth_date: new Date(Date.parse(dueDate)).toISOString(),
            birth_location: birthLocationId,
            birth_type: birthTypeId,
            birth_provider: p,
            // TODO samgqroberts 2021-12-12 openapi spec wrong here, don't need created_date for create
            created_date: ''
          })
        ).then(() => setStage('conclusion'));
      });
    }
  }, [p]);
  // TODO samgqroberts 2021-12-10 should 'request' this data, and context should detect it's already been loaded and
  //      just serve the loaded one.
  //      can extend this to cache in localstorage as well.
  if (!options.data) {
    console.error('Options not loaded');
    return null;
  }
  return (
    <div>
      <StyledProgress progress={100} />
      <H7>Who&apos;s leading your care?</H7>
      <Options<number>
        options={options.data.providers
          .sort((a, b) => a.order - b.order)
          .map((provider) => ({ id: provider.id, name: provider.name }))}
        selected={[p].filter(isDefined)}
        onSelect={(id) => setP(id)}
        onDeselect={() => {}}
      />
      {creatingProfile && <LoaderOverlay />}
    </div>
  );
};
