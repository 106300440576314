import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { PaymentSuccessHandler } from '../../CheckoutPage/CheckoutForm';
import { CheckoutPage } from '../../CheckoutPage/CheckoutPage';
import { AppCheckoutSuccessPage } from '../../CheckoutSuccessPage/AppCheckoutSuccessPage';
import { PaymentsService } from '../../general/ServerClient';
import { browserNavigate, PageLevelComponent } from '../../general/util';
import { MembershipPlansPage } from '../../MembershipPlansPage';
import {
  FreeClaimSuccessHandler,
  PaidSkuSignupHandler,
  UserInfo
} from '../../MembershipPlansPage/Plans';
import { upgradeLS, useAppContext } from '../page/WebappContext';
import { WebappUrls } from '../page/WebappUrls';

export const WebappMembershipPlansPage: PageLevelComponent = () => {
  const { userDetails, upgrade } = useAppContext();
  const history = useHistory();
  useEffect(() => {
    // on navigation to this page should wipe any remaining information from any previous upgrade attempt
    upgrade.setData(upgradeLS.dummyState.data);
  }, []);
  const alreadyRegistered = true;
  const onFreeClaimSuccess: FreeClaimSuccessHandler = (productName) => {
    const email = userDetails.data?.email;
    upgrade.setData({ productName, couponUsed: null, pricePaid: null });
    if (email) {
      PaymentsService.paymentsClaimCreate({
        sku_name: productName,
        email
      }).then(() => {
        history.push(WebappUrls.upgradeSuccess);
      });
    }
  };
  const onPaidSkuSignup: PaidSkuSignupHandler = (productName) => {
    upgrade.setData({ productName, couponUsed: null, pricePaid: null });
    history.push(WebappUrls.checkout);
  };
  const userInfo: UserInfo = {
    email: userDetails.data?.email || null,
    firstName: userDetails.data?.first_name || null,
    lastName: userDetails.data?.last_name || null,
    currentPlan: userDetails.data?.plan.database_name || null
  };
  const loadingUserInfo = userDetails.loading;
  const error = false;
  return (
    <MembershipPlansPage
      {...{
        alreadyRegistered,
        onFreeClaimSuccess,
        onPaidSkuSignup,
        userInfo,
        loadingUserInfo,
        error
      }}
    />
  );
};

export const WebappCheckoutPage: PageLevelComponent = () => {
  const history = useHistory();
  const { userDetails, upgrade } = useAppContext();
  const productName = upgrade.data.productName;
  const userInfo: UserInfo = {
    email: userDetails.data?.email || null,
    firstName: userDetails.data?.first_name || null,
    lastName: userDetails.data?.last_name || null,
    currentPlan: userDetails.data?.plan.database_name || null
  };
  const addToCartOnLoad = true;
  const onPaymentSuccess: PaymentSuccessHandler = ({
    productName,
    couponUsed,
    pricePaid
  }) => {
    upgrade.setData({
      productName,
      couponUsed,
      pricePaid
    });
    history.push(WebappUrls.upgradeSuccess);
  };
  return (
    <CheckoutPage
      {...{
        productName,
        userInfo,
        addToCartOnLoad,
        onPaymentSuccess
      }}
    />
  );
};

export const WebappUpgradeSuccessPage: PageLevelComponent = () => {
  const { userDetails, upgrade } = useAppContext();
  const email = userDetails.data?.email || null;
  const productName = upgrade.data.productName;
  const onNext = () => {
    browserNavigate('/');
  };
  return <AppCheckoutSuccessPage {...{ email, productName, onNext }} />;
};
