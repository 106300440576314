import { createContext, useContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

type Stage =
  | 'goals'
  | 'assurance'
  | 'duedate'
  | 'birthtype'
  | 'birthlocation'
  | 'provider'
  | 'conclusion';

type NextAction = undefined | (() => void);
export interface IOnboardingState {
  stage: Stage;
  setStage: (stage: Stage) => void;
  nextAction: NextAction;
  setNextAction: (fn: NextAction) => void;
  nextText: string;
  setNextText: (nextText: string) => void;
  dueDate: string | undefined;
  setDueDate: (dueDate: string) => void;
  birthTypeId: number | undefined;
  setBirthTypeId: (birthTypeId: number) => void;
  birthLocationId: number | undefined;
  setBirthLocationId: (birthLocationId: number) => void;
}

const initialOnboardingState: IOnboardingState = {
  stage: 'goals',
  setStage: noop,
  nextAction: undefined,
  setNextAction: noop,
  nextText: 'Next',
  setNextText: noop,
  dueDate: undefined,
  setDueDate: noop,
  birthTypeId: undefined,
  setBirthTypeId: noop,
  birthLocationId: undefined,
  setBirthLocationId: noop
};

export const OnboardingContext = createContext<IOnboardingState>(
  initialOnboardingState
);

export const OnboardingProvider: React.FC = ({ children }) => {
  const [stage, setStage] = useState<Stage>('goals');
  const [nextAction, setNextAction] = useState<NextAction>(undefined);
  const [nextText, setNextText] = useState<string>('Next');
  const [dueDate, setDueDate] = useState<string | undefined>(undefined);
  const [birthTypeId, setBirthTypeId] = useState<number | undefined>(undefined);
  const [birthLocationId, setBirthLocationId] = useState<number | undefined>(
    undefined
  );
  return (
    <OnboardingContext.Provider
      value={{
        stage,
        setStage,
        nextAction,
        setNextAction: (na) => {
          setNextAction(() => na);
        },
        nextText,
        setNextText,
        dueDate,
        setDueDate,
        birthTypeId,
        setBirthTypeId,
        birthLocationId,
        setBirthLocationId
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = (): IOnboardingState =>
  useContext(OnboardingContext);
