import React from 'react';

import { LogoLink } from '../general/LogoLink';
import styles from './Navbar.module.css';

const Navbar: React.FC = () => {
  return (
    <div className={styles.navbar}>
      <div className={styles.navLogoContainer}>
        <LogoLink linkClassName={styles.navLogoLink} />
      </div>
    </div>
  );
};
export default Navbar;
