import { styled } from '@mui/material/styles';
import React from 'react';

import { Questions } from '../../StyleGuide/Colors';
import { BirthPlanStarIcon } from './BirthPlanStarIcon';

const Title = styled('div')({
  fontWeight: 'bold'
});
const Subtitle = styled('div')({
  marginTop: 6,
  fontSize: 12,
  color: Questions,
  fontWeight: 'bold'
});
const StyledStarIcon = styled(BirthPlanStarIcon)({
  marginLeft: 8
});

export const TitleAndSubtitle: React.FC<{
  title: React.ReactNode;
  subtitle: React.ReactNode;
  showStar?: boolean;
}> = ({ title, subtitle, showStar }) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title>{title}</Title>
        {showStar && <StyledStarIcon />}
      </div>
      <Subtitle>{subtitle}</Subtitle>
    </>
  );
};
