import classNames from 'classnames';
import isNumber from 'predicates/number';
import React, { useState } from 'react';

import { PriceCheckResponse, Sku } from '../general/ServerClient';
import useIsMobile from '../general/useIsMobile';
import {
  fmap,
  maybe,
  moneyFormatCents,
  parseStringAsFloat
} from '../general/util';
import { ButtonLink } from '../StyleGuide/Button';
import { H7, Span } from '../StyleGuide/Text';
import styles from './OrderSummary.module.css';

const OrderSummaryChrome: React.FC = ({ children }) => (
  <div className={styles.orderSummary}>
    <H7 className={styles.header}>Order Summary</H7>
    <hr />
    {children}
  </div>
);

const OrderSummary: React.FC<{
  product: Sku | undefined;
  applyPromoCode: (promoCode: string) => void;
  priceCheckResponse: PriceCheckResponse | undefined;
}> = ({ product, applyPromoCode, priceCheckResponse }) => {
  const displayName = product?.display_name;
  const [promoCode, setPromoCode] = useState<string>('');
  const isMobile = useIsMobile();
  const [addingPromoCode, setAddingPromoCode] = useState<boolean>(false);

  if (!product) {
    return (
      <OrderSummaryChrome>
        <div className={styles.productInfo}>
          <div className={styles.productInfoRow}>
            <Span className={styles.productName}>{displayName}</Span>
          </div>
        </div>
      </OrderSummaryChrome>
    );
  }

  const discountedPriceAfterCoupon =
    priceCheckResponse && priceCheckResponse.accepted_coupon
      ? parseStringAsFloat(priceCheckResponse.final_price)
      : undefined;

  const price = parseStringAsFloat(product.price);
  const priceFmtd = maybe(price, moneyFormatCents, '');
  const fullPriceRaw = fmap(product.full_price, parseStringAsFloat);
  const fullPrice =
    isNumber(price) && price === fullPriceRaw ? undefined : fullPriceRaw;

  const finalPrice = isNumber(discountedPriceAfterCoupon)
    ? discountedPriceAfterCoupon
    : price;
  const finalPriceFmtd = maybe(finalPrice, moneyFormatCents, '');

  const finalPriceSection = (
    <div className={styles.totalSection}>
      <div className={classNames(styles.productInfoRow, styles.total)}>
        <Span>Total</Span>
        <Span>{finalPriceFmtd}</Span>
      </div>
    </div>
  );

  const promoSection = (
    <div className={styles.promoSection}>
      <div className={styles.productInfoRow}>
        <Span className={styles.productName}>Promo Code</Span>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            applyPromoCode(promoCode);
          }}
        >
          <input
            type="text"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            data-testid="promo-code-input"
          />
          <button type="submit">Apply</button>
        </form>
      </div>
      {isNumber(discountedPriceAfterCoupon) && (
        <div className={styles.productInfoRow}>
          <Span className={styles.discountedPrice}>Promo code applied</Span>
        </div>
      )}
      {priceCheckResponse && !priceCheckResponse.accepted_coupon && (
        <div className={styles.productInfoRow}>
          <span className={styles.badCoupon}>Invalid coupon</span>
        </div>
      )}
    </div>
  );

  if (isMobile) {
    return (
      <OrderSummaryChrome>
        {finalPriceSection}
        {addingPromoCode ? (
          promoSection
        ) : (
          <ButtonLink
            className={styles.addPromoCode}
            onClick={() => setAddingPromoCode(true)}
          >
            Add Promo Code
          </ButtonLink>
        )}
      </OrderSummaryChrome>
    );
  }

  return (
    <OrderSummaryChrome>
      <div className={styles.productInfo}>
        <div className={styles.productInfoRow}>
          <Span className={styles.productName}>{displayName}</Span>
          {fullPrice ? (
            <Span className={styles.fullPrice}>
              {moneyFormatCents(fullPrice)}
            </Span>
          ) : (
            <Span className={styles.price}>{priceFmtd}</Span>
          )}
        </div>
        {fullPrice && (
          <div className={styles.productInfoRow}>
            <Span className={styles.discountedPrice}>{priceFmtd}</Span>
          </div>
        )}
      </div>
      {promoSection}
      <hr />
      {finalPriceSection}
    </OrderSummaryChrome>
  );
};
export default OrderSummary;
