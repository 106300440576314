import isNumber from 'predicates/number';
import React from 'react';

import useIsMobile from '../../general/useIsMobile';
import { Tundora, White } from '../../StyleGuide/Colors';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';

/**
 * Helper component for a circular progress indicator to be displayed over an image.
 */
export const OverImageProgress: React.FC<{ value: number; size?: number }> = ({
  value,
  size: rawSize
}) => {
  const isMobile = useIsMobile();
  const size = isNumber(rawSize) ? rawSize : isMobile ? 60 : 70;
  return (
    <CircularProgressWithLabel
      backgroundColor={Tundora}
      foregroundColor={White}
      fontColor={White}
      completedColor={White}
      {...{ value, size }}
    />
  );
};
