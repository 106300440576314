import React from 'react';
import { generatePath } from 'react-router';

import { PageLevelComponent } from '../../general/util';
import { InProgressBackdrop } from '../Course/InProgressBackdrop';
import { InProgressSection } from '../Course/InProgressSection';
import { SectionList } from '../Course/SectionList';
import { PageLayout } from '../page/PageLayout';
import { useAppContext, useBirthPlanCourse } from '../page/WebappContext';
import { WebappUrls } from '../page/WebappUrls';
import { combineSectionsAndProgress } from '../util/util';
import { PlanActions } from './PlanActions';

/**
 * Top level navigational tab content.
 * Displays list of Sections related to the birth plan.
 */
export const BirthPlanPage: PageLevelComponent = () => {
  const birthPlanCourse = useBirthPlanCourse();
  const { birthPlanCourseProgress } = useAppContext();

  const sections =
    birthPlanCourse && birthPlanCourseProgress.data
      ? combineSectionsAndProgress(
          birthPlanCourse,
          birthPlanCourseProgress.data
        )
      : undefined;
  const inProgressSection = sections?.find(({ progress }) => {
    return progress.overall.percent_completed < 100;
  });
  const allSectionsNotStarted = !!sections?.every(
    ({ progress }) => progress.overall.completed === 0
  );
  return (
    <PageLayout>
      <InProgressBackdrop>
        {inProgressSection && (
          <InProgressSection
            section={inProgressSection}
            path={generatePath(WebappUrls.planSection, {
              sectionId: inProgressSection.section.id
            })}
            {...{ allSectionsNotStarted }}
          />
        )}
        <PlanActions />
      </InProgressBackdrop>
      {sections && (
        <SectionList
          {...{
            sections,
            generatePath: (sectionId) =>
              generatePath(WebappUrls.planSection, { sectionId })
          }}
        />
      )}
    </PageLayout>
  );
};
