import { useWindowWidth } from '@react-hook/window-size';

import { MobileBreakpoint } from '../StyleGuide/Breakpoints';

const useIsMobile = (): boolean => {
  const windowWidth = useWindowWidth();
  return windowWidth < MobileBreakpoint;
};

export default useIsMobile;
