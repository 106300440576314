import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import PageUrls from '../general/Routing';
import { ApiError, AuthService } from '../general/ServerClient';
import { browserNavigate, PageLevelComponent } from '../general/util';
import { Button } from '../StyleGuide/Button';
import { Placeholder } from '../StyleGuide/Colors';
import {
  IconEmailInput,
  VisibilityTogglePasswordInput
} from '../StyleGuide/Input';
import { P } from '../StyleGuide/Text';
import { storeRefreshToken } from '../webapp/page/Auth';
import styles from './LoginPage.module.css';

export const LoginPage: PageLevelComponent = () => {
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const handleRequestEmail: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setLoggingIn(true);
    setError('');
    AuthService.authTokenCreate({ username: email, password })
      .then((jwt) => {
        storeRefreshToken(jwt.refresh);
        browserNavigate('/');
      })
      .catch((e: Error) => {
        if (!(e instanceof ApiError) || !(e.status === 401)) {
          console.error('Error logging in.', e);
          setError('There was an unknown error logging in.');
          return;
        } else {
          setError('Incorrect email or password. Please try again.');
        }
        setLoggingIn(false);
      });
  };
  return (
    <div className={styles.page}>
      <form
        onSubmit={handleRequestEmail}
        style={{
          boxShadow: `0px 2px 6px ${Placeholder}`,
          borderRadius: 4,
          padding: 20
        }}
      >
        <IconEmailInput
          value={email}
          setValue={setEmail}
          autoComplete="username"
        />
        <VisibilityTogglePasswordInput
          value={password}
          setValue={setPassword}
        />
        <Link
          to={PageUrls.requestPasswordReset}
          style={{
            fontFamily: "'Poppins', sans-serif",
            textDecoration: 'none',
            fontSize: 14,
            alignSelf: 'baseline',
            marginTop: 20
          }}
        >
          Forgot password?
        </Link>
        {error && <P className={styles.error}>{error}</P>}
        <Button type="submit" disabled={!email || !password || loggingIn}>
          Sign in
        </Button>
      </form>
      <div style={{ marginBottom: 20 }}>
        New to Soshe?&nbsp;&nbsp;
        <Link to={PageUrls.membership} style={{ textDecoration: 'none' }}>
          Join now
        </Link>
      </div>
    </div>
  );
};
