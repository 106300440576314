import React from 'react';
import { Route } from 'react-router';

import { LearnContentPage } from '../LearnContentPage/LearnContentPage';
import { LearnSectionPage } from '../LearnSectionPage/LearnSectionPage';
import { RouteSwitcher } from '../page/RouteSwitcher';
import { WebappUrls } from '../page/WebappUrls';
import { QuestionPage } from '../QuestionPage/QuestionPage';
import { LearnPage } from './LearnPage';

export const LearnRoutes: React.FC = () => {
  return (
    <RouteSwitcher>
      <Route exact path={WebappUrls.home}>
        <LearnPage />
      </Route>
      <Route exact path={WebappUrls.learnSection}>
        <LearnSectionPage />
      </Route>
      <Route exact path={WebappUrls.learnQuestion}>
        <QuestionPage />
      </Route>
      <Route exact path={WebappUrls.learnContent}>
        <LearnContentPage />
      </Route>
    </RouteSwitcher>
  );
};
