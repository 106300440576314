/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { UserDetails } from '../models/UserDetails';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Retrieve details for the current user
     * @returns User
     * @throws ApiError
     */
    public static async userDetailsRetrieve(): Promise<User> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/user/details`,
        });
        return result.body;
    }

    /**
     * Update details for the current user
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static async userDetailsCreate(
        requestBody: UserDetails,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/user/details`,
            body: requestBody,
            errors: {
                400: `No response body`,
            },
        });
        return result.body;
    }

}