import { Check } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';

import { H7 } from '../../StyleGuide/Text';
import { useOnboardingContext } from './OnboardingContext';

const Root = styled('div')({});
const Offerings = styled('ul')({
  listStyle: 'none',
  padding: 0
});
const Offering = styled('li')({
  display: 'flex',
  alignItems: 'center',
  '&:not(:first-of-type)': {
    marginTop: 8
  }
});
const OfferingText = styled('span')({
  marginLeft: 5
});

const offerings = [
  'Online Childbirth Class',
  '20+ Videos and 50+ Articles',
  'Birth Plan Builder',
  'Optional 1-1 Birth Coach Support',
  'Plus: Latest on COVID and Birth'
];

/**
 * A stage in the Onboarding flow.
 */
export const AssuranceStage: React.FC = () => {
  const { setStage, setNextText, setNextAction } = useOnboardingContext();
  useEffect(() => {
    setNextText('Got it.');
    setNextAction(() => setStage('duedate'));
  }, []);
  return (
    <Root>
      <H7>You&apos;re in good hands. SoShe covers all of that and more.</H7>
      <Offerings>
        {offerings.map((offering) => {
          return (
            <Offering key={offering}>
              <Check />
              <OfferingText>{offering}</OfferingText>
            </Offering>
          );
        })}
      </Offerings>
    </Root>
  );
};
