import { WKMessageHandler } from './webkit-types';

export function getMessageHandler(
  messageHandlerName: string
): WKMessageHandler | undefined {
  if (!('webkit' in window) || !window.webkit) {
    console.error(
      'Cannot communicate with iOS app: `window` does not contain `webkit`'
    );
    return undefined;
  }
  const { webkit } = window;
  if (!('messageHandlers' in webkit)) {
    console.error(
      'Cannot communicate with iOS app, `window.webkit` does not contain `messageHandlers`'
    );
    return undefined;
  }
  const { messageHandlers } = webkit;
  const handler = messageHandlers[messageHandlerName];
  if (!handler) {
    console.error(
      `Cannot communicate with iOS app, \`window.webkit.messageHandlers\` does not contain \`${messageHandlerName}\``
    );
    return undefined;
  }
  return handler;
}
