import classNames from 'classnames';
import isNumber from 'predicates/number';
import isString from 'predicates/string';
import { forwardRef, HTMLProps } from 'react';

import styles from './Divider.module.css';

const Divider = forwardRef<
  HTMLHRElement,
  HTMLProps<HTMLHRElement> & { width?: number | string; widthPx?: number }
>(function Divider(props, ref) {
  const { widthPx, style, ...hrProps } = props;
  const widthProp = props.width;
  const width = isNumber(widthPx)
    ? `${widthPx}px`
    : isString(widthProp)
    ? widthProp
    : isNumber(widthProp)
    ? `${widthProp}%`
    : '100%';
  return (
    <hr
      {...{ ref }}
      {...hrProps}
      className={classNames(styles.divider, props.className)}
      style={{ ...style, width }}
    />
  );
});
export default Divider;
