/**
 * The generated server client code is produced using https://github.com/ferdikoomen/openapi-typescript-codegen.
 * The options in this file configure the generated client code.
 * Therefore this file must be imported in order for the generated client code to work.
 * Since this file re-exports everything from the generated client code, the rest of the app should
 *   simply import from this file.
 */

import { ApiError, OpenAPI } from '../generated/server/index'; // eslint-disable-line import/no-restricted-paths
import { appEnv } from './AppEnv';

export const SERVER_URL_BASE = appEnv('SERVER_BASE_URL');

OpenAPI.BASE = SERVER_URL_BASE;
OpenAPI.HEADERS = {};

export * from '../generated/server'; // eslint-disable-line import/no-restricted-paths

interface ResultSuccess<T> {
  type: 'success';
  data: T;
}

interface ResultError {
  type: 'error';
  error: ApiError;
}

export type ServerResult<T> = ResultSuccess<T> | ResultError;

export async function asResult<T>(
  fn: () => Promise<T>
): Promise<ServerResult<T>> {
  try {
    const data = await fn();
    return { type: 'success', data };
  } catch (error) {
    if (error instanceof ApiError) {
      return { type: 'error', error };
    }
    throw error;
  }
}

const AUTH_HEADER_KEY = 'Authorization';

export function setAuthHeader(access: string): void {
  const value = `Bearer ${access}`;
  if (OpenAPI.HEADERS) {
    // @ts-expect-error TODO
    OpenAPI.HEADERS[AUTH_HEADER_KEY] = `Bearer ${access}`;
  } else {
    OpenAPI.HEADERS = { [AUTH_HEADER_KEY]: value };
  }
}

export function unsetAuthHeader(): void {
  // @ts-expect-error TODO
  if (OpenAPI.HEADERS?.['Authorization']) {
    // @ts-expect-error TODO
    delete OpenAPI.HEADERS['Authorization'];
  }
}
export function setHeaders(headers: typeof OpenAPI['HEADERS']): void {
  OpenAPI.HEADERS = headers;
}
