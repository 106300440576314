import { P } from '../StyleGuide/Text';

export const CenteredMessage: React.FC = ({ children }) => {
  return <P style={{ textAlign: 'center' }}>{children}</P>;
};

export const MissingParametersMessage: React.FC<{ parameters: string[] }> = ({
  parameters
}) => {
  return (
    <CenteredMessage>
      Missing required parameter{parameters.length === 1 ? '' : 's'}:{' '}
      {parameters.join(', ')}
    </CenteredMessage>
  );
};
