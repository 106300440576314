import { useEffect, useState } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

// https://stackoverflow.com/a/60978633/5828638
export const useContainerDimensions = (
  myRef: React.MutableRefObject<HTMLElement | null>
): Dimensions | undefined => {
  const getDimensions = () => {
    const { current } = myRef;
    if (!current) {
      return undefined;
    }
    return {
      width: current.offsetWidth,
      height: current.offsetHeight
    };
  };

  const [dimensions, setDimensions] = useState<Dimensions | undefined>(
    undefined
  );

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return dimensions;
};
