import React, { useState } from 'react';

import Divider from '../general/Divider';
import {
  MidsizeBreakpoint,
  MidsizeWideBreakpoint,
  MobileBreakpoint,
  WideBreakpoint
} from './Breakpoints';
import {
  Button,
  buttonColorVariants,
  ButtonLink,
  buttonSizes,
  LinkButton
} from './Button';
import { ColorName, colorNames, colors } from './Colors';
import {
  Checkbox,
  EmailInput,
  FirstNameInput,
  Input,
  LastNameInput,
  Select,
  TextArea,
  VisibilityTogglePasswordInput
} from './Input';
import styles from './StyleGuidePage.module.css';
import {
  BodyParagraph,
  BodySpan,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  H7,
  H8,
  H9
} from './Text';

const Section: React.FC<{ title: string }> = ({ title, children }) => {
  return (
    <div className={styles.section}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.items}>{children}</div>
    </div>
  );
};

const Swatch: React.FC<{ color: ColorName }> = ({ color }) => {
  const hex = colors[color];
  return (
    <div className={styles.swatchContainer}>
      <div className={styles.swatch} style={{ background: hex }} />
      <span className={styles.label}>{color}</span>
      <span className={styles.hex}>{hex}</span>
    </div>
  );
};

const StyleGuidePage: React.FC = () => {
  const [value, setValue] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  return (
    <div className={styles.page}>
      <Section title="Colors">
        {colorNames.map((color) => (
          <Swatch key={color} {...{ color }} />
        ))}
      </Section>
      <Section title="Buttons">
        {buttonSizes.map((size) => {
          const variantStacks = buttonColorVariants.map((colorVariant) => {
            const name = `${size} | ${colorVariant}`;
            return (
              <React.Fragment key={name}>
                <Button {...{ size, colorVariant }}>{name}</Button>
                <Button {...{ size, colorVariant }} disabled>
                  {name}
                </Button>
                <LinkButton to={location} {...{ size, colorVariant }}>
                  {name} (link)
                </LinkButton>
                <LinkButton to={location} {...{ size, colorVariant }} disabled>
                  {name} (link)
                </LinkButton>
              </React.Fragment>
            );
          });
          return (
            <div key={size} className={styles.buttonStack}>
              {variantStacks}
            </div>
          );
        })}
        <ButtonLink>ButtonLink</ButtonLink>
      </Section>
      <Section title="Inputs">
        <Input placeholder="Input" />
        <FirstNameInput />
        <LastNameInput />
        <EmailInput />
        <Select>
          <option value="Option 1">Select</option>
          <option value="Option 2">Select Option 2</option>
          <option value="Option 3">Select Option 3</option>
        </Select>
        <TextArea placeholder="TextArea" />
        <VisibilityTogglePasswordInput {...{ value, setValue }} />
        <Checkbox checked={checked} onChange={setChecked}>
          Checkbox
        </Checkbox>
      </Section>
      <Section title="Text">
        <H1>Header1</H1>
        <H2>Header2</H2>
        <H3>Header3</H3>
        <H4>Header4</H4>
        <H5>Header5</H5>
        <H6>Header6</H6>
        <H7>Header7</H7>
        <H8>Header8</H8>
        <H9>Header9</H9>
        <BodyParagraph>Paragraph</BodyParagraph>
        <BodySpan>Span</BodySpan>
      </Section>
      <Divider />
      <div className={styles.screenSizes}>
        <h3>Screen sizes</h3>
        <p>
          In order to implement responsive design, we break the screen up into
          different categories of sizes via standard &quot;breakpoints.&quot;
        </p>
        <p>
          When the screen&apos;s width passes a particular
          &quot;breakpoint,&quot; the styling layer may apply different styles.
        </p>
        <Breakpoint
          width={MobileBreakpoint}
          screenSizeName="Mobile"
          breakpointName="MobileBreakpoint"
        />
        <Breakpoint
          width={MidsizeBreakpoint}
          screenSizeName="Midsize"
          breakpointName="MidsizeBreakpoint"
        />
        <Breakpoint
          width={MidsizeWideBreakpoint}
          screenSizeName="MidsizeWide"
          breakpointName="MidsizeWideBreakpoint"
        />
        <Breakpoint
          width={WideBreakpoint}
          screenSizeName="Wide"
          breakpointName="WideBreakpoint"
        />
      </div>
    </div>
  );
};

const Breakpoint: React.FC<{
  width: number;
  screenSizeName: string;
  breakpointName: string;
}> = ({ width, breakpointName, screenSizeName }) => {
  return (
    <div className={styles.breakpoint}>
      <h4>{screenSizeName}</h4>
      <div className={styles.breakpointContainer}>
        <div
          style={{ width: `${width}px` }}
          className={styles.breakpointLine}
        />
        <span className={styles.breakpointLabel}>
          {width}px &quot;{breakpointName}&quot;
        </span>
      </div>
    </div>
  );
};

export default StyleGuidePage;
