import classNames from 'classnames';
import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './Button.module.css';

export const buttonSizes = ['sm', 'md', 'lg'] as const;
export type ButtonSize = typeof buttonSizes[number];

export const buttonColorVariants = ['std', 'cta'] as const;
export type ButtonColorVariant = typeof buttonColorVariants[number];

interface CustomButtonProps {
  size?: ButtonSize;
  colorVariant?: ButtonColorVariant;
}

export const Button = forwardRef<
  HTMLButtonElement,
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
    CustomButtonProps
>(function Button(props, ref) {
  const { size, colorVariant, ...htmlButtonProps } = props;
  return (
    <button
      {...{ ref }}
      {...htmlButtonProps}
      className={classNames(styles.Button, props.className, {
        [styles.sm]: size === 'sm',
        [styles.md]: size === 'md' || !size, // default
        [styles.lg]: size === 'lg',
        [styles.std]: colorVariant === 'std' || !colorVariant, // default
        [styles.cta]: colorVariant === 'cta'
      })}
    />
  );
});

type LinkButtonProps = LinkProps<unknown> &
  React.RefAttributes<HTMLAnchorElement> & {
    disabled?: boolean;
  } & CustomButtonProps;

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { size, colorVariant, disabled, ...htmlLinkProps } = props;
  return (
    <Link
      {...htmlLinkProps}
      className={classNames(styles.LinkButton, props.className, {
        [styles.disabled]: disabled,
        [styles.sm]: size === 'sm',
        [styles.md]: size === 'md' || !size, // default
        [styles.lg]: size === 'lg',
        [styles.std]: colorVariant === 'std' || !colorVariant, // default
        [styles.cta]: colorVariant === 'cta'
      })}
    />
  );
};

export const ButtonLink = forwardRef<
  HTMLButtonElement,
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>(function ButtonLink(props, ref) {
  return (
    <button
      {...{ ref }}
      {...props}
      className={classNames(styles.ButtonLink, props.className)}
    />
  );
});
