import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { RoutedApp } from './RoutedApp';

/**
 * Entrypoint to everything. In underlying components will decide whether to show the logged in
 * webapp experience or the unauthenticated experience.
 */
const App: React.FC = () => {
  return (
    <Router>
      <RoutedApp />
    </Router>
  );
};

export default App;
