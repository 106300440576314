import isNumber from 'predicates/number';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';

import LoaderOverlay from '../../general/LoaderOverlay';
import {
  Answer,
  CoursesService,
  FullSection,
  Interstitial,
  InterstitialsService,
  Question,
  UserAnswer,
  UserAnswersService,
  UserProgress
} from '../../general/ServerClient';
import {
  getNowUTCISOString,
  isDefined,
  PageLevelComponent
} from '../../general/util';
import NotFoundPage from '../../NotFoundPage';
import { PathParamsReader } from '../components/PathParamsReader';
import { useGetRetryFetch } from '../page/Auth';
import { BackDetails } from '../page/PageLayout';
import {
  BIRTH_PLAN_COURSE_ID,
  BIRTH_PREP_COURSE_ID,
  useAppContext
} from '../page/WebappContext';
import { WebappUrls } from '../page/WebappUrls';
import { load } from '../util/RemoteData';
import { QuestionPageLoaded } from './QuestionPageLoaded';

interface AnswerStatus {
  answer: Answer;
  userAnswer: UserAnswer | null;
  pendingRemoval: boolean;
}
type AnswerStatuses = { [answerId: number]: AnswerStatus };

/**
 * Page level component displaying a particular question in a section.
 * If question contains an interstitial, displays a teaser which opens a dialog.
 * Allows updating user answers to the question.
 */
export const QuestionPage: PageLevelComponent = () => {
  return (
    <PathParamsReader params={{ sectionId: 'int', questionId: 'int' }}>
      {({ sectionId, questionId }) => {
        const {
          fullSection,
          sectionAnswers,
          birthPrepCourseProgress,
          birthPlanCourseProgress
        } = useAppContext();
        const [question, setQuestion] = useState<Question | undefined>(
          undefined
        );
        const [answerStatuses, setAnswerStatuses] = useState<
          AnswerStatuses | undefined
        >(undefined);
        const [interstitial, setInterstitial] = useState<
          Interstitial | null | undefined
        >(undefined);
        const [viewedInterstitial, setViewedInterstitial] = useState<
          boolean | undefined
        >(undefined);
        const retryFetchFullSection = useGetRetryFetch<FullSection>();
        const retryFetchSectionAnswers = useGetRetryFetch<UserAnswer[]>();
        const retryFetchUserProgress = useGetRetryFetch<UserProgress>();
        // first, load the full section based off the section id
        //   and all user answers for this section
        useEffect(() => {
          load(
            fullSection,
            () =>
              retryFetchFullSection(() =>
                CoursesService.coursesSectionsRetrieve(sectionId)
              ),
            { sectionId }
          );
          load(
            sectionAnswers,
            () =>
              retryFetchSectionAnswers(() =>
                CoursesService.coursesSectionsUserAnswersList(sectionId)
              ),
            { sectionId }
          );
        }, [sectionId]);
        // then grab the full question from the full section data based off the question id
        useEffect(() => {
          if (fullSection.data) {
            setQuestion(
              fullSection.data.questions.find(
                (question) => question.id === questionId
              )
            );
          }
        }, [fullSection.data]);
        // when the question and section answers are available, initialize our tracking of answers / user answers
        useEffect(() => {
          if (question && sectionAnswers.data !== undefined) {
            const newAnswerStatuses: AnswerStatuses = {};
            question.answers.forEach((answer) => {
              newAnswerStatuses[answer.id] = {
                answer,
                userAnswer:
                  sectionAnswers.data?.find((ua) => ua.answer === answer.id) ||
                  null,
                pendingRemoval: false
              };
            });
            setAnswerStatuses(newAnswerStatuses);
          }
        }, [question, sectionAnswers.data]);
        // get whether user has viewed the interstitial associated with the question
        useEffect(() => {
          if (question) {
            const firstInterstitial = question.interstitials[0];
            setInterstitial(firstInterstitial ? firstInterstitial : null);
            if (firstInterstitial) {
              InterstitialsService.interstitialsViewedRetrieve(
                firstInterstitial.id
              ).then((status) => {
                setViewedInterstitial(status.viewed);
              });
            }
          }
        }, [question]);
        const isBirthPlanCourse = location.pathname.includes('plan');
        const updateUserProgress = () => {
          // load both course's progress again, because some questions are included in multiple sections
          load(birthPrepCourseProgress, () =>
            retryFetchUserProgress(() =>
              CoursesService.coursesUserProgressRetrieve(BIRTH_PREP_COURSE_ID)
            )
          );
          load(birthPlanCourseProgress, () =>
            retryFetchUserProgress(() =>
              CoursesService.coursesUserProgressRetrieve(BIRTH_PLAN_COURSE_ID)
            )
          );
        };
        const onAnswer = (answerId: number) => {
          if (question && answerStatuses !== undefined) {
            let userAnswersToDelete: number[] = [];
            if (!question.multi_select) {
              // delete all existing answers
              // NB samgqroberts 2021-11-27 this really ought to be done by the server automatically...
              userAnswersToDelete = Object.entries(answerStatuses)
                .filter(([aid]) => {
                  return String(answerId) !== aid;
                })
                .map(([, status]) => {
                  const uaId = status.userAnswer?.id;
                  return isNumber(uaId) && uaId > 0 ? uaId : undefined;
                })
                .filter(isDefined);
            }
            userAnswersToDelete.forEach((uaId) => {
              UserAnswersService.userAnswersDestroy(uaId);
            });
            // create new answer
            const nowString = getNowUTCISOString();
            const optimisticUserAnswer: UserAnswer = {
              id: -1,
              created_date: nowString,
              answer: answerId,
              is_active: true,
              modified_date: nowString,
              question: question.id,
              user: -1
            };
            UserAnswersService.userAnswersCreate({
              answer: answerId
            }).then((ua) => {
              updateUserProgress();
              setAnswerStatuses((current) => {
                if (!current) return current;
                const status = Object.entries(current).find(
                  ([aid]) => String(answerId) === aid
                )?.[1];
                if (status?.pendingRemoval) {
                  UserAnswersService.userAnswersDestroy(ua.id);
                  return Object.fromEntries(
                    Object.entries(current).map(([aid, status]) => {
                      if (String(answerId) === aid) {
                        return [aid, { ...status, pendingRemoval: false }];
                      }
                      return [aid, status];
                    })
                  );
                }
                return Object.fromEntries(
                  Object.entries(current).map(([aid, status]) => {
                    if (aid === String(answerId)) {
                      if (status.userAnswer?.id === optimisticUserAnswer.id) {
                        return [aid, { ...status, userAnswer: ua }];
                      }
                    }
                    return [aid, status];
                  })
                );
              });
            });
            // optimistic update
            setAnswerStatuses((current) => {
              if (!current) return current;
              return Object.fromEntries(
                Object.entries(current).map(([aid, status]) => {
                  if (aid === String(answerId)) {
                    return [
                      aid,
                      { ...status, userAnswer: optimisticUserAnswer }
                    ];
                  }
                  const uaId = status.userAnswer?.id;
                  if (uaId && userAnswersToDelete.includes(uaId)) {
                    return [aid, { ...status, userAnswer: null }];
                  }
                  return [aid, status];
                })
              );
            });
          }
        };
        const onRemoveAnswer = (answerId: number) => {
          // delete on server
          const status =
            answerStatuses &&
            Object.entries(answerStatuses).find(
              ([aid]) => String(answerId) === aid
            );
          const uaId = status?.[1].userAnswer?.id;
          if (isNumber(uaId) && uaId > 0) {
            UserAnswersService.userAnswersDestroy(uaId).then(() => {
              updateUserProgress();
            });
          }
          // optimistic update
          setAnswerStatuses((current) => {
            if (!current) return current;
            return Object.fromEntries(
              Object.entries(current).map(([aid, status]) => {
                if (aid === String(answerId)) {
                  return [
                    aid,
                    { ...status, userAnswer: null, pendingRemoval: true }
                  ];
                }
                return [aid, status];
              })
            );
          });
        };
        const onViewInterstitial = (interstitialId: number): void => {
          InterstitialsService.interstitialsViewedCreate(interstitialId);
        };
        const back: BackDetails = {
          label: `Back To ${fullSection.data?.title || ''}`,
          destination: generatePath(
            isBirthPlanCourse
              ? WebappUrls.planSection
              : WebappUrls.learnSection,
            {
              sectionId: fullSection.data?.id || 0
            }
          )
        };
        return !fullSection.loading &&
          !sectionAnswers.loading &&
          !question &&
          answerStatuses === undefined ? (
          <NotFoundPage />
        ) : (
          <>
            {question &&
              answerStatuses !== undefined &&
              interstitial !== undefined &&
              (interstitial === null || viewedInterstitial !== undefined) && (
                <QuestionPageLoaded
                  {...{
                    question,
                    userAnswers: Object.entries(answerStatuses)
                      .map(([, status]) => {
                        return status.userAnswer || undefined;
                      })
                      .filter(isDefined),
                    interstitial,
                    viewedInterstitial,
                    onAnswer,
                    onRemoveAnswer,
                    onViewInterstitial,
                    back
                  }}
                />
              )}
            {(fullSection.loading || sectionAnswers.loading) && (
              <LoaderOverlay />
            )}
          </>
        );
      }}
    </PathParamsReader>
  );
};
