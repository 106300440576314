import { styled } from '@mui/material/styles';
import React from 'react';

import Divider from '../../general/Divider';
import { TitleAndSubtitle } from '../components/HeaderAndSubtitle';
import { SectionAndProgress } from '../util/util';
import { SectionItem } from './SectionItem';

const Root = styled('div')({
  margin: 20
});
const StyledDivider = styled(Divider)({
  borderColor: 'black',
  marginBottom: 0
});

/**
 * Renders a list of Sections in a Course.
 */
export const SectionList: React.FC<{
  sections: SectionAndProgress[];
  generatePath: (sectionId: number) => string;
}> = ({ sections, generatePath }) => {
  const totalSections = sections.length;
  const numCompleted = sections.reduce((acc, el) => {
    if (el.progress.overall.percent_completed >= 100) return acc + 1;
    return acc;
  }, 0);
  return (
    <Root>
      <TitleAndSubtitle
        title="All Sections"
        subtitle={`${numCompleted} of ${totalSections} completed`}
      />
      <StyledDivider />
      {sections.map(({ section, progress }, index) => {
        const key = section.id;
        const path = generatePath(section.id);
        return (
          <SectionItem key={key} {...{ section, index, progress, path }} />
        );
      })}
    </Root>
  );
};
