import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import { isDefined } from '../../general/util';
import { H7 } from '../../StyleGuide/Text';
import { useAppContext } from '../page/WebappContext';
import { useOnboardingContext } from './OnboardingContext';
import { Options } from './Options';
import { Progress } from './Progress';

const StyledProgress = styled(Progress)({
  marginTop: 25
});

/**
 * A stage in the Onboarding flow.
 */
export const BirthTypeStage: React.FC = () => {
  const { setStage, setNextText, setNextAction, setBirthTypeId } =
    useOnboardingContext();
  const { options } = useAppContext();
  const [bt, setBT] = useState<number | undefined>(undefined);
  useEffect(() => setNextText('Next'), []);
  useEffect(
    () =>
      setNextAction(
        bt === undefined
          ? undefined
          : () => {
              setBirthTypeId(bt);
              setStage('birthlocation');
            }
      ),
    [bt]
  );
  // TODO samgqroberts 2021-12-10 should 'request' this data, and context should detect it's already been loaded and
  //      just serve the loaded one.
  //      can extend this to cache in localstorage as well.
  if (!options.data) {
    console.error('Options not loaded');
    return null;
  }
  // TODO samgqoberts 2021-12-12 add VBAC explanation
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledProgress progress={50} />
      <H7>What type of birth are you planning or hoping for?</H7>
      <Options<number>
        options={options.data.birth_types.map((bt) => ({
          id: bt.id,
          name: bt.name
        }))}
        selected={[bt].filter(isDefined)}
        onSelect={(id) => setBT(id)}
        onDeselect={() => {}}
      />
    </div>
  );
};
