import { createContext, useContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export interface MobileContextState {
  isMobile: boolean;
  setIsMobile: (value: boolean) => void;
}

const initialMobileContextState: MobileContextState = {
  isMobile: false,
  setIsMobile: noop
};

export const MobileContext = createContext<MobileContextState>(
  initialMobileContextState
);

export const MobileProvider: React.FC = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  return (
    <MobileContext.Provider
      value={{
        isMobile,
        setIsMobile
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};

export const useMobileContext = (): MobileContextState =>
  useContext(MobileContext);
