import React, { forwardRef, SVGProps } from 'react';

/**
 * Google Material icon encoded as a react `<svg />`
 *
 * source: https://material.io/resources/icons/?icon=expand_more&style=baseline
 */
const ExpandMoreIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  function ExpandMoreIcon(props, ref) {
    return (
      <svg
        {...{ ref }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </svg>
    );
  }
);
export default ExpandMoreIcon;
