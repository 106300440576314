import React, { forwardRef, SVGProps } from 'react';

/**
 * Google Material icon encoded as a react `<svg />`
 *
 * source: https://material.io/resources/icons/?icon=mail_outline&style=baseline
 */
const MailOutlineIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  function MailOutlineIcon(props, ref) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="2 2 20 20"
        fill="black"
        width="18px"
        height="18px"
        {...{ ref }}
        {...props}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z" />
      </svg>
    );
  }
);
export default MailOutlineIcon;
