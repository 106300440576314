import {
  Course,
  SectionProgress,
  TerseSection,
  UserProgress
} from '../../general/ServerClient';
import { isDefined } from '../../general/util';

export interface SectionAndProgress {
  section: TerseSection;
  progress: SectionProgress;
}

export function combineSectionsAndProgress(
  course: Course,
  userProgress: UserProgress
): SectionAndProgress[] {
  return course.sections
    .map((section) => {
      const progress = userProgress.sections[section.id];
      if (!progress) return undefined;
      return { section, progress };
    })
    .filter(isDefined);
}
