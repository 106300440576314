import isNumber from 'predicates/number';
import { useEffect, useState } from 'react';

import { UserInfo } from '../MembershipPlansPage/Plans';
import { useLocalStorage } from '../webapp/util/useLocalStorage';
import { fmap, parseStringAsFloat } from './util';
import { getMessageHandler } from './webkit';

function parseUserInfoFromIos(userInfo: unknown): UserInfo | null {
  if (userInfo === null || typeof userInfo !== 'object') {
    console.error(
      'Could not parse user info from iOS - passed value is not an object'
    );
    return null;
  }
  const userInfo2 = userInfo as { [key: string]: unknown };
  if (!('email' in userInfo2)) {
    console.error(
      "Could not parse user info from iOS - passed object does not contain 'email' key"
    );
    return null;
  }
  const userInfo3 = userInfo2 as { email: unknown; [key: string]: unknown };
  const email = userInfo3.email;
  if (typeof email !== 'string') {
    console.error(
      "Could not parse user info from iOS - passed object has non-string 'email' value"
    );
    return null;
  }
  const firstName =
    'firstName' in userInfo3 && userInfo3.firstName
      ? String(userInfo3.firstName)
      : null;
  const lastName =
    'lastName' in userInfo3 && userInfo3.lastName
      ? String(userInfo3.lastName)
      : null;
  const currentPlan =
    'currentPlan' in userInfo3 && userInfo3.currentPlan
      ? String(userInfo3.currentPlan)
      : null;
  return { email, firstName, lastName, currentPlan };
}

export const INIT_CHECKOUT_MESSAGE_HANDLER_NAME = 'initCheckoutHandler';

function getUserInfoFromIos(): Promise<UserInfo> {
  return new Promise<UserInfo>((resolve, reject) => {
    // if the round-trip communication cycle doesn't happen in 3 seconds, report an error
    setTimeout(() => {
      reject('Did not receive communication from iOS within 3000ms');
    }, 3000);
    // set up callback on window
    window.soshewkutils = {
      passUserInfo: (rawUserInfo) => {
        const userInfo = parseUserInfoFromIos(rawUserInfo);
        if (userInfo) {
          resolve(userInfo);
        } else {
          reject(
            'User info was attempted to be passed from iOS but could not parse'
          );
        }
      }
    };
    // alert iOS that we're ready to accept information
    const messageHandler = getMessageHandler(
      INIT_CHECKOUT_MESSAGE_HANDLER_NAME
    );
    if (messageHandler) {
      messageHandler.postMessage({
        status: 'Ready for user info.',
        functionName: 'window.soshewkutils.passUserInfo'
      });
    } else {
      reject(
        'Could not get handle to iOS messageHandler to request user information'
      );
    }
    // at this point, iOS will receive the message and should respond by calling the callback
    //   function with the proper user information
  });
}

export const USER_EMAIL_LOCALSTORAGE_KEY = 'USER_EMAIL';
export const USER_FIRST_NAME_LOCALSTORAGE_KEY = 'USER_FIRST_NAME';
export const USER_LAST_NAME_LOCALSTORAGE_KEY = 'USER_LAST_NAME';
export const USER_CURRENT_PLAN_LOCALSTORAGE_KEY = 'USER_CURRENT_PLAN';
export const USER_PRODUCT_NAME_LOCALSTORAGE_KEY = 'USER_PRODUCT_NAME';

export type UserInfoAndProductName = UserInfo & { productName: string | null };

export function useUserInfo(retrieveFromIos?: boolean): {
  userInfo: UserInfoAndProductName;
  loadingUserInfo: boolean;
  error: boolean;
  setEmail: (email: string | undefined) => void;
  setProductName: (productName: string | undefined) => void;
} {
  const [email, setEmail] = useLocalStorage(
    USER_EMAIL_LOCALSTORAGE_KEY,
    undefined,
    retrieveFromIos
  );
  const [firstName, setFirstName] = useLocalStorage(
    USER_FIRST_NAME_LOCALSTORAGE_KEY,
    undefined,
    retrieveFromIos
  );
  const [lastName, setLastName] = useLocalStorage(
    USER_LAST_NAME_LOCALSTORAGE_KEY,
    undefined,
    retrieveFromIos
  );
  const [currentPlan, setCurrentPlan] = useLocalStorage(
    USER_CURRENT_PLAN_LOCALSTORAGE_KEY,
    undefined,
    retrieveFromIos
  );
  const [productName, setProductName] = useLocalStorage(
    USER_PRODUCT_NAME_LOCALSTORAGE_KEY,
    undefined,
    retrieveFromIos
  );
  const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>(
    !!retrieveFromIos
  );
  const [error, setError] = useState<boolean>(false);
  useEffect(() => {
    if (retrieveFromIos) {
      setLoadingUserInfo(true);
      getUserInfoFromIos()
        .then(({ email, firstName, lastName, currentPlan }) => {
          setEmail(email || undefined);
          setFirstName(firstName || undefined);
          setLastName(lastName || undefined);
          setCurrentPlan(currentPlan || undefined);
        })
        .catch((reason) => {
          console.error(reason);
          setError(true);
        })
        .finally(() => setLoadingUserInfo(false));
    }
  }, []);
  return {
    userInfo: {
      email: email || null,
      firstName: firstName || null,
      lastName: lastName || null,
      currentPlan: currentPlan || null,
      productName: productName || null
    },
    loadingUserInfo,
    error,
    setEmail,
    setProductName
  };
}

export interface CheckoutInfo {
  couponUsed: string | null;
  pricePaid: number | null;
}

export function useCheckoutInfo(wipe?: boolean): {
  checkoutInfo: CheckoutInfo;
  setCouponUsed: (couponUsed: string | undefined) => void;
  setPricePaid: (pricePaid: number | undefined) => void;
} {
  const [couponUsed, setCouponUsed] = useLocalStorage(
    CHECKOUT_COUPON_USED_LOCALSTORAGE_KEY,
    undefined,
    wipe
  );
  const [pricePaid, setPricePaid] = useLocalStorage(
    CHECKOUT_PRICE_PAID_LOCALSTORAGE_KEY,
    undefined,
    wipe
  );
  return {
    checkoutInfo: {
      couponUsed: couponUsed || null,
      pricePaid: fmap(pricePaid, parseStringAsFloat) || null
    },
    setCouponUsed,
    setPricePaid: (pricePaid: number | undefined) => {
      if (isNumber(pricePaid)) return setPricePaid(String(pricePaid));
      return setPricePaid(undefined);
    }
  };
}

export const CHECKOUT_COUPON_USED_LOCALSTORAGE_KEY = 'USER_COUPON_USED';
export const CHECKOUT_PRICE_PAID_LOCALSTORAGE_KEY = 'USER_PRICE_PAID';
