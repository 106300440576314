import React, { useEffect } from 'react';

import PageUrls from '../../general/Routing';
import { eraseToken } from './Auth';

/**
 * View for if the user's refresh token has expired, or otherwise the user has been detected
 *   to have been logged out.
 * If the user initiates the logout, user should ideally not be taken here.
 */
export const LoggedOut: React.FC = () => {
  // no matter how the logged out page was reached, ensure that the refresh token is not stored
  //   in localstorage.
  // if it continues to be stored somehow, the user could encounter a situation where refreshes
  //   simply land them back here without an escape route.
  useEffect(() => {
    eraseToken();
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        marginTop: 200
      }}
    >
      You have been logged out. Please&nbsp;
      <a href={PageUrls.home}>log in again</a>.
    </div>
  );
};
