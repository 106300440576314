import PageUrls from '../general/Routing';
import { PageLevelComponent } from '../general/util';
import { LinkButton } from '../StyleGuide/Button';
import { H5, H7 } from '../StyleGuide/Text';
import styles from './NotFoundPage.module.css';

export const NotFoundPage: PageLevelComponent = () => {
  return (
    <div className={styles.page}>
      <H5 className={styles.header}>Oops! Page not found.</H5>
      <H7 className={styles.parenthetical}>(404)</H7>
      <img className={styles.image} src="/404.jpg" alt="Page not found" />
      <LinkButton to={PageUrls.home} className={styles.link}>
        Go to Homepage
      </LinkButton>
    </div>
  );
};
export default NotFoundPage;
