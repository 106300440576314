import { styled } from '@mui/material/styles';
import React from 'react';

import Divider from '../../general/Divider';
import {
  BirthPlanSectionEnum,
  FullSection,
  SectionProgress,
  UserAnswer
} from '../../general/ServerClient';
import { mobileMediaQuery } from '../../StyleGuide/Breakpoints';
import { White } from '../../StyleGuide/Colors';
import { H3 } from '../../StyleGuide/Text';
import { OverImageProgress } from '../components/OverImageProgress';
import { BackDetails, PageLayout } from '../page/PageLayout';
import { useUserHasAccessToBirthClass } from '../page/WebappContext';
import { Collapsible } from './Collapsible';
import { SECTION_INTERNAL_PADDING } from './constants';
import { ContentBlocks } from './ContentBlocks';
import { LessonItem } from './LessonItem';
import { QuestionItem } from './QuestionItem';

const Header = styled('div')({
  width: '100%',
  position: 'relative'
});
const HeaderImg = styled('img')({
  width: '100%',
  display: 'block'
});
const Gradient = styled('div')({
  position: 'absolute',
  top: '40%',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundImage: 'linear-gradient(transparent, black)',
  opacity: 0.7
});
const Metadata = styled('div')({
  color: White,
  position: 'absolute',
  left: SECTION_INTERNAL_PADDING,
  bottom: 36,
  right: SECTION_INTERNAL_PADDING,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [mobileMediaQuery]: {
    bottom: 10
  }
});
const SectionTitle = styled(H3)({
  margin: 0,
  fontSize: 40,
  [mobileMediaQuery]: {
    fontSize: 24
  }
});

/**
 * Page level component displaying information about a section.
 * Information within a section that this page displays includes video "LessonItem"s,
 *   Questions, and reading list "ContentBlock"s.
 */
export const SectionPage: React.FC<{
  section: FullSection;
  progress: SectionProgress;
  userAnswers: UserAnswer[];
  generateQuestionPath: (params: {
    sectionId: number;
    questionId: number;
  }) => string;
  back: BackDetails;
  onUserWatchedVideo: (videoId: number) => void;
  isBirthClass: boolean;
}> = ({
  section,
  progress,
  userAnswers,
  generateQuestionPath,
  back,
  onUserWatchedVideo,
  isBirthClass
}) => {
  const userHasAccessToBirthClass = useUserHasAccessToBirthClass();
  const birthPlanQuestions = section.questions.filter(
    (q) =>
      q.birth_plan_section && q.birth_plan_section !== BirthPlanSectionEnum.NO
  );
  const nonBirthPlanQuestions = section.questions.filter(
    (q) =>
      !q.birth_plan_section || q.birth_plan_section === BirthPlanSectionEnum.NO
  );
  const birthPlanQuestionsAndAnswers = birthPlanQuestions.map((question) => {
    const thisUserAnswers = userAnswers.filter(
      (a) => a.question === question.id
    );
    const answers = question.answers.filter((a) =>
      thisUserAnswers.find((ua) => ua.answer === a.id)
    );
    return { question, answers };
  });
  const nonBirthPlanQuestionsAndAnswer = nonBirthPlanQuestions.map(
    (question) => {
      const thisUserAnswers = userAnswers.filter(
        (a) => a.question === question.id
      );
      const answers = question.answers.filter((a) =>
        thisUserAnswers.find((ua) => ua.answer === a.id)
      );
      return { question, answers };
    }
  );
  const birthPlanQuestionsCompleted = birthPlanQuestionsAndAnswers.filter(
    ({ answers }) => answers.length > 0
  ).length;
  const birthPlanQuestionsTotal = birthPlanQuestionsAndAnswers.length;
  const nonBirthPlanQuestionsCompleted = nonBirthPlanQuestionsAndAnswer.filter(
    ({ answers }) => answers.length > 0
  ).length;
  const nonBirthPlanQuestionsTotal = nonBirthPlanQuestionsAndAnswer.length;
  const lockedBehindPaywall = isBirthClass && !userHasAccessToBirthClass;
  return (
    <PageLayout {...{ back }}>
      <Header>
        <HeaderImg src={section.image_2x} alt="Section" />
        <Gradient />
        <Metadata>
          <SectionTitle>{section.title}</SectionTitle>
          <OverImageProgress value={progress.overall.percent_completed} />
        </Metadata>
      </Header>
      {section.videos.length > 0 && (
        <Collapsible
          title="Lessons"
          subtitle={`${progress.videos.completed} of ${progress.videos.total} complete`}
          moreSpaceBelow={false}
        >
          <div>
            {section.videos.map((video, i) => {
              return (
                <React.Fragment key={video.id}>
                  <LessonItem
                    {...{ video, onUserWatchedVideo }}
                    lockedBehindPaywall={lockedBehindPaywall}
                  />
                  {i < section.videos.length - 1 && (
                    <Divider style={{ margin: '11px auto' }} />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </Collapsible>
      )}
      {birthPlanQuestionsAndAnswers.length > 0 && (
        <Collapsible
          title="My Birth Plan"
          subtitle={`${birthPlanQuestionsCompleted} of ${birthPlanQuestionsTotal} complete`}
          moreSpaceBelow
          showStar
        >
          <div>
            {birthPlanQuestionsAndAnswers.map(({ question, answers }, i) => {
              const path = generateQuestionPath({
                sectionId: section.id,
                questionId: question.id
              });
              return (
                <QuestionItem
                  key={question.id}
                  {...{ question, answers, path }}
                  showDivider={i < birthPlanQuestionsAndAnswers.length - 1}
                  showStar
                  lockedBehindPaywall={false}
                />
              );
            })}
          </div>
        </Collapsible>
      )}
      {nonBirthPlanQuestionsAndAnswer.length > 0 && (
        <Collapsible
          title="More Topics & Preferences"
          subtitle={`${nonBirthPlanQuestionsCompleted} of ${nonBirthPlanQuestionsTotal} complete`}
          moreSpaceBelow
        >
          <div>
            {nonBirthPlanQuestionsAndAnswer.map(({ question, answers }, i) => {
              const path = generateQuestionPath({
                sectionId: section.id,
                questionId: question.id
              });
              return (
                <QuestionItem
                  key={question.id}
                  {...{ question, answers, path }}
                  showDivider={i < nonBirthPlanQuestionsAndAnswer.length - 1}
                  lockedBehindPaywall={lockedBehindPaywall}
                />
              );
            })}
          </div>
        </Collapsible>
      )}
      {section.content_blocks.length > 0 && (
        <Collapsible
          title="Reading List"
          subtitle="Optional"
          moreSpaceBelow={false}
        >
          <ContentBlocks
            sectionId={section.id}
            contentBlocks={section.content_blocks}
            lockedBehindPaywall={lockedBehindPaywall}
          />
        </Collapsible>
      )}
    </PageLayout>
  );
};
