import React from 'react';

import { AuthProvider } from './page/Auth';
import { EnsureAuthed } from './page/EnsureAuthed';
import { WebappProvider } from './page/WebappContext';

/**
 * Entrypoint for the logged-in webapp treatment.
 */
export const Webapp: React.FC = () => {
  return (
    <AuthProvider>
      <WebappProvider>
        <EnsureAuthed />
      </WebappProvider>
    </AuthProvider>
  );
};
