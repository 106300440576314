import * as is from 'predicates';
import { fail, ok, Result } from 'typescript-monads';

interface AppEnvConfigFieldString {
  type: 'string';
  required: boolean;
}

interface AppEnvConfigFieldBoolean {
  type: 'boolean';
  required: boolean;
}

type AppEnvConfigField = AppEnvConfigFieldString | AppEnvConfigFieldBoolean;

type AbstractAppEnvConfig = { [K: string]: AppEnvConfigField };

type AppEnvFromConfig<T extends AbstractAppEnvConfig> = {
  [K in keyof T]: T[K] extends AppEnvConfigFieldString
    ? string
    : T[K] extends AppEnvConfigFieldBoolean
    ? boolean
    : never;
};

interface ConfigField {
  (type: 'string', required: boolean): AppEnvConfigFieldString;
  (type: 'boolean', required: boolean): AppEnvConfigFieldBoolean;
}

// @ts-expect-error TODO
const configField: ConfigField = (
  type: 'string' | 'boolean',
  required: boolean
): AppEnvConfigFieldString | AppEnvConfigFieldBoolean => {
  if (type === 'string') {
    return { type, required } as AppEnvConfigFieldString;
  }
  if (type === 'boolean') {
    return { type, required } as AppEnvConfigFieldBoolean;
  }
  throw new Error(`Unknown type specified: ${type}`);
};

const appEnvConfig = {
  SERVER_BASE_URL: configField('string', true),
  STRIPE_CLIENT_API_KEY: configField('string', true),
  GTM_CONTAINER_ID: configField('string', false),
  SHOW_DEV_RESOURCES: configField('boolean', false)
};

type AppEnv = AppEnvFromConfig<typeof appEnvConfig>;

function loadEnv(): Result<AppEnv, string[]> {
  const fieldNames = Object.keys(appEnvConfig) as (keyof typeof appEnvConfig)[];
  const [loadedEnv, errors] = fieldNames.reduce<[Partial<AppEnv>, string[]]>(
    (acc, el) => {
      const { type, required } = appEnvConfig[el];
      const raw = process.env[`REACT_APP_${el}`];
      if ((!is.string(raw) || (is.string(raw) && !raw)) && required) {
        acc[1].push(`Missing required environment variable ${el}.`);
        return [acc[0], acc[1]];
      }
      if (type === 'string') {
        const value = is.string(raw) ? raw : '';
        // @ts-expect-error TODO
        acc[0][el] = value;
        return [acc[0], acc[1]];
      }
      if (type === 'boolean') {
        // @ts-expect-error TODO
        acc[0][el] = raw === 'true';
        return [acc[0], acc[1]];
      }
      return acc;
    },
    [{}, []]
  );

  if (errors.length) return fail(errors);

  return ok(loadedEnv as AppEnv);
}

// caching mechanism for the loaded environment variables
let loadedEnv: Result<AppEnv, string[]> | undefined = undefined;

/**
 * Returns the requested environment variable, or throws if that variable is unavailable.
 * On first call, will attempt to retrieve environment variables from process.env.
 */
export function appEnv<K extends keyof AppEnv>(key: K): AppEnv[K] {
  if (!loadedEnv) {
    loadedEnv = loadEnv();
  }
  if (loadedEnv.isOk()) {
    return loadedEnv.unwrap()[key];
  }
  throw new Error(loadedEnv.unwrapFail().join(', '));
}
