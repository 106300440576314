import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { mobileMediaQuery } from '../../StyleGuide/Breakpoints';
import { CopperRose, Placeholder } from '../../StyleGuide/Colors';

const Root = styled('div')({
  height: '100%'
});
const NavSection = styled('div')({
  width: 700,
  margin: '0 auto',
  flexShrink: 0,
  height: 44,
  display: 'flex',
  alignItems: 'center',
  [mobileMediaQuery]: {
    width: '100%',
    paddingLeft: 10,
    margin: 0,
    borderBottom: `1px solid ${Placeholder}`
  }
});
const ContentAndNavContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%'
});
const ContentContainer = styled('div')({
  flexGrow: 1,
  width: '100%',
  margin: '0 auto',
  overflowY: 'auto'
});
const Content = styled('div')({
  maxWidth: 700,
  margin: '0 auto'
});
const BackLink = styled(Link)({
  textDecoration: 'none',
  color: CopperRose,
  [mobileMediaQuery]: {
    fontSize: 13
  }
});

export interface BackDetails {
  label: string;
  destination: string;
}

export const PageLayout: React.FC<{
  back?: BackDetails;
  className?: string;
  contentStyle?: React.CSSProperties;
}> = ({ back, className, contentStyle, children }) => {
  return (
    <Root className={classNames(className)}>
      <ContentAndNavContainer>
        {back && (
          <NavSection>
            <BackLink to={back.destination}>
              {'<'} {back.label}
            </BackLink>
          </NavSection>
        )}
        <ContentContainer>
          <Content style={contentStyle}>{children}</Content>
        </ContentContainer>
      </ContentAndNavContainer>
    </Root>
  );
};
