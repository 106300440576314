import { styled } from '@mui/material/styles';
import React from 'react';
import Modal from 'react-modal';
import { Redirect } from 'react-router';
import { Route, useLocation } from 'react-router-dom';

import PageUrls from '../../general/Routing';
import useIsMobile from '../../general/useIsMobile';
import { MobileBreakpoint } from '../../StyleGuide/Breakpoints';
import { PlanRoutes } from '../BirthPlanPage/PlanRoutes';
import { LearnRoutes } from '../LearnPage/LearnRoutes';
import { ProfilePage } from '../ProfilePage/ProfilePage';
import { SharePage } from '../SharePage/SharePage';
import { useLoggedOut } from './Auth';
import { BottomNav, Tab } from './BottomNav';
import { CONTENT_CONTAINER_ELEMENT_ID } from './constants';
import { LeftNav } from './LeftNav';
import { LoggedOut } from './LoggedOut';
import { RouteSwitcher } from './RouteSwitcher';
import { WebappUrls } from './WebappUrls';

const PREFIX = 'Webapp';

const classes = {
  webapp: `${PREFIX}-webapp`,
  contentContainer: `${PREFIX}-contentContainer`,
  content: `${PREFIX}-content`
};

const Root = styled('div')({
  [`&.${classes.webapp}`]: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    [`@media (min-width: ${MobileBreakpoint}px)`]: {
      flexDirection: 'row'
    }
  },
  [`& .${classes.contentContainer}`]: {
    flex: 1,
    overflow: 'hidden',
    position: 'relative'
  }
});

/**
 * The content of the webapp, expected to be mounted only after user details and a valid birth plan
 * has been loaded (if not, user should go to Onboarding instead).
 */
export const WebappContent: React.FC = () => {
  const isMobile = useIsMobile();
  const loggedOut = useLoggedOut();
  const { pathname } = useLocation();

  const selected: Tab | null =
    pathname === '/' || pathname.startsWith('/learn')
      ? 'learn'
      : pathname.startsWith(WebappUrls.birthPlan)
      ? 'birthplan'
      : pathname.startsWith(WebappUrls.share)
      ? 'share'
      : pathname.startsWith(WebappUrls.profile)
      ? 'profile'
      : null;

  return (
    <Root className={classes.webapp}>
      {!isMobile && <LeftNav {...{ selected }} />}
      <div
        className={classes.contentContainer}
        id={CONTENT_CONTAINER_ELEMENT_ID}
      >
        <RouteSwitcher>
          <Route exact path={WebappUrls.home}>
            <LearnRoutes />
          </Route>
          <Route exact path={PageUrls.membership}>
            <Redirect to={WebappUrls.upgrade} />
          </Route>
          <Route path={WebappUrls.learn}>
            <LearnRoutes />
          </Route>
          <Route path={WebappUrls.birthPlan}>
            <PlanRoutes />
          </Route>
          <Route path={WebappUrls.share}>
            <SharePage />
          </Route>
          <Route path={WebappUrls.profile}>
            <ProfilePage />
          </Route>
        </RouteSwitcher>
      </div>
      {isMobile && <BottomNav {...{ selected }} />}
      {/* TODO samgqroberts 2022-03-02 remove react-modal, prefer mui dialog */}
      <Modal
        isOpen={loggedOut}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '400px',
            padding: '40px',
            boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
            border: 'none',
            borderRadius: '12px'
          },
          overlay: {
            zIndex: 3
          }
        }}
      >
        <LoggedOut />
      </Modal>
    </Root>
  );
};
