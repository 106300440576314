import RLS from 'react-loader-spinner';

const Loader: React.FC<{
  height?: number;
  width?: number;
  className?: string;
}> = (props) => {
  return (
    <RLS type="Oval" color="#00BFFF" height={100} width={100} {...props} />
  );
};
export default Loader;
