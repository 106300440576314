import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';

import { Button } from '../../StyleGuide/Button';

const Root = styled('div')({
  '& p': {
    margin: 0
  },
  '& ul': {
    margin: 0,
    whiteSpace: 'normal'
  },
  '& li': {
    padding: '2px 0'
  }
});

const GotItButton = styled(Button)({
  margin: '30px auto 0'
});

/**
 * Renders the content of an interstitial.
 * Content is assumed to be in markdown form, so renders the markdown appropriately.
 */
export const InterstitialContent: React.FC<{
  content: string;
  onClose: () => void;
}> = ({ content, onClose }) => {
  return (
    <Root>
      <ReactMarkdown>{content}</ReactMarkdown>
      <GotItButton onClick={onClose}>Got it</GotItButton>
    </Root>
  );
};
