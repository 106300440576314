import React from 'react';
import { Route } from 'react-router';

import { RouteSwitcher } from '../page/RouteSwitcher';
import { WebappUrls } from '../page/WebappUrls';
import { PlanSectionPage } from '../PlanSectionPage/PlanSectionPage';
import { QuestionPage } from '../QuestionPage/QuestionPage';
import { BirthPlanPage } from './BirthPlanPage';

/**
 * Routes under the /plan url.
 */
export const PlanRoutes: React.FC = () => {
  return (
    <RouteSwitcher>
      <Route exact path={WebappUrls.birthPlan}>
        <BirthPlanPage />
      </Route>
      <Route exact path={WebappUrls.planSection}>
        <PlanSectionPage />
      </Route>
      <Route exact path={WebappUrls.planQuestion}>
        <QuestionPage />
      </Route>
    </RouteSwitcher>
  );
};
