import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from '@stripe/react-stripe-js';
import {
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeElements
} from '@stripe/stripe-js';
import classNames from 'classnames';
import React, { useState } from 'react';

import InternalLink from '../general/InternalLink';
import {
  PaymentsService,
  PriceCheck,
  PriceCheckResponse,
  PurchaseIntent,
  Sku
} from '../general/ServerClient';
import { dataLayerGTM, getProductGTMData } from '../general/tagManager';
import { parseStringAsFloat } from '../general/util';
import { UserInfo } from '../MembershipPlansPage/Plans';
import { Button } from '../StyleGuide/Button';
import inputStyles from '../StyleGuide/Input.module.css';
import { H7, P, Span } from '../StyleGuide/Text';
import styles from './CheckoutForm.module.css';
import OrderSummary from './OrderSummary';

export type PaymentSuccessHandler = ({
  email,
  productName,
  couponUsed,
  pricePaid
}: {
  email: string;
  productName: string;
  couponUsed: string | null;
  pricePaid: number;
}) => void;

function titleCase(str: string): string {
  if (str === '') return str;
  return `${str[0].toLocaleUpperCase()}${str.substr(1)}`;
}
function snakeToSentence(snake: string): string {
  return snake
    .split('_')
    .map((x, i) => (i === 0 ? titleCase(x) : x))
    .join(' ');
}

function toReadableError(e: unknown): string {
  if (typeof e === 'string') {
    return e;
  }
  if (Array.isArray(e)) {
    return e.map(toReadableError).join(', ');
  }
  if (e && typeof e === 'object') {
    return (Object.keys(e) as (keyof typeof e)[])
      .map((k) => `${snakeToSentence(k)}: ${toReadableError(e[k])}`)
      .join(', ');
  }
  return String(e);
}

const CheckoutForm: React.FC<{
  stripe: Stripe;
  elements: StripeElements;
  productName: string;
  userInfo: UserInfo;
  product: Sku | undefined;
  error: string | undefined;
  onPaymentSuccess: PaymentSuccessHandler;
}> = ({
  stripe,
  elements,
  productName,
  userInfo,
  product,
  error: loadingError,
  onPaymentSuccess
}) => {
  const { email, firstName, lastName } = userInfo;
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [error, setError] = useState<React.ReactNode>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [priceCheckResponse, setPriceCheckResponse] = useState<
    PriceCheckResponse | undefined
  >(undefined);
  const applyPromoCode = (promoCode: string) => {
    const priceCheck: PriceCheck = {
      coupon: promoCode,
      product_name: productName
    };
    PaymentsService.paymentsCheckPriceCreate(priceCheck).then((r) =>
      setPriceCheckResponse(r)
    );
  };
  const completelyFreeDueToCoupon =
    priceCheckResponse &&
    parseStringAsFloat(priceCheckResponse.final_price) === 0;

  /* end hooks */

  if (!email) {
    return (
      <P style={{ margin: '20px' }}>
        Cannot check out - do not have the required information (email).{' '}
        <InternalLink to="membership">Choose a plan.</InternalLink>
      </P>
    );
  }

  const errorToShow = error ? error : loadingError ? loadingError : undefined;

  // Surface card errors
  const handleCardNumberChange = async (
    event: StripeCardNumberElementChangeEvent
  ) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };
  const handleCardExpiryChange = async (
    event: StripeCardExpiryElementChangeEvent
  ) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };
  const handleCardCvcChange = async (
    event: StripeCardCvcElementChangeEvent
  ) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const submitPayment = async () => {
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      console.error('Cannot submit payment: no handle on card info components');
      return;
    }
    setProcessing(true);

    const purchaseIntent: PurchaseIntent = {
      email,
      product_name: productName,
      first_name: firstName,
      last_name: lastName,
      coupon: priceCheckResponse?.accepted_coupon?.code
    };

    PaymentsService.paymentsIntentCreate(purchaseIntent)
      .then((purchaseIntentResponse) => {
        if (!purchaseIntentResponse) {
          throw new Error('No payment intent returned from server');
        }
        return stripe
          .confirmCardPayment(purchaseIntentResponse.client_secret, {
            payment_method: {
              card: cardNumberElement
            }
          })
          .then((payload) => ({ payload, purchaseIntentResponse }));
      })
      .then(({ payload, purchaseIntentResponse }) => {
        if (payload.error) {
          setError(`Payment failed: ${payload.error.message}`);
          setProcessing(false);
        } else {
          setError(null);
          setProcessing(false);
          setSucceeded(true);
          const { paymentIntent } = payload;
          const pricePaid = paymentIntent.amount / 100; // amount is in cents, want dollars
          if (product) {
            dataLayerGTM({ ecommerce: null });
            dataLayerGTM({
              event: 'purchase',
              ecommerce: {
                purchase: {
                  actionField: {
                    id: paymentIntent.id,
                    revenue: pricePaid,
                    coupon: priceCheckResponse?.accepted_coupon?.code
                  },
                  products: [getProductGTMData(product)]
                }
              }
            });
          }
          onPaymentSuccess({
            email,
            productName,
            couponUsed: purchaseIntentResponse.accepted_coupon || null,
            pricePaid
          });
        }
      })
      .catch((e) => {
        let errorMessage = e.toString();
        if ('status' in e && 'body' in e) {
          if ('email' in e.body) {
            const msg = e.body.email;
            delete e.body.email;
            errorMessage = toReadableError({ ...e.body, [`'${email}'`]: msg });
          } else {
            errorMessage = toReadableError(e.body);
          }
        }
        setError(`Payment failed: ${errorMessage}`);
        setProcessing(false);
      });
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (completelyFreeDueToCoupon) {
      setProcessing(true);
      PaymentsService.paymentsClaimCreate({
        sku_name: productName,
        email,
        coupon: priceCheckResponse?.accepted_coupon.code
      })
        .then((claim) => {
          const couponUsed = claim.accepted_coupon || null;
          onPaymentSuccess({ email, productName, couponUsed, pricePaid: 0 });
        })
        .catch((e) => {
          let errorMessage = e.toString();
          if ('status' in e && 'body' in e) {
            errorMessage = toReadableError(e.body);
          }
          setError(`Signup failed: ${errorMessage}`);
          setProcessing(false);
        });
    } else {
      submitPayment();
    }
  };

  return (
    <div className={styles.checkoutFormContainer}>
      <div className={styles.orderSummaryContainer}>
        <OrderSummary {...{ product, applyPromoCode, priceCheckResponse }} />
      </div>
      <div className={styles.paymentInformationContainer}>
        <div className={styles.paymentInformation}>
          <H7 className={styles.header}>Payment Information</H7>
          <hr />
          <img
            alt="Credit Cards"
            src="/credit-card-icon-strip.png"
            width={200}
          />
          <form className={styles.form} onSubmit={handleSubmit}>
            <CardNumberElement
              className={classNames(styles.cardNumber, inputStyles.input)}
              onChange={handleCardNumberChange}
              options={{
                placeholder: 'Credit card number',
                disabled: completelyFreeDueToCoupon
              }}
            />
            <div className={styles.expAndCvc}>
              <CardExpiryElement
                className={classNames(styles.cardExpiry, inputStyles.input)}
                onChange={handleCardExpiryChange}
                options={{
                  placeholder: 'Exp (MM/YY)',
                  disabled: completelyFreeDueToCoupon
                }}
              />
              <CardCvcElement
                className={classNames(styles.cardCvc, inputStyles.input)}
                onChange={handleCardCvcChange}
                options={{
                  placeholder: 'Security code (CVC)',
                  disabled: completelyFreeDueToCoupon
                }}
              />
            </div>
            <Span className={styles.tosNotice}>
              By joining SoShe, you agree to our{' '}
              {/* TODO samgqroberts 2022-02-10 extract these to variables, or at least domain */}
              <a href="https://www.soshe.com/terms-of-use">Terms of Service</a>{' '}
              and{' '}
              <a href="https://www.soshe.com/privacy-policy">Privacy Policy</a>
            </Span>
            <Button
              disabled={
                processing ||
                succeeded ||
                (!completelyFreeDueToCoupon && disabled)
              }
              className={styles.submit}
            >
              {completelyFreeDueToCoupon ? 'Sign Up (Free!)' : 'Sign Up'}
            </Button>
            {errorToShow && (
              <Span className={styles.cardError} role="alert">
                {errorToShow}
              </Span>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
