/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BirthPlanSectionEnum {
    NO = 'NO',
    HD = 'HD',
    VG = 'VG',
    CS = 'CS',
    BC = 'BC',
}