import { useEffect, useState } from 'react';

import InternalLink from '../general/InternalLink';
import LoaderOverlay from '../general/LoaderOverlay';
import { useCachedQueryParam } from '../general/react-util';
import { AuthService } from '../general/ServerClient';
import { PageLevelComponent } from '../general/util';
import { Button } from '../StyleGuide/Button';
import { VisibilityTogglePasswordInput } from '../StyleGuide/Input';
import { H7, P } from '../StyleGuide/Text';
import styles from './ConfirmPasswordResetPage.module.css';

const TOKEN_QUERY_PARAM = 'token';

const ConfirmPasswordResetPage: PageLevelComponent = () => {
  const token = useCachedQueryParam(TOKEN_QUERY_PARAM);
  const [checkingTokenValid, setCheckingTokenValid] = useState<boolean>(true);
  const [tokenValid, setTokenValid] = useState<boolean | undefined>(undefined);
  const [resetting, setResetting] = useState<boolean>(false);
  const [resetSuccessful, setResetSuccessful] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [fatalError, setFatalError] = useState<string | undefined>(undefined);
  const [newPassword1, setNewPassword1] = useState<string>('');
  const [newPassword2, setNewPassword2] = useState<string>('');
  useEffect(() => {
    if (token) {
      setCheckingTokenValid(true);
      AuthService.authPasswordResetValidateTokenCreate({ token })
        .then(() => {
          setCheckingTokenValid(false);
          setTokenValid(true);
        })
        .catch(() => {
          setTokenValid(false);
        });
    }
  }, [token]);
  if (resetSuccessful) {
    return (
      <div className={styles.pageSuccess}>
        <P>Your password has been successfully reset.</P>
      </div>
    );
  }
  if (!token || tokenValid === false) {
    return (
      <div className={styles.pageInvalid}>
        <P>This password reset request is either invalid or has expired.</P>
        <P>
          You can request another password reset{' '}
          <InternalLink to="requestPasswordReset">here</InternalLink>.
        </P>
      </div>
    );
  }
  if (checkingTokenValid) {
    return (
      <div className={styles.pageLoading}>
        <LoaderOverlay />
      </div>
    );
  }
  const passwordsMatch = newPassword1 === newPassword2;
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setResetting(true);
    AuthService.authPasswordResetConfirmCreate({
      token,
      password: newPassword1
    })
      .then(() => {
        setResetSuccessful(true);
      })
      .catch((e) => {
        // eslint-disable-next-line no-debugger
        if ('body' in e && 'password' in e.body) {
          setError(e.body.password.toString());
        } else {
          console.error(e, e.body);
          setFatalError(
            'An unexpected error occurred. Please try again later.'
          );
        }
      })
      .finally(() => {
        setResetting(false);
      });
  };
  return (
    <div className={styles.page}>
      <H7>Reset your password</H7>
      <form onSubmit={handleSubmit}>
        <VisibilityTogglePasswordInput
          value={newPassword1}
          setValue={setNewPassword1}
          placeholder="New Password"
        />
        <VisibilityTogglePasswordInput
          value={newPassword2}
          setValue={setNewPassword2}
          placeholder="New Password (again)"
        />
        <Button
          type="submit"
          disabled={
            !newPassword1 || !passwordsMatch || resetting || !!fatalError
          }
        >
          Reset password
        </Button>
      </form>
      {error && <P>{error}</P>}
      {fatalError && <P>{fatalError}</P>}
      {resetting && <LoaderOverlay />}
    </div>
  );
};

export default ConfirmPasswordResetPage;
