import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import * as React from 'react';

import { CopperRose, Tundora, White } from '../../StyleGuide/Colors';
import { AltFont } from '../../StyleGuide/Text';

const PREFIX = 'CircularProgressWithLabel';

const classes = {
  label: `${PREFIX}-label`,
  fixed: `${PREFIX}-fixed`
};

const StyledBox = styled(Box)({
  [`& .${classes.label}`]: {
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: `${AltFont}`
  },
  [`& .${classes.fixed}`]: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

/**
 * Helper component for displaying a user's progress through a set of tasks.
 * Displays a circle whose radius is filled in based on the completion percentage.
 */
export const CircularProgressWithLabel: React.FC<{
  value: number;
  size?: number | string;
  className?: string;
  backgroundColor?: string;
  foregroundColor?: string;
  fontColor?: string;
  completedColor?: string;
}> = ({
  value,
  size = 70,
  className,
  backgroundColor = Tundora,
  foregroundColor = White,
  fontColor = 'black',
  completedColor = CopperRose
}) => {
  const foregroundCircularProgressRef = React.useRef<HTMLSpanElement>(null);
  // fix iOS / Safari bug: need to set explicit size on SVG nested within CircularProgress
  React.useEffect(() => {
    const { current } = foregroundCircularProgressRef;
    if (current && current instanceof HTMLElement) {
      const nestedSvg = current.querySelector('svg');
      if (nestedSvg) {
        nestedSvg.style.width = '' + size;
        nestedSvg.style.height = '' + size;
      }
    }
  }, [size]);
  const notStarted = value <= 0;
  const completed = value >= 100;
  return (
    <StyledBox
      {...{ className }}
      sx={{ position: 'relative', display: 'inline-flex' }}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{
          color: `${backgroundColor} !important`,
          opacity: 0.4
        }}
        size={size}
      />
      <CircularProgress
        ref={foregroundCircularProgressRef}
        variant="determinate"
        value={value}
        className={classNames(classes.fixed)}
        sx={{
          color: `${completed ? completedColor : foregroundColor} !important`
        }}
        size={size}
      />
      <Box className={classNames(classes.label, classes.fixed)}>
        {value >= 100 ? (
          <CheckIcon
            sx={{ color: completed ? completedColor : foregroundColor }}
          />
        ) : (
          <span style={{ color: notStarted ? foregroundColor : fontColor }}>
            {Math.round(value)}%
          </span>
        )}
      </Box>
    </StyledBox>
  );
};
