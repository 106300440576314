import { styled } from '@mui/material/styles';

import { PageLevelComponent } from '../../general/util';
import logo from '../../img/desktop-logo.png';
import { Button } from '../../StyleGuide/Button';
import { Placeholder } from '../../StyleGuide/Colors';
import { useAppContext } from '../page/WebappContext';
import { AssuranceStage } from './AssuranceStage';
import { BirthLocationStage } from './BirthLocationStage';
import { BirthTypeStage } from './BirthTypeStage';
import { ConclusionStage } from './ConclusionStage';
import { DueDateStage } from './DueDateStage';
import { GoalsStage } from './GoalsStage';
import { useOnboardingContext } from './OnboardingContext';
import { ProviderStage } from './ProviderStage';

const MAX_CONTENT_WIDTH = 480;

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
});
const LogoContainer = styled('div')({
  margin: '0 auto',
  marginTop: 10,
  width: 100,
  flexShrink: 0,
  '& > img': {
    width: '100%'
  }
});
const ContentContainer = styled('div')({
  flexGrow: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: '0 15px 15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center'
});
const StageContainer = styled('div')({
  width: '100%',
  maxWidth: MAX_CONTENT_WIDTH
});
const Next = styled(Button)({
  width: '100%',
  maxWidth: MAX_CONTENT_WIDTH,
  paddingLeft: 0,
  paddingRight: 0,
  marginTop: 20,
  '&:disabled': {
    background: `${Placeholder} !important`
  }
});

/**
 * Based on the information tracked in OnboardingContext,
 *   displays the stage of onboarding the user is currently on.
 */
export const ContextualizedOnboarding: PageLevelComponent = () => {
  const { stage, nextAction, nextText } = useOnboardingContext();
  const { options } = useAppContext();
  // TODO samgqroberts 2021-12-10 should 'request' this data, and context should detect it's already been loaded and
  //      just serve the loaded one.
  //      can extend this to cache in localstorage as well.
  //      samgqroberts 2022-04-06 plugging in react-query properly here should be the solution
  if (!options.data) {
    console.error('Options not loaded');
    return null;
  }
  return (
    <Root>
      <LogoContainer>
        <img src={logo} alt="SoShe" />
      </LogoContainer>
      <ContentContainer>
        <StageContainer>
          {stage === 'goals' ? (
            <GoalsStage />
          ) : stage === 'assurance' ? (
            <AssuranceStage />
          ) : stage === 'duedate' ? (
            <DueDateStage />
          ) : stage === 'birthtype' ? (
            <BirthTypeStage />
          ) : stage === 'birthlocation' ? (
            <BirthLocationStage />
          ) : stage === 'provider' ? (
            <ProviderStage />
          ) : stage === 'conclusion' ? (
            <ConclusionStage />
          ) : null}
        </StageContainer>
        <Next disabled={nextAction === undefined} onClick={nextAction}>
          {nextText}
        </Next>
      </ContentContainer>
    </Root>
  );
};
