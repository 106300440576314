import Vimeo from '@vimeo/player';
import { useEffect, useRef, useState } from 'react';

const CUEPOINT_NAME = 'tenSecondsFromEnd';

/**
 * Component that mounts an iframe that loads and plays a video from Vimeo.
 */
export const VimeoPlayer: React.FC<{
  videoId: number;
  link: string;
  title: string;
  height: number;
  width: number;
  onUserWatchedVideo: (videoId: number) => void;
  onVideoEnded: () => void;
}> = ({
  videoId,
  link,
  title,
  height,
  width,
  onUserWatchedVideo,
  onVideoEnded
}) => {
  const [hasReportedWatched, setHasReportedWatched] = useState<boolean>(false);
  const handleWatchedVideo = () => {
    if (!hasReportedWatched) {
      setHasReportedWatched(true);
      onUserWatchedVideo(videoId);
    }
  };
  const onEnd = () => {
    handleWatchedVideo();
    onVideoEnded();
  };
  const ref = useRef<HTMLIFrameElement>(null);
  const annotatedUrl = new URL(link);
  annotatedUrl.searchParams.set('autoplay', '1');
  annotatedUrl.searchParams.set('title', '0');
  annotatedUrl.searchParams.set('byline', '0');
  annotatedUrl.searchParams.set('controls', '1');
  const videoSrc = annotatedUrl.toString();
  useEffect(() => {
    const { current } = ref;
    if (current) {
      const player = new Vimeo(current);
      player.getDuration().then((duration) => {
        const tenSecondsFromEnd = duration - 10;
        player
          .addCuePoint(tenSecondsFromEnd, { type: CUEPOINT_NAME })
          .then(() => {
            player.on('cuepoint', (cuePointEvent) => {
              if (cuePointEvent.data.type === CUEPOINT_NAME) {
                // user has watched until 10 seconds from end
                handleWatchedVideo();
              }
            });
          });
      });
      player.on('ended', () => {
        onEnd();
      });
    }
  }, [ref]);
  return (
    <iframe
      style={{ border: 0 }}
      src={videoSrc}
      {...{ ref, title, height, width }}
      allow="autoplay; fullscreen"
      allowFullScreen
    />
  );
};
