import React from 'react';
import { Route, Switch } from 'react-router';

import NotFoundPage from '../../NotFoundPage';

export const RouteSwitcher: React.FC = ({ children }) => {
  return (
    <Switch>
      {children}
      {/* fallthrough 404 page */}
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};
