/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeEmailConfirmation } from '../models/ChangeEmailConfirmation';
import type { Email } from '../models/Email';
import type { PasswordToken } from '../models/PasswordToken';
import type { Register } from '../models/Register';
import type { ResetToken } from '../models/ResetToken';
import type { TokenObtainPair } from '../models/TokenObtainPair';
import type { TokenObtainRequest } from '../models/TokenObtainRequest';
import type { TokenRefresh } from '../models/TokenRefresh';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Request to change the email associated with the logged-in user's account
     * @param requestBody
     * @returns Email
     * @throws ApiError
     */
    public static async authChangeEmailCreate(
        requestBody: Email,
    ): Promise<Email> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/auth/change-email`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Confirm request to change account email
     * @param requestBody
     * @returns ChangeEmailConfirmation
     * @throws ApiError
     */
    public static async authConfirmChangeEmailCreate(
        requestBody: ChangeEmailConfirmation,
    ): Promise<ChangeEmailConfirmation> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/auth/confirm-change-email`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * An Api View which provides a method to request a password reset token based on an e-mail address
     *
     * Sends a signal reset_password_token_created when a reset token was created
     * @param requestBody
     * @returns Email
     * @throws ApiError
     */
    public static async authPasswordResetCreate(
        requestBody: Email,
    ): Promise<Email> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/auth/password-reset/`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * An Api View which provides a method to reset a password based on a unique token
     * @param requestBody
     * @returns PasswordToken
     * @throws ApiError
     */
    public static async authPasswordResetConfirmCreate(
        requestBody: PasswordToken,
    ): Promise<PasswordToken> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/auth/password-reset/confirm/`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * An Api View which provides a method to verify that a token is valid
     * @param requestBody
     * @returns ResetToken
     * @throws ApiError
     */
    public static async authPasswordResetValidateTokenCreate(
        requestBody: ResetToken,
    ): Promise<ResetToken> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/auth/password-reset/validate_token/`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns Register
     * @throws ApiError
     */
    public static async authRegisterCreate(
        requestBody: Register,
    ): Promise<Register> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/auth/register`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web
     * token pair to prove the authentication of those credentials.
     * @param requestBody
     * @returns TokenObtainPair
     * @throws ApiError
     */
    public static async authTokenCreate(
        requestBody: TokenObtainRequest,
    ): Promise<TokenObtainPair> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/auth/token`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web
     * token if the refresh token is valid.
     * @param requestBody
     * @returns TokenRefresh
     * @throws ApiError
     */
    public static async authTokenRefreshCreate(
        requestBody: TokenRefresh,
    ): Promise<TokenRefresh> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/auth/token/refresh`,
            body: requestBody,
        });
        return result.body;
    }

}