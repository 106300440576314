import React from 'react';
import { generatePath } from 'react-router';

import { InProgressBackdrop } from '../Course/InProgressBackdrop';
import { InProgressSection } from '../Course/InProgressSection';
import { SectionList } from '../Course/SectionList';
import { PageLayout } from '../page/PageLayout';
import { useAppContext, useBirthPrepCourse } from '../page/WebappContext';
import { WebappUrls } from '../page/WebappUrls';
import { combineSectionsAndProgress } from '../util/util';

/**
 * Top level navigational tab content.
 * Displays list of Sections related to the birth prep (not plan) course.
 */
export const LearnPage: React.FC = () => {
  const birthPrepCourse = useBirthPrepCourse();
  const { birthPrepCourseProgress } = useAppContext();
  const sections =
    birthPrepCourse && birthPrepCourseProgress.data
      ? combineSectionsAndProgress(
          birthPrepCourse,
          birthPrepCourseProgress.data
        )
      : undefined;
  const inProgressSection = sections?.find(({ progress }) => {
    return progress.overall.percent_completed < 100;
  });
  const allSectionsNotStarted = !!sections?.every(
    ({ progress }) => progress.overall.completed === 0
  );
  return (
    <PageLayout>
      {inProgressSection && (
        <InProgressBackdrop>
          <InProgressSection
            section={inProgressSection}
            path={generatePath(WebappUrls.learnSection, {
              sectionId: inProgressSection.section.id
            })}
            {...{ allSectionsNotStarted }}
          />
        </InProgressBackdrop>
      )}
      {sections && (
        <SectionList
          {...{
            sections,
            generatePath: (sectionId) =>
              generatePath(WebappUrls.learnSection, { sectionId })
          }}
        />
      )}
    </PageLayout>
  );
};
