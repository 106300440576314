import { PageLevelComponent } from '../../general/util';
import { ContextualizedOnboarding } from './ContextualizedOnboarding';
import { OnboardingProvider } from './OnboardingContext';

/**
 * Entrypoint to the UX flow that onboards users to the app, asking them basic questions
 *   about their birth plan.
 */
export const Onboarding: PageLevelComponent = () => {
  return (
    <OnboardingProvider>
      <ContextualizedOnboarding />
    </OnboardingProvider>
  );
};
