import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import { Interstitial, Question, UserAnswer } from '../../general/ServerClient';
import { notMobileMediaQuery } from '../../StyleGuide/Breakpoints';
import { CopperRose, Questions, Tundora, White } from '../../StyleGuide/Colors';
import { BackDetails, PageLayout } from '../page/PageLayout';
import { SIDE_PADDING } from './constants';
import { InterstitialDialog } from './InterstitialDialog';
import { InterstitialTeaser } from './InterstitialTeaser';

const HeaderDiv = styled('div')({
  padding: SIDE_PADDING,
  color: Questions,
  fontSize: 14,
  textTransform: 'uppercase'
});
const TextDiv = styled('div')({
  padding: `10px ${SIDE_PADDING}px`,
  paddingTop: 0,
  fontSize: 18,
  [notMobileMediaQuery]: {
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: '36px'
  }
});
const ChoicePromptDiv = styled('div')({
  fontSize: 13,
  color: Questions,
  padding: `10px ${SIDE_PADDING}px 0`
});
const AnswersUL = styled('ul')({
  listStyle: 'none',
  padding: `2px ${SIDE_PADDING}px 10px`,
  margin: 0
});
const AnswerLI = styled('li')({
  display: 'flex',
  padding: '8px 0'
});
const SelectAnswerButton = styled(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    multiSelect,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selected,
    ...rest
  }: React.ComponentProps<'button'> & {
    multiSelect: boolean;
    selected: boolean;
  }) => <button {...rest} />
)(({ selected, multiSelect }) => ({
  height: 16,
  width: 16,
  marginTop: 3,
  border: `1px solid ${Tundora}`,
  background: selected ? CopperRose : White,
  borderColor: selected ? CopperRose : 'initial',
  borderRadius: multiSelect ? 0 : '50%'
}));
const AnswerTextSpan = styled('span')({
  marginLeft: 10
});

/**
 * An internal component to the question page ecosystem.
 * Handles overall layout and is passed page level state props.
 */
export const QuestionPageLoaded: React.FC<{
  question: Question;
  userAnswers: UserAnswer[];
  interstitial: Interstitial | null;
  viewedInterstitial: boolean | undefined;
  onAnswer: (answerId: number) => void;
  onRemoveAnswer: (answerId: number) => void;
  onViewInterstitial: (interstitialId: number) => void;
  back: BackDetails;
}> = ({
  question,
  userAnswers,
  interstitial,
  viewedInterstitial,
  onAnswer,
  onRemoveAnswer,
  onViewInterstitial,
  back
}) => {
  const [showInterstitial, setShowInterstitial] = useState<boolean>(false);
  useEffect(() => {
    if (interstitial && !viewedInterstitial) {
      setShowInterstitial(true);
    }
  }, []);
  useEffect(() => {
    if (interstitial && showInterstitial && !viewedInterstitial) {
      onViewInterstitial(interstitial.id);
    }
  }, [showInterstitial]);
  return (
    <PageLayout {...{ back }}>
      {interstitial && (
        <InterstitialTeaser
          {...{ interstitial, onClick: () => setShowInterstitial(true) }}
        />
      )}
      <HeaderDiv>{question.header}</HeaderDiv>
      <TextDiv>{question.text}</TextDiv>
      <ChoicePromptDiv>
        {question.multi_select ? 'Choose one or more' : 'Choose one'}
      </ChoicePromptDiv>
      <AnswersUL>
        {question.answers.map((answer) => {
          const userAnswer = userAnswers.find((ua) => ua.answer === answer.id);
          const selected = !!userAnswer;
          return (
            <AnswerLI key={answer.id}>
              <SelectAnswerButton
                multiSelect={question.multi_select}
                selected={selected}
                data-selected={selected}
                onClick={
                  userAnswer
                    ? () => onRemoveAnswer(answer.id)
                    : () => onAnswer(answer.id)
                }
              ></SelectAnswerButton>
              <AnswerTextSpan>{answer.text}</AnswerTextSpan>
            </AnswerLI>
          );
        })}
      </AnswersUL>
      <InterstitialDialog
        {...{
          question,
          open: showInterstitial,
          onClose: () => setShowInterstitial(false)
        }}
      />
    </PageLayout>
  );
};
